/* jshint esversion: 8 */
'use strict';

import BasePage from '../../lib/page-base.js';

export default class StatsInfoPb extends BasePage {
    constructor(global) {
        super(global);
        this.pageName = 'Powerball Stats';
    }

    initialize() {
        this.layout.main.classList.add('scheme-pb');
        this.initializeAds('iaad-articlepage-bgdark', 1);
    }

    getContent() {
        return `<div class="static-root">
<div class="lottery-img">
    <img src="../images/lottery-logos/pb-logo-640x120.png"></img>
</div>
<br/>
<div>
    <div class="htp-label">This Powerball® statistic report includes the number matrix that was last changed on October 7, 2015. The winning numbers consist of five white balls chosen from 1 to 69 and one Powerball chosen from 1 to 26.</div>
    <div class="htp-label">These statistics do not include numbers drawn in Double Play® drawings, which started on August 23, 2021.</div>
</div>
<br/>
<div class="dpad-staticpage-banner" id="iaad-articlepage-bgdark-1"></div>
<div>
    <div class="htp-label">TERMS USED IN THIS REPORT:</div>
    <div>
        <li><b><u>Times Drawn</u></b> is the number of times a white ball number appears as one of the five winning numbers or the red ball number appears as the Powerball number.</li>
        <li><b><u>% of Drawings</u></b> is the percentage of drawing dates where the drawn number was one of the five winning numbers. It only applies to white ball numbers.</li>
        <li><b><u>% of Draws</u></b> is the percentage of times a number was drawn out of every drawn number. There are five white ball numbers per drawing.</li>
        <li>For white ball numbers, <b><u>% of Draws</u></b> is equal to <b><u>Times Drawn</b></u> divided by the total number of drawing dates and then divided by five. Therefore, <b><u>% of Draws</u></b> is equal to five times the <b><u>% of Drawings</u></b>.</li>
        <li>For Powerball numbers, <b><u>% of Draws</u></b> is equal to <b><u>Times Drawn</b></u> divided by the total number of drawing dates. Therefore, <b><u>% of Draws</u></b> and <b><u>% of Drawings</u></b> are the same.</li>
    </div>
</div>
<br/><br/><br/><br/>
</div>`;
    }
}