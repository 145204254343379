/* jshint esversion: 8 */
'use strict';

import BasePage from '../../lib/page-base.js';

export default class PageNotFound extends BasePage {
    constructor(global) {
        super(global);
        this.pageName = 'Page Not Found';
    }

    getContent() {
        return `<div class="general-msg">
<div class="logos" id="applogo48x48"></div><br/><br/>
<h2>404. Page Not Found</h2><br/><br/>
<p>The requested page does not exist.</p><br/>
<p>Please click on the menu items in the top navigation bar to go to other pages.</p><div>`;
    }
}
