/* jshint esversion: 8 */
'use strict';

import LotteryBase from './lottery-base.js';
import Utils from '../../lib/utils.js';
import '../../../css/home-page.scss';

export default class HomePage extends LotteryBase {
    constructor(global) {
        super(global);
        this.pageName = 'Home';
    }

    initialize() {
        this.layout.selectMenuItem(this.layout.menus.home.item);
        this.loadContent(this, 'megamillions').then(() => {
            return this.loadContent(this, 'powerball');
        }).then(() => {
            this.countdown(this);
        });
    }

    getContent() {
        this.root = Utils.addElement(null, 'div', 'home-root');
        Utils.addElement(this.root, 'div', null, 'dpad-home-leaderboard').innerHTML = this.insertAd('dpad-home-leaderboard');

        const jackpot = Utils.addElement(this.root, 'div', 'jackpot-container');
        this.renderLotteryTile(jackpot, 'megamillions');
        this.renderLotteryTile(jackpot, 'powerball');

        Utils.addElement(jackpot, 'div', null, 'dpad-home-skyscraper').innerHTML = this.insertAd('dpad-home-skyscraper');

        return this.root;
    }

    renderLotteryTile(parent, type) {
        const ltype = this[type];
        const tile = Utils.addElement(parent, 'div', 'jackpot-tile');
        const img = Utils.addElement(tile, 'img', type);
        img.src = type === 'powerball' ? './images/lottery-logos/pb-logo-640x120.png' : './images/lottery-logos/mm-logo-640x120.png';;

        // Next Estimated Jackpot section
        Utils.addElement(tile, 'div', 'label fs-pt85').innerText = 'NEXT ESTIMATED JACKPOT'
        let  sect = Utils.addElement(tile, 'div', 'draw-info tborder');
        ltype.nextAnnuity = this.addBlockText(sect, 'right', 'ANNUITY');
        ltype.nextDrawDate = this.addBlockText(sect, 'left', 'NEXT DRAW');
        ltype.nextCash = this.addBlockText(sect, 'right', 'CASH VALUE');

        const block = Utils.addElement(sect, 'div', 'half');
        ltype.statusLabel = Utils.addElement(block, 'div', 'label left fs-pt75');
        ltype.statusLabel.innerText = 'STATUS';
        ltype.statusValue = Utils.addElement(block, 'div', 'value left');
        ltype.nextNumsJp = Utils.addJackpotNumbers(sect, type, [0, 0, 0, 0, 0, 0], 0);
        ltype.nextNumsJp.classList.add('top-margin');
        ltype.nextNumsJp.classList.add('hide')
        if (type === 'powerball') {
            ltype.nextNumsDp = Utils.addJackpotNumbers(sect, type, [0, 0, 0, 0, 0, 0], 0, undefined, 'black');
            ltype.nextNumsDp.classList.add('bottom-margin');
            ltype.nextNumsDp.classList.add('hide')
        }

        // Last Winning Numbers section
        Utils.addElement(tile, 'div', 'label fs-pt85').innerText = 'LAST WINNING NUMBERS';
        this.renderPrevDraw(tile, type, 'draw-info tborder');
        Utils.addElement(tile, 'div', 'bmargin60');

        const btnbox = Utils.addElement(tile, 'div', 'btnbox');

        // Payout button
        let btn = Utils.addElement(btnbox, 'button', 'regular left');
        btn.innerText = 'Payouts';
        btn.me = this;
        btn.ltype = type;
        btn.onclick = event => this.renderPayoutsPopup(event.target, null, 'dpad-payoutmodal-banner');

        // History button
        btn = Utils.addElement(btnbox, 'button', 'regular right');
        btn.innerText = 'History';
        const qparam = type === 'powerball' ? '?type=pb' : '?type=mm';
        btn.onclick = () => this.global.router.dispatch('/history', qparam);
    }

    renderCountdown(ltype) {
        if (!ltype.nextDays || !ltype.nextHours || !ltype.nextMinutes || !ltype.nextSeconds) {
            Utils.clearNode(ltype.statusLabel, false);
            Utils.addElement(ltype.statusLabel, 'div', 'fs-pt75').innerText = 'DAYS';
            Utils.addElement(ltype.statusLabel, 'div', 'fs-pt75').innerText = 'HRS';
            Utils.addElement(ltype.statusLabel, 'div', 'fs-pt75').innerText = 'MINS';
            Utils.addElement(ltype.statusLabel, 'div', 'fs-pt75').innerText = 'SECS';

            Utils.clearNode(ltype.statusValue, false);
            ltype.nextDays = Utils.addElement(ltype.statusValue, 'div');
            ltype.nextHrs = Utils.addElement(ltype.statusValue, 'div');
            ltype.nextMins = Utils.addElement(ltype.statusValue, 'div');
            ltype.nextSecs = Utils.addElement(ltype.statusValue, 'div');
        }
    }

    async loadContent(me, type) {
        const path = type === 'powerball' ? 'powermega/feeds/home-pb.json' : 'powermega/feeds/home-mm.json';

        return Utils.downloadStorageJson(me.sref.child(path)).then(data => {
            me[type].dailyFeed = data;
            me.loadJackpotTile(type);
            me[type].rfTimerId = setTimeout(me.loadContent, Utils.getNextWakeupTime(type), me, type);
        })
    }

    loadJackpotTile(type) {
        const ltype = this[type];
        const next = ltype.dailyFeed.next;

        ltype.nextAnnuity.innerHTML = `<b>${Utils.formatAmount(next.jpot)}</b>`;
        ltype.nextDrawDate.innerText = Utils.formatDate(next.ddate, false, false);
        ltype.nextCash.innerHTML = Utils.formatAmount(next.cash);
        next.wnum ? Utils.setJackpotNumbers(ltype.nextNumsJp, next.wnum, next.mplier) : ltype.nextNumsJp.classList.add('hide');

        if (type === 'powerball') {
            next.dpwnum ? Utils.setJackpotNumbers(ltype.nextNumsDp, next.dpwnum, 'DP') : ltype.nextNumsDp.classList.add('hide');
        }

        if (next.winner) {
            Utils.clearNode(ltype.statusLabel, false);
            Utils.clearNode(ltype.statusValue, false);
            ltype.nextDays = undefined;
            ltype.nextHours = undefined;
            ltype.nextMinutes = undefined;
            ltype.nextSeconds = undefined;
            ltype.statusLabel.innerText = 'Status';
            ltype.statusValue.innerText = next.winner || 'Pending';
        } else {
            this.renderCountdown(ltype);
        }

        this.loadPrevDrawInfo(ltype);
    }

    countdown(me) {
        const mmdf = me.megamillions;
        const pbdf = me.powerball;
        const mmtime = Utils.countdown(mmdf.dailyFeed.next.ddate);
        const pbtime = Utils.countdown(pbdf.dailyFeed.next.ddate);

        const update = (ltype, time) => {
            if (ltype.nextDays && ltype.nextHrs && ltype.nextMins && ltype.nextSecs) {
                ltype.nextDays.innerText = time.days.toString().padStart(2, '0');
                ltype.nextHrs.innerText = time.hours.toString().padStart(2, '0');
                ltype.nextMins.innerText = time.minutes.toString().padStart(2, '0');
                ltype.nextSecs.innerText = time.seconds.toString().padStart(2, '0');
            }
        }

        if (mmtime) update(mmdf, mmtime);
        if (pbtime) update(pbdf, pbtime);

        me.cdTimerId = setTimeout(me.countdown, 1000, me);
    }

    housekeep(me) {
        if (me.cdTimerId) clearTimeout(me.cdTimerId);
        if (me.megamillions.rfTimerId) clearTimeout(me.megamillions.rfTimerId);
        if (me.powerball.rfTimerId) clearTimeout(me.powerball.rfTimerId);
    }
}
