/**
 * jshint esversion: 8
 *
 * https://medium.com/@bryanmanuele/how-i-implemented-my-own-spa-routing-system-in-vanilla-js-49942e3c4573
 */
'use strict';

import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/analytics';
import 'firebase/compat/functions';

import Hooks from './hooks.js';

import AboutPage from '../app/components/about.js';
import AccountTermination from '../app/components/termination.js';
import Authentication from '../app/components/authentication.js';
import EmailLogin from '../app/components/email-login.js';
import {Generator} from '../app/components/generator.js';
import HistoryPage from '../app/components/history.js';
import HomePage from '../app/components/home.js';
import ManageAccount from '../app/components/manage-account.js';
import NewsLetters from '../app/components/news-letters.js';
import Statistics from '../app/components/statistics.js';
import ProfileUpdate from '../app/components/update-profile.js';
import Registration from '../app/components/registration.js';
import TopPrizes from '../app/components/top-prizes.js';
import UserStatusCheck from '../app/components/status-check.js';
import Winnings from '../app/components/winnings.js';
import AppVersion from '../app/static-pages/app-version.js';
import HowToPlayMm from '../app/static-pages/howtoplay-mm.js';
import HowToPlayPb from '../app/static-pages/howtoplay-pb.js';
import NewFeatures from '../app/static-pages/new-features.js';
import PageNotFound from '../app/static-pages/page404.js';
import PrivacyPolicy from '../app/static-pages/privacy.js';
import StatsInfoMm from '../app/static-pages/statsinfo-mm.js';
import StatsInfoPb from '../app/static-pages/statsinfo-pb.js';
import TermsConditions from '../app/static-pages/terms.js';
import UserConsent from './user-consent.js';

export default class Router {
    constructor(settings) {
        firebase.initializeApp(settings.firebaseConfig);
        firebase.analytics.isSupported().then(isSupported => {
            if (isSupported) this.analytics = firebase.analytics();
        });
        this.functions = firebase.functions();
        this.firstPage = true;

        // configure the routes url path to JS class mapping
        this.routes = {
            '/': HomePage,
            '/about': AboutPage,
            '/authenticate': Authentication,
            '/checkstatus': UserStatusCheck,
            '/emaillogin': EmailLogin,
            '/generator': Generator,
            '/history': HistoryPage,
            '/home': HomePage,
            '/htpmm': HowToPlayMm,
            '/htppb': HowToPlayPb,
            '/index.html': HomePage,
            '/manageact': ManageAccount,
            '/notfound': PageNotFound,
            '/optnews': NewsLetters,
            '/privacy': PrivacyPolicy,
            '/privacy.html': PrivacyPolicy,
            '/register': Registration,
            '/stats': Statistics,
            '/statsmm': StatsInfoMm,
            '/statspb': StatsInfoPb,
            '/termaccount': AccountTermination,
            '/terms': TermsConditions,
            '/terms.html': TermsConditions,
            '/tprizes': TopPrizes,
            '/updprofile': ProfileUpdate,
            '/version': AppVersion,
            '/welcome': AboutPage,
            '/welcome.html': AboutPage,
            '/whatsnew': NewFeatures,
            '/winnings': Winnings
        };

        // set the global variables that are used by all the pages
        this.global = {
            firebase: firebase,
            router: this,
            dbname: settings.dbname,
            action: settings.actionCodeSettings,
            ioslink: settings.iosAppLink,
            androidlink: settings.androidAppLink,
            webHomeUrl: settings.webHomeUrl,
            menus: settings.menus,
            geo: settings.geo,
            processing: false,
            qstring: null,
            states: null
        };

        // spinning loader
        this.loader = document.createElement('div');
        this.loader.id = 'spinning-loader';
        this.loader.className = 'loader';
        document.body.appendChild(this.loader);

        // user consent for cookies
        UserConsent.showConsentPopup();

        [this.context, this.dispatch] = Hooks.useContext(this.navigate, this, firebase.auth().currentUser);

        // listen to auth state change events
        this.listenAuthStateChange();

        // handle browse history entry for the back button action
        window.onpopstate = event => {
            const state = event.state;
            if (state && state.isMine) {
                this.showLoader();
                this.setContent(this.routes[window.location.pathname], window.location.search);
                this.hideLoader();
            }
        }
    }

    listenAuthStateChange() {
        firebase.auth().onAuthStateChanged(async user => {
            if (this.global.processing) {
                // global.processing is an internal flag, when it is set to true, ignore authentication state change to avoid duplicated actions.
                this.global.processing = false;
            } else if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
                // the incoming request URL is a Firebase passwordless email link, login the user here and prompt for more profile information
                this.dispatch('/emaillogin', window.location.search);
            } else {
                firebase.auth().getRedirectResult().then(oauth => {
                    if (user) {
                        // user is signed in
                        if (oauth.user) {
                            const reg = Registration.getInstance(this.global);
                            reg.updatePowermegaDatabase(oauth.user);
                        }

                        this.dispatch(window.location.pathname, window.location.search, 'goback');

                        // add user journey for linked account
                        if (oauth.operationType === 'link') {
                            // operationType values: 'signIn' for a sign-in operation, 'link' for a linking operation and 'reauthenticate' for a reauthentication operation.
                            const pageObj = this.routes[window.location.pathname].getInstance(this.global);
                            const pmUserRef = pageObj.ucol.doc(oauth.user.uid);
                            const logMsg = `Linked "${oauth.credential.providerId}" account "${oauth.user.email}" with this Powermega account.`;
                            pageObj.writeToUserJourney(pmUserRef, logMsg);
                        }
                    } else {
                        // user is signed out or never signed in
                        if (oauth.user) {
                            throw(new Error({code: 'powermega/redirect-error', message: `User has not logged in to the Firebase, but a third party authentication method <b>${oauth.credential.providerId}</b> redirects a logged in user <b>${oauth.user.email}</b>.`}));
                        } else {
                            this.dispatch(window.location.pathname, window.location.search, 'stayon');
                        }
                    }
                }).catch(error => {
                    console.log('app.js getRedirectResult error:', error);
                    this.dispatch('/authenticate');
                    const authPageObj = Authentication.getInstance(this.global);

                    if (error.code === 'auth/email-already-in-use') {
                        authPageObj.errormsg.innerHTML = `The email address <b>${error.email}</b> is already in use by another account.  It cannot be linked with account <b>${user.email}</b>.`
                    } else if (error.code === 'auth/account-exists-with-different-credential') {
                        firebase.auth().fetchSignInMethodsForEmail(error.email).then(providers => {
                            authPageObj.errormsg.innerHTML = `${error.message}</br></br>${authPageObj.getProviders(providers, true)}  If you would like to use <b>${error.credential.providerId}</b> as an alternative sign-in method, please login with your previously used sign-in method, and then select the <b>Alternative Sign-In</b> button from the Manage Account page.`
                        });
                    } else if (error.code === 'auth/web-storage-unsupported') {
                        authPageObj.errormsg.innerHTML = error.message + ' Please check your browser settings and allow third party cookies.';
                    } else {
                        authPageObj.errormsg.innerHTML = error.message;
                    }
                });
            }
        });
    }

    async setContent(className, queryString) {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        this.global.qstring = queryString;
        const obj = className.getInstance(this.global);
        document.title = `Powermega ${obj.pageName}`;
        await obj.renderContent();
    }

    async navigate(context) {
        context.router.showLoader();

        if (context.prev.path) {
            const className = context.router.routes[context.prev.path];
            if (className) {
                const prevInstance = className.getInstance(context.router.global);
                if (typeof prevInstance.housekeep === 'function') prevInstance.housekeep(prevInstance);
            }
        }

        const className = context.router.routes[context.next.path] || PageNotFound;
        await context.router.setContent(className, context.next.query);
        context.router.hideLoader();
    }

    updateNavigationUrl(pathName, queryString) {
        Hooks.pushToBrowserHistory(pathName, queryString);
    }

    async getPageContent(path) {
        const className = this.routes[path];
        if (className) {
            const obj = className.getInstance(this.global);
            return obj.getContent.constructor.name === 'AsyncFunction' ? await obj.getContent() : obj.getContent();
        }
        return '';
    }

    showLoader() {
        this.loader.style.display = '';
    }

    hideLoader() {
        this.loader.style.display = 'none';
    }
}
