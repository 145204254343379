/* jshint esversion: 8 */
'use strict';

import BasePage from '../../lib/page-base.js';

export default class PrivacyPolicy extends BasePage {
    constructor(global) {
        super(global);
        this.pageName = 'Privacy Policy';
    }

    initialize() {
        if (this.layout.webview) {
            this.layout.hideHeaderFooter();
            this.showWebviewElement('pmb-webview');
        }
        this.initializeAds('iaad-articlepage-bgnone', 3);
    }

    getContent() {
        return `<div class="legal-page">
<div class="pm-brand hide-element" id="pmb-webview">
    <div class="logos pmlogo-36x36"></div>
    <div>
        <div class="b-title">POWERMEGA</div>
        <div class="b-subtitle">Helping You Win!</div>
    </div>
</div>
<h1>Powermega Privacy Policy</h1>
<p class="notes">Effective date: October 18, 2021</p>
<p>Powermega ("us", "we", or "our") operates the Powermega mobile application (hereinafter referred to as the "Service").</p>
<p>This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data.</p>
<p>We use your data to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, the terms used in this Privacy Policy have the same meanings as in our Terms and Conditions.</p>
<div class="iaad-articlepage-bgnone" id="iaad-articlepage-bgnone-1"></div>
<h2>1. Information Collection And Use</h2>
<p>We collect several different types of information for various purposes to provide and improve our Service to you.</p>
<h4>1.1 Personal Data</h4>
<p>While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you ("Personal Data"). Personally identifiable information may include, but is not limited to:</p>
<ul>
    <li>Email address</li>
    <li>First name and last name</li>
    <li>Phone number</li>
    <li>Address, State, Province, ZIP/Postal code, City</li>
    <li>Cookies and Usage Data</li>
</ul>
<h4>1.2 Usage Data</h4>
<p>When you access the Service with a mobile device, we may collect certain information automatically, including, but not limited to, the type of mobile device you use, your mobile device unique ID, the IP address of your mobile device, your mobile operating system, the type of mobile Internet browser you use, unique device identifiers and other diagnostic data ("Usage Data").</p>
<p>When you choose to sign in with your Facebook, Google or Apple account, we may by default access your account information, including your name, email, gender, birthday, current city, and profile picture URL, as well as other information that you choose to make public. and you may choose to grant or deny us access to each individual permission. For more information regarding Facebook, Google and Apple permissions, refer to their permissions reference page.</p>
<h4>1.3 Tracking &amp; Cookies Data</h4>
<p>We use cookies and similar tracking technologies to track the activity on our Service and hold certain information.</p>
<p>Cookies are files with small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Tracking technologies also used are beacons, tags, and scripts to collect and track information and to improve and analyze our Service.</p>
<p>You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.</p>
<p>Examples of Cookies we use:</p>
<ul>
    <li><strong>Session Cookies.</strong> We use Session Cookies to operate our Service.</li>
    <li><strong>Preference Cookies.</strong> We use Preference Cookies to remember your preferences and various settings.</li>
    <li><strong>Security Cookies.</strong> We use Security Cookies for security purposes.</li>
</ul>
<div class="iaad-articlepage-bgnone" id="iaad-articlepage-bgnone-2"></div>
<h2>2. Use of Data</h2>
<p>Powermega uses the collected data for various purposes:</p>
<ul>
    <li>To provide and maintain the Service</li>
    <li>To notify you about changes to our Service</li>
    <li>To allow you to participate in interactive features of our Service when you choose to do so</li>
    <li>To provide customer care and support</li>
    <li>To provide analysis or valuable information so that we can improve the Service</li>
    <li>To monitor the usage of the Service</li>
    <li>To detect, prevent and address technical issues</li>
</ul>
<p>Additionally, we may use third-party software to serve ads on the site and our mobile application, implement email marketing campaigns, and manage other interactive marketing initiatives.  This third-party software may use cookies or similar tracking technology to help manage and optimize your online experience with us.</p>
<h2>3. Transfer Of Data</h2>
<p>Your information, including Personal Data, may be transferred to, and maintained on, computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from your jurisdiction.</p>
<p>If you are located outside United States and choose to provide information to us, please note that we transfer the data, including Personal Data, to United States and process it there.</p>
<p>Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.</p>
<p>Powermega will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of your data and other personal information.</p>
<h2>4. Disclosure Of Data</h2>
<p>Powermega may disclose your Personal Data in the good faith belief that such action is necessary to:</p>
<ul>
    <li>To comply with a legal obligation</li>
    <li>To protect and defend the rights or property of Powermega</li>
    <li>To prevent or investigate possible wrongdoing in connection with the Service</li>
    <li>To protect the personal safety of users of the Service or the public</li>
    <li>To protect against legal liability</li>
</ul>
<h2>5. Security Of Data</h2>
<p>The security of your data is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.</p>
<p>You may at any time review or change the information in your account by logging into <a href="/">Powermega Home</a>.  If you would like to terminate your account, open <a href="/termaccount">${this.global.webHomeUrl}/termaccount</a> in a web browser, or login from the mobile app, go to Profile Update screen by tapping on the user name or email address, then tap on the "Delete Account" button.  Upon your request to terminate your account, we will delete your account and personal information from our active databases. However, some information may be retained in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our Terms of Use and/or comply with legal requirements.</p>
<p>During termination request process, you should get a confirmation code, which allows you to check the account status at <a href="/checkstatus">${this.global.webHomeUrl}/checkstatus</a>.  A confirmation email will also be sent to you if we have your valid email address.  After the account is terminated, there might be some cached information within the mobile app and you need to sign out from the app to clear the cache.  The push notification feature works for both authenticated and anonymous users.  You may still receive notifications even after your account has been terminated.  To stop receiving notification messages, you can turn off push notification switches under the "More" screen within the app.</p>
<p>If you have encountered any problems or questions during the account termination process, please contact us using the contact information provided below.</p>
<h2>6. Service Providers</h2>
<p>We may employ third party companies and individuals to facilitate our Service ("Service Providers"), to provide the Service on our behalf, to perform Service-related services or to assist us in analyzing how our Service is used.</p>
<p>These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.</p>
<h3>6.1 Analytics</h3>
<p>We use Google Analytics (GA) for Firebase Service to monitor and analyze the use of our Service.  Firebase Analytics collects your identifiers for devices including Advertising Identifier for iOS, Android Advertising ID and a web cookie (_ga). Read the <a href="https://firebase.google.com/policies/analytics">Privacy Policy for Firebase</a> for more information.</p>
<p>We aggregated, anonymized mobile app and website traffic analysis. In order to track your session usage, Google drops a cookie (_ga) with a randomly-generated ClientID in your browser. This ID is anonymized and contains no identifiable information like email, phone number, name, etc. We also send Google your IP Address. We use GA to track aggregated mobile and website behavior, such as what pages you looked at, for how long, and so on. This information is important to us for improving the user experience and determining site effectiveness. If you would like to access what browsing information we have - or ask us to delete any GA data - please delete your _ga cookies, reach out to us via our <a href="mailto:contactus@powermega.net?subject=Remove My Powermega Google Analytics Data">support email</a>, and/or install the <a href="https://tools.google.com/dlpage/gaoptout">Google Analytics Opt-Out Browser Add-On</a>.</p>
<div class="iaad-articlepage-bgnone" id="iaad-articlepage-bgnone-3"></div>
<h2>7. Links To Other Sites</h2>
<p>Our Service may contain links to other sites that are not operated by us. If you click on a third party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit.</p>
<p>We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</p>
<h2>8. Children's Privacy</h2>
<p>Our Service does not address anyone under the age of 18 ("Children").</p>
<p>We do not knowingly collect personally identifiable information from anyone under the age of 18. If you are a parent or guardian and you are aware that your Children has provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from children without verification of parental consent, we take steps to remove that information from our servers.</p>
<h2>9. Changes To This Privacy Policy</h2>
<p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
<p>We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update the "effective date" at the top of this Privacy Policy.</p>
<p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
<h2>10. Contact Us</h2>
<p>If you have any questions about these terms, please contact us by email: contactus@powermega.net</p>
<br/><br/><br/><br/>
</div>`;
    }
}
