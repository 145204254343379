/* jshint esversion: 8 */
'use strict';

export default class AdManager {
    static getAdUnit(adUnitName) {
        return this.adUnits[adUnitName];
    }

    static adUnits = {
        // Home page ads
        'dpad-home-leaderboard': {
            tag: `<ins class="adsbygoogle"
                style="display:block"
                data-ad-client="ca-pub-7526882407618262"
                data-ad-slot="1907794951"
                data-ad-format="auto"
                data-full-width-responsive="true"></ins>`,
            sid: '1907794951'
        },
        'dpad-home-skyscraper': {
            tag: `<ins class="adsbygoogle"
                style="display:inline-block;width:300px;height:600px"
                data-ad-client="ca-pub-7526882407618262"
                data-ad-slot="2526742057"></ins>`,
            sid: '2526742057'
        },

        // History page ads
        'dpad-history-tile-1': {
            tag: `<ins class="adsbygoogle"
                style="display:inline-block;width:290px;height:200px"
                data-ad-client="ca-pub-7526882407618262"
                data-ad-slot="8882947596"></ins>`,
            sid: '8882947596'
        },
        'dpad-history-tile-2': {
            tag: `<ins class="adsbygoogle"
                style="display:inline-block;width:290px;height:200px"
                data-ad-client="ca-pub-7526882407618262"
                data-ad-slot="9074766708"></ins>`,
            sid: '9074766708'
        },
        'dpad-history-tile-3': {
            tag: `<ins class="adsbygoogle"
                style="display:inline-block;width:290px;height:200px"
                data-ad-client="ca-pub-7526882407618262"
                data-ad-slot="8751333078"></ins>`,
            sid: '8751333078'
        },
        'dpad-history-tile-4': {
            tag: `<ins class="adsbygoogle"
                style="display:inline-block;width:290px;height:200px"
                data-ad-client="ca-pub-7526882407618262"
                data-ad-slot="4812088067"></ins>`,
            sid: '4812088067'
        },

        // About page ads
        'mpad-about-multipex': {
            tag: `<ins class="adsbygoogle"
                style="display:block"
                data-ad-format="autorelaxed"
                data-ad-client="ca-pub-7526882407618262"
                data-ad-slot="5490212087"></ins>`,
            sid: '5490212087'
        },
        'dpad-about-tile': {
            tag: `<ins class="adsbygoogle"
                style="display:block"
                data-ad-client="ca-pub-7526882407618262"
                data-ad-slot="2864048748"
                data-ad-format="auto"
                data-full-width-responsive="true"></ins>`,
            sid: '2864048748'
        },

        // Numbers -> Statistics page ads
        'mpad-stats-multiplex': {
            tag: `<ins class="adsbygoogle"
                style="display:block"
                data-ad-format="autorelaxed"
                data-ad-client="ca-pub-7526882407618262"
                data-ad-slot="7589799809"></ins>`,
            sid: '7589799809'
        },
        'dpad-stats-leaderboard': {
            tag: `<ins class="adsbygoogle"
                style="display:block"
                data-ad-client="ca-pub-7526882407618262"
                data-ad-slot="4951194023"
                data-ad-format="auto"
                data-full-width-responsive="true"></ins>`,
            sid: '4951194023'
        },

        // Numbers -> Winnings page ads
        'dpad-winnings-left': {
            tag: `<ins class="adsbygoogle"
                style="display:inline-block;width:336px;height:280px"
                data-ad-client="ca-pub-7526882407618262"
                data-ad-slot="6114217980"></ins>`,
            sid: '6114217980'
        },
        'dpad-winnings-right': {
            tag: `<ins class="adsbygoogle"
                style="display:inline-block;width:336px;height:280px"
                data-ad-client="ca-pub-7526882407618262"
                data-ad-slot="6533020381"></ins>`,
            sid: '6533020381'
        },
        'dpad-winnings-tile-1': {
            tag: `<ins class="adsbygoogle"
                style="display:inline-block;width:300px;height:250px"
                data-ad-client="ca-pub-7526882407618262"
                data-ad-slot="5406854557"></ins>`,
            sid: '5406854557'
        },
        'dpad-winnings-tile-2': {
            tag: `<ins class="adsbygoogle"
                style="display:inline-block;width:300px;height:250px"
                data-ad-client="ca-pub-7526882407618262"
                data-ad-slot="8456739476"></ins>`,
            sid: '8456739476'
        },
        'dpad-winnings-tile-3': {
            tag: `<ins class="adsbygoogle"
                style="display:inline-block;width:300px;height:250px"
                data-ad-client="ca-pub-7526882407618262"
                data-ad-slot="7143657805"></ins>`,
            sid: '7143657805'
        },

        // Numbers - Generator page ads
        'dpad-generator-left': {
            tag: `<ins class="adsbygoogle"
                style="display:inline-block;width:300px;height:600px"
                data-ad-client="ca-pub-7526882407618262"
                data-ad-slot="6735188602"></ins>`,
            sid: '6735188602'
        },
        'dpad-generator-right': {
            tag: `<ins class="adsbygoogle"
                style="display:inline-block;width:300px;height:600px"
                data-ad-client="ca-pub-7526882407618262"
                data-ad-slot="5621734710"></ins>`,
            sid: '5621734710'
        },

        // Home, Payout Modal banner ads
        'dpad-payoutmodal-banner': {
            tag: `<ins class="adsbygoogle"
                style="display:block"
                data-ad-client="ca-pub-7526882407618262"
                data-ad-slot="3547440991"
                data-ad-format="auto"
                data-full-width-responsive="true"></ins>`,
            sid: '3547440991'
        },

        // Static pages banner ads
        'dpad-staticpage-banner-1': {
            tag: `<ins class="adsbygoogle"
                style="display:block"
                data-ad-client="ca-pub-7526882407618262"
                data-ad-slot="3803441925"
                data-ad-format="auto"
                data-full-width-responsive="true"></ins>`,
            sid: '3803441925'
        },
        'dpad-staticpage-banner-2': {
            tag: `<ins class="adsbygoogle"
                style="display:block"
                data-ad-client="ca-pub-7526882407618262"
                data-ad-slot="1724073493"
                data-ad-format="auto"
                data-full-width-responsive="true"></ins>`,
            sid: '1724073493'
        },
        'dpad-staticpage-banner-3': {
            tag: `<ins class="adsbygoogle"
                style="display:block"
                data-ad-client="ca-pub-7526882407618262"
                data-ad-slot="4593779412"
                data-ad-format="auto"
                data-full-width-responsive="true"></ins>`,
            sid: '4593779412'
        },
        'iaad-articlepage-bgnone-1': {
            tag: `<ins class="adsbygoogle"
                style="display:block; text-align:center;"
                data-ad-layout="in-article"
                data-ad-format="fluid"
                data-ad-client="ca-pub-7526882407618262"
                data-ad-slot="9206977691"></ins>`,
            sid: '9206977691'
        },
        'iaad-articlepage-bgnone-2': {
            tag: `<ins class="adsbygoogle"
                style="display:block; text-align:center;"
                data-ad-layout="in-article"
                data-ad-format="fluid"
                data-ad-client="ca-pub-7526882407618262"
                data-ad-slot="7012113091"></ins>`,
            sid: '7012113091'
        },
        'iaad-articlepage-bgnone-3': {
            tag: `<ins class="adsbygoogle"
                style="display:block; text-align:center;"
                data-ad-layout="in-article"
                data-ad-format="fluid"
                data-ad-client="ca-pub-7526882407618262"
                data-ad-slot="3072868089"></ins>`,
            sid: '3072868089'
        },
        'iaad-articlepage-bgdark-1': {
            tag: `<ins class="adsbygoogle"
                style="display:block; text-align:center;"
                data-ad-layout="in-article"
                data-ad-format="fluid"
                data-ad-client="ca-pub-7526882407618262"
                data-ad-slot="5830839463"></ins>`,
            sid: '5830839463'
        },
        'iaad-articlepage-bgdark-2': {
            tag: `<ins class="adsbygoogle"
                style="display:block; text-align:center;"
                data-ad-layout="in-article"
                data-ad-format="fluid"
                data-ad-client="ca-pub-7526882407618262"
                data-ad-slot="9511118074"></ins>`,
            sid: '9511118074'
        }
    };
}
