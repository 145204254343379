/**
 * jshint esversion: 8
 */
'use strict';

export default class Hooks {
    static useContext(reducer, router, user) {
        const [context, action] = this.initializeContext(router, user);
        const dispatch = (path, query, cta) => action(path, query, cta, reducer);
        return [context, dispatch];
    }

    static initializeContext(router, user) {
        let state = {
            router: router,
            user: user,
            prev: {
                path: null,
                query: '',
            },
            next: {
                path: null,
                query: ''
            }
        };

        /**
         * The action that is taking place when the reducer (navigate method defined in router.js) of the hook is dispatched.
         *
         * @param {string} path: the pathname string of target URL
         * @param {string} query: the search string of target URL
         * @param {string} cta: the values are: goback; stayon
         * @param {function} reducer: the function "navigate", defined in router.js, that is called when the reducer is dispatched
         */
        const action = (path, query, cta, reducer) => {
            const resetPathQuery = (pval, qval) => [path, query] = [pval || '/', qval || ''];

            if (cta === 'stayon' && state.next.path) {
                // triggered by sign-out event from "firebase.auth().onAuthStateChanged" in router.js
                if (['/manageact', '/authenticate', '/updprofile', '/termaccount', '/checkstatus', '/optnews'].includes(path)) {
                    // after logged out, redirect the pages that require login to the home ('/') page.
                    resetPathQuery();
                } else {
                    return;
                }
            } else if (cta === 'goback') {
                // triggered by sign-in event from "firebase.auth().onAuthStateChanged" in router.js
                if (['/optnews'].includes(state.prev.path)) {
                    resetPathQuery();
                } else if (['/authenticate'].includes(path) && !state.prev.path) {
                    resetPathQuery('/manageact');
                } else if (state.prev.path) {
                    // user signs in from UI using userid and password
                    resetPathQuery(state.prev.path, state.prev.query);
                }
            }

            state.prev.path = state.next.path;
            state.prev.query = state.next.query;
            state.next.path = path || '/';
            state.next.query = query || '';
            this.pushToBrowserHistory(state.next.path, state.next.query);
            this.updateCanonicalLink(state.next.path, state.next.query, state.router);
            reducer(state);
        }

        return [state, action];
    }

    static pushToBrowserHistory(pathName, queryString, addionalState) {
        const state = addionalState ? {...addionalState, ...{isMine: true}} : {isMine: true};
        const url = window.location.origin + pathName + queryString;
        if (this.visitedBefore) {
            window.history.pushState(state, pathName, url);
        } else {
            window.history.replaceState(state, pathName, url);
            this.visitedBefore = true;
        }
    }

    static updateCanonicalLink(path, query, router) {
        const canonicalLink = document.getElementById('canonical-link');
        canonicalLink.setAttribute('href', `${router.global.webHomeUrl}${path}${query}`);
    }
}
