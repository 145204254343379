/**
 * jshint esversion: 8
 *
 * This class BasePage is the base for all the pages, including AboutPage, Registration, Authentication,
 * ManageAccount, EmailLogin, ProfileUpdate, Privacy Policy, Terms & Conditions, etc.
 *
 * It sets up the page layout, which includes header, footer, content section placeholder space, user consent,
 * by using PageLayout class from ./page-layout.js.
 *
 * To instantiate an instance, the class calls two methods of its subclass:
 *     a mandatory method getContent(), which renders the main body, the content section part, of the page.
 *     an optional method initialize(), which is in some cases needed after the content has been rendered.
 *
 * the subclass fills in ${this.layout.main} placeholder space and decides showing or hiding header and/or footer.
 */
'use strict';

import UserAdminBase from '../../lib/page-base.js';
import Utils from '../../lib/utils.js';
import '../../../css/lottery-balls.scss';

export default class LotteryBase extends UserAdminBase {
    constructor(global) {
        super(global);
        this.megamillions = {
            histUrl: 'powermega/feeds/hist-mm.pbi',
            statsUrl: 'powermega/feeds/stats-mm.pbi'
        };
        this.powerball = {
            histUrl: 'powermega/feeds/hist-pb.pbi',
            statsUrl: 'powermega/feeds/stats-pb.pbi'
        };
        this.sref = global.firebase.storage().ref();

    }

    renderPrevDraw(parent, type, clsName = 'draw-info', showWinnings = false) {
        const ltype = this[type];
        const sect = Utils.addElement(parent, 'div', clsName);
        ltype.prevDrawDate = Utils.addElement(sect, 'div', 'date');
        ltype.prevNumsJp = Utils.addJackpotNumbers(sect, type, [0, 0, 0, 0, 0, 0], 0);
        if (type === 'powerball') ltype.prevNumsDp = Utils.addJackpotNumbers(sect, type, [0, 0, 0, 0, 0, 0], 0, undefined, 'black');
        const wframe = Utils.addElement(sect, 'div', 'winner');
        ltype.prevFlash = Utils.addElement(wframe, 'div', 'logos flash');
        ltype.prevWinner = Utils.addElement(wframe, 'div', 'fs-pt12');
        ltype.prevAnnuity = this.addBlockText(sect, 'right', 'ANNUITY', null, 'fs-pt12');
        ltype.prevCash = this.addBlockText(sect, 'left', 'CASH VALUE', null, 'fs-pt12');

        if (showWinnings) {
            const winsDiv = Utils.addElement(sect, 'div');
            if (this.isPowerball) {
                this.matched = this.addBlockText(winsDiv, 'right', 'POWERBALL MATCHED', null, 'fs-pt12');
                this.dpmatched = this.addBlockText(winsDiv, 'left', 'DOUBLE PLAY MATCHED', null, 'fs-pt12');
            } else {
                this.matched = this.addBlockText(winsDiv, 'center', 'MATCHED', null, 'fs-pt12');
            }
        }
    }

    loadPrevDrawInfo(ltype, prevData) {
        if (!prevData) prevData = ltype.dailyFeed.prev;
        if (prevData) {
            ltype.prevDrawDate.innerText = Utils.formatDate(prevData.ddate, true, false);
            Utils.setJackpotNumbers(ltype.prevNumsJp, prevData.wnum, prevData.mplier);
            if (prevData.dpwnum) {
                Utils.setJackpotNumbers(ltype.prevNumsDp, prevData.dpwnum, 'DP');
            } else if (ltype.prevNumsDp) {
                ltype.prevNumsDp.style.display = 'none';
            }
            ltype.prevWinner.innerText = prevData.winner;
            if (prevData.winner && /\d/.test(prevData.winner)) {
                ltype.prevFlash.style.display = 'inline-block';
            }
            ltype.prevAnnuity.innerText = Utils.formatAmount(prevData.jpot);
            ltype.prevCash.innerText = Utils.formatAmount(prevData.cash);
        }
    }

    addBlockText(parent, align, label, value, valueSize) {
        const block = Utils.addElement(parent, 'div', 'half');
        Utils.addElement(block, 'div', `label ${align} fs-pt75`).innerHTML = label;
        let valueClass = `value ${align}`;
        if (valueSize) valueClass += ` ${valueSize}`;
        const valobj = Utils.addElement(block, 'div', valueClass);
        if (value) valobj.innerHTML = value;
        return valobj;
    }

    renderPayoutsPopup(btn, gameData, adUnitName) {
        const me = btn.me;
        const prevData = gameData || me[btn.ltype].dailyFeed.prev;
        const popup = me.layout.createModalPopup();
        popup.title.innerText = btn.ltype === 'powerball' ? 'Powerball Payouts' : 'Mega Millions Payouts';
        me.renderPrevDraw(popup.content, btn.ltype);
        me.loadPrevDrawInfo(me[btn.ltype], prevData);

        if (adUnitName) {
            Utils.addElement(popup.content, 'div', adUnitName).innerHTML = this.insertAd(adUnitName);
            this.loadAds(adUnitName);
        }

        const poframe = Utils.addElement(popup.content, 'div', 'poframe');
        let table = Utils.addElement(poframe, 'table', 'payouts');

        const addOneRow = (cell, data) => {
            const tr = Utils.addElement(table, 'tr');
            const anums = data[0].split('+');
            const balls = Utils.addElement(tr, cell, 'payout-balls');
            if (/\d/.test(anums)) {
                for (let i = 0; i < parseInt(anums[0]); i++) Utils.addElement(balls, 'div', 'ball circle small white');
                const color = btn.ltype === 'powerball' ? 'red' : 'yellow';
                if (anums[1].trim() === '1') Utils.addElement(balls, 'div', `ball circle small ${color}`);
            } else {
                balls.innerText = data[0];
            }
            for (let i = 0; i < data.length; i++ ) {
                const tblcell = Utils.addElement(tr, cell);
                if (i === 0) tblcell.className = 'payout-match';
                tblcell.innerText = data[i];
            }
        }

        const mplier = prevData.mplier;
        const prepareDate = (keyp, keyw) => {
            const numo = prevData.twinners ? (prevData.twinners[keyw][0]).toLocaleString() : '';
            const numx = keyp === '5 + 1' ? '' : (prevData.twinners ? (prevData.twinners[keyw][1]).toLocaleString() : '');
            const amt = prevData.payout[keyp];
            const amto = Utils.formatAmount(amt, true);
            const amtx = keyp === '5 + 1' ? '' : Utils.formatAmount(mplier * amt, true);
            return [keyp, amto, numo, amtx, numx];
        }

        const payouts = prevData.payout;
        if (payouts) {
            addOneRow('th', ['MATCH', 'PRIZE', 'WINS', `PRIZE (${mplier}X)`, `WINS (${mplier}X)`]);
            const keys = Object.keys(payouts);
            for (let i = 0; i < keys.length; i++ ) addOneRow('td', prepareDate(keys[i], `t${i}`));
        }

        if (btn.ltype === 'powerball') {
            const dppayouts = prevData.dppayout;
            if (dppayouts) {
                table = Utils.addElement(poframe, 'table', 'payouts');
                addOneRow('th', ['MATCH', 'DOUBLE PLAY PRIZE']);
                Object.keys(dppayouts).forEach(key => addOneRow('td', [key, Utils.formatAmount(dppayouts[key])]));
            }
        }
        popup.reposition();

    }

    getLotteryType() {
        const type = Utils.getQueryParam(window.location.search, 'type');
        this.type = type && ['pb', 'powerball'].includes(type.toLowerCase()) ? 'powerball' : 'megamillions';
        this.isPowerball = this.type === 'powerball';
        return this.type;
    }

    addLotteryTab(parent, path) {
        this.getLotteryType();
        const tabsBar = Utils.addElement(parent, 'div', 'ltype-tab');
        const switchTab = async params => {
            let queryString = Utils.getQueryParam(window.location.search, 'act');
            queryString = queryString ? `${params.qstring}&act=${queryString}` : params.qstring;

            // To refresh the entire "Numbers" page use dispatch(), otherwise use updateNavigationUrl() and renderContent() to keep the page state
            // this.global.router.dispatch(path, queryString);
            this.global.router.updateNavigationUrl(path, queryString);
            params.me.getLotteryType();
            await this.renderContent();

            const tabs = tabsBar.tabGroup.children;
            for (let i = 0; i < tabs.length; i++) tabs[i].disabled = false;
            if (tabsBar.tabGroup.tabSelected) tabsBar.tabGroup.tabSelected.disabled = true;
        }

        const preprocess = params => {
            if (params.me.selectedTab && typeof params.me.selectedTab.backup === 'function') params.me.selectedTab.backup();
            return true;
        }

        Utils.createaTabs(tabsBar, [{
            label: 'Mega Millions',
            precheck: preprocess,
            callback: switchTab,
            params: {qstring: '?type=mm', me: this},
            default: !this.isPowerball
        }, {
            label: 'Powerball',
            precheck: preprocess,
            callback: switchTab,
            params: {qstring: '?type=pb', me: this},
            default: this.isPowerball
        }]);

        if (tabsBar.tabGroup.tabSelected) tabsBar.tabGroup.tabSelected.disabled = true;
    }

    async getHistoricalData(url) {
        const ltype = this[this.type];
        if (!ltype.hist) ltype.hist = this.readHistoricalFeeds(url);
        return ltype.hist;
    }

    async readHistoricalFeeds(url) {
        return Utils.downloadStorageJson(this.sref.child(url), true).then(histJson => {
            const games = [];
            histJson.games.forEach(game => games.push(game));

            if (histJson.index.prev) {
                const path = histJson.index.prev.path;
                if (path) return this.readHistoricalFeeds(path).then(subset => games.concat(subset));
            } else {
                return games;
            }
        });
    }

    showAlertMessage(msg, parent, title) {
        const msgbox = this.layout.createModalPopup('alert', parent || this.content, 320);
        msgbox.title.innerText = title || 'Warning';
        msgbox.content.innerHTML = msg;
        msgbox.configOneButton('OK');
        msgbox.button1.onclick = msgbox.cleanup;
        return msgbox;
    }

    showSignInRequired() {
        return this.showAlertMessage('Please sign in to enable the "My Favorites" feature.');
    }
}
