/**
 * jshint esversion: 8
 */
'use strict';

export default class GeoLocation {
    static geo = {
        source: 'https://ipapi.co/json',
        remap: {
            enable: true,
            fields: {
                country: "country_name",
                countryCode: "country_code",
                region: "region_code",
                regionName: "region",
                city: "city",
                zip: "postal",
                lat: "latitude",
                lon: "longitude",
                timezone: "timezone",
                org: "org",
                asn: "asn",
                ip: "ip"
            }
        }
    }
}
