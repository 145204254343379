/* jshint esversion: 8 */
'use strict';

import {Buffer} from 'buffer';
import MessageBox from '../../lib/msgbox.js';
import BasePage from '../../lib/page-base.js';
import Utils from '../../lib/utils.js';

export default class UserAdminBase extends BasePage {
    constructor(global) {
        super(global);
        this.ucol = global.firebase.firestore().collection(global.dbname).doc('members').collection('users');
    }

    getContent() {
        this.layout.main.classList.add('vcenter');
        const container = Utils.addElement(null, 'div', 'container');
        this.renderHeader(container);
        this.loadContentSection();
        this.renderFooter(container);
        this.hideAuthHeaderFooter();
        this.container = container;
        return container;
    }

    renderHeader(parent) {
        this.framebox = Utils.addElement(parent, 'div', 'frame-box');
        this.logo48x48 = Utils.addElement(this.framebox, 'div', 'logos', 'applogo48x48');
    }

    renderFooter(parent) {
        this.footer = Utils.addElement(parent, 'footer', 'footer-auth');
        let element = Utils.addElement(this.footer, 'div', 'legal');
        element = Utils.addElement(element, 'span', 'links');
        Utils.addAnchor(element, undefined, '/privacy?wv=true', 'Privacy', undefined, true);
        Utils.addAnchor(element, undefined, '/terms?wv=true', 'Terms', undefined, true);
    }

    async getStates() {
        if (this.global.states) {
            return Promise.resolve(this.global.states);
        } else {
            const sref = this.global.firebase.storage().ref().child('powermega/feeds/state-members.json');
            return Utils.downloadStorageJson(sref).then(states => {
                this.global.states = states;
                return states;
            });
        }
    }

    addStateSelectOptions(select) {
        Utils.addElement(select, 'option').text = 'Select your state';
        if (this.states) {
            this.states.states.every(i => {
                Utils.addElement(select, 'option').text = i;
                return true;
            })
        }
    }

    clickCheckbox(buttonId) {
        const submitBtn = document.getElementById(buttonId);
        if (this.checkbox1 && this.checkbox1.checked && this.checkbox2 && this.checkbox2.checked) {
            submitBtn.removeAttribute('disabled');
        } else if (!this.checkbox1 && this.checkbox2 && this.checkbox2.checked) {
            submitBtn.removeAttribute('disabled');
        } else {
            submitBtn.disabled = true;
        }
    }

    validateEmail(email) {
        return (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email.trim()))
    }

    resetForm() {
        if (this.email) this.email.value = '';
        if (this.password) this.password.value = '';
        if (this.errormsg) this.errormsg.innerText = '';
    }

    writeToUserJourney(pmUserRef, logMsg) {
        if (logMsg) {
            return pmUserRef.collection('journey').doc(new Date().toISOString()).set({desc: logMsg});
        } else {
            return Promise.resolve();
        }
    }

    async getUserData(authUser, pmUserDoc) {
        let name = (this.name && this.name.value) || authUser.displayName;
        let state = this.select && this.select.selectedIndex > 0 ? this.select.options[this.select.selectedIndex].label : '';
        let created = new Date().toISOString();
        let avatar = authUser.photoURL || '';
        let location = {};

        if (pmUserDoc) {
            const pmUser = pmUserDoc.data();
            name = name || pmUser.name;
            state = state || pmUser.state;
            created = pmUser.created || created;
            avatar = avatar || pmUser.state;
            location = pmUser.location;
        }

        if (JSON.stringify(location) === '{}') {
            const geoInfo = await Utils.fetch(this.global.geo.source).then(response => response.json());
            if (this.global.geo.remap.enable) {
                const mapping = this.global.geo.remap.fields;
                Object.keys(mapping).forEach(field => location[field] = geoInfo[mapping[field]]);
            } else {
                location = geoInfo;
            }
        }

        const udata = {
            email: authUser.email || this.email.value,
            name: name,
            registered: true,
            uid: authUser.uid,
            avatar: authUser.photoURL || '',
            created: created,
            location: location
        };

        if (state) udata.state = state;

        return udata;
    }

    async createUserInFirestore(authUser) {
        return this.getUserData(authUser).then(data => {
            const logMsg = 'Created account "' + data.name + '" with email "' + data.email + '" and ID "' + data.uid + '".';
            return this.setPowermegaUser(this.ucol.doc(authUser.uid), data, false, logMsg);
        });
    }

    async setPowermegaUser(pmUserRef, data, merge, logMsg) {
        if (merge) {
            return pmUserRef.set(data, {merge: true}).then(() => {
                return this.writeToUserJourney(pmUserRef, logMsg);
            })
        } else {
            return pmUserRef.set(data).then(() => {
                return this.writeToUserJourney(pmUserRef, logMsg);
            })
        }
    }

    confirmPasswordReset(email) {
        const msgbox = new MessageBox('alert', this.container, 320);
        msgbox.title.innerText = 'Reset Password';
        msgbox.configOneButton('OK').onclick = () => msgbox.cleanup();
        this.auth.sendPasswordResetEmail(email).then(() => {
            msgbox.content.innerText = 'An email has been sent to your mailbox "' + email + '", please follow the instruction in the email to reset your password.';
        }).catch(error => {
            msgbox.content.innerText = error.message;
        });
    }

    getProviders(providers, simpleArray = false) {
        let result = 'This account has';
        try {
            for (let pid of providers) {
                const provider = simpleArray ? pid : pid.providerId;
                if (provider === 'password') {
                    result += ' <b>Powermega</b>,';
                } else if (provider === 'google.com') {
                    result += ' <b>Google</b>,';
                } else if (provider === 'facebook.com') {
                    result += ' <b>Facebook</b>,';
                } else if (provider === 'apple.com') {
                    result += ' <b>Apple</b>,';
                }
            }
            result = result.substring(0, result.length -1) + ' sign-in method(s) enabled.';
        } catch(error) {
            result = '';
        }
        return result;
    }

    setCookie(cname, cvalue, expsecs) {
        const dt = new Date();
        dt.setTime(dt.getTime() + expsecs * 1000);
        document.cookie = `${cname}=${cvalue};expires=${dt.toUTCString()};path=/`;
      }

    getCookie(cname) {
        let cvalue;
        if (document.cookie) {
            const kvps = document.cookie.split(';');
            for (let i = 0; i < kvps.length; i++) {
                const kv = kvps[i].split('=');
                if (kv.length === 2 && (decodeURIComponent(kv[0]).trim() === cname)) cvalue = decodeURIComponent(kv[1].trim());
            }
        }
        return cvalue
    }

    encodeEmail(str) {
        let data = Buffer.from(str, 'utf-8');
        data =  data.toString('base64');
        const charmap = {'+':'.', '/':'_', '=':'-'};
        return data.replace(/[+/=]/g, m => charmap[m]);
    }

    decodeEmail(str) {
        const charmap = {'.':'+', '_':'/', '-':'='};
        let data = str.replace(/[._-]/g, m => charmap[m]);
        data = Buffer.from(data, 'base64');
        return data.toString('utf-8');
    }

    hideAuthHeaderFooter(hide = true) {
        const display = hide ? 'none' : '';
        this.logo48x48.style.display = display;
        this.footer.style.display = display;
    }
}
