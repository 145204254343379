/* jshint esversion: 8 */
'use strict';

import LotteryBase from './lottery-base.js';
import Utils from '../../lib/utils.js';
import '../../../css/stats-page.scss';


export default class Statistics extends LotteryBase {
    constructor(global) {
        super(global);
        this.pageName = 'Statistics';
    }

    initialize() {
        this.layout.selectMenuItem(this.layout.menus.numbers.menus.stats.item);
    }

    async getContent() {
        this.root = Utils.addElement(null, 'div', 'stats-root');
        this.addLotteryTab(this.root, '/stats');
        const ltype = this[this.type];
        Utils.addElement(this.root, 'div', null, 'dpad-stats-leaderboard').innerHTML = this.insertAd('dpad-stats-leaderboard');
        const sbox = Utils.addElement(this.root, 'div', 'stats-box');

        return Utils.downloadStorageJson(this.sref.child(ltype.statsUrl), true).then(statsJson => {
            const processData = (records, total) => {
                const result = [];
                records.forEach(record => {
                    const len = record.occured.length;
                    result.push([record.number, len, len / total, new Date(record.occured[0])]);
                });
                return result;
            }

            let table = this.renderTable(sbox, ['Ball Number', 'Time Drawn', '% of Draws', 'Last Draw'], false);
            if (!ltype.rballs) ltype.rballs = processData(statsJson.rballs.records, statsJson.rballs.total);
            this.refreshTable(table, 1, false);

            table = this.renderTable(sbox, ['Ball Number', 'Time Drawn', '% of Draws', 'Last Draw'], true);
            if (!ltype.cballs) ltype.cballs = processData(statsJson.cballs.records, statsJson.cballs.total);
            this.refreshTable(table, 1, true);

            const notes = this.isPowerball ? `<b>Note</b>: The statistics are based on <b>${statsJson.cballs.total}</b> drawings since October 7, 2015.`
                 : `<b>Note</b>: The statistics are based on <b>${statsJson.cballs.total}</b> drawings since October 31, 2017.`;
            Utils.addElement(table.parentNode, 'div', 'footnotes').innerHTML = notes;

            Utils.addElement(table.parentNode, 'div', null, 'mpad-stats-multiplex').innerHTML = this.insertAd('mpad-stats-multiplex');

            return this.root;
        });
    }

    renderTable(parent, columns, colorBall) {
        const frame = Utils.addElement(parent, 'div', 'tbl-frame');
        const table = Utils.addElement(frame, 'table', 'sortable');

        const numberToPercentage = num => {
            const percentage = (num * 100).toFixed(2);
            return `${percentage}%`;
        }

        const utcToLocalDate = utcDate => {
            const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
            return utcDate.toLocaleDateString(undefined, options);
        }

        const tr = Utils.addElement(table, 'tr');
        const addTh = (index, className = 'down inactive') => {
            const th = Utils.addElement(tr, 'th', 'sort');
            Utils.addElement(th, 'div', 'name').innerText = columns[index];
            const icon = Utils.addElement(th, 'i', `icon ${className}`);
            th.onclick = () => this.refreshTable(table, index, colorBall);
            return icon;
        }

        const openInfoPopup = () => {
            const path = this.isPowerball ? '/statspb' : '/statsmm';
            const popup = Statistics.getInfoPopup(info, 500);
            this.global.router.getPageContent(path).then(content => {
                popup.content.innerHTML = content;
                popup.reposition();
            });
        }

        table.column0 = addTh(0, 'up inactive');
        table.column1 = addTh(1, 'down inactive');
        table.column2 = addTh(2, 'down inactive');
        table.column3 = addTh(3, 'down inactive');
        let info = Utils.addElement(tr, 'th', 'sort');
        info = Utils.addElement(info, 'div', 'logos info-icon');
        info.me = this;
        info.onclick = openInfoPopup;

        table.addOneRow = (record, colorBall = false) => {
            const tr = Utils.addElement(table, 'tr');
            const className = colorBall ? (this.isPowerball ? 'red' : 'yellow') : 'white';
            const td = Utils.addElement(tr, 'td');
            Utils.addElement(td, 'div', `ball middle circle ${className}`).innerText = record[0].toString().padStart(2, '0');
            Utils.addElement(tr, 'td').innerText = record[1].toString();
            Utils.addElement(tr, 'td').innerText = numberToPercentage(record[2]);
            Utils.addElement(tr, 'td', 'wider').innerText = utcToLocalDate(record[3]);
            const arrow = Utils.addElement(tr, 'td');
            Utils.addElement(arrow, 'div', 'logos right-arrow');
            tr.me = this;
            tr.onclick = () => {
                this.global.router.showLoader();
                const ltype = this[this.type];
                this.getHistoricalData(ltype.histUrl).then(games => {
                    const popup = Statistics.getInfoPopup(tr);
                    popup.content.classList.add('stats');

                    const infoIcon = Utils.addElement(popup.content, 'div', 'logos info-icon upright');
                    infoIcon.onclick = openInfoPopup;

                    const tds = tr.children;
                    let ballInfo = Utils.addElement(popup.content, 'div', 'ball-info top');
                    Utils.addElement(ballInfo, 'div', `ball large circle ${className}`).innerText = tds[0].firstChild.innerText;
                    ballInfo = Utils.addElement(popup.content, 'div', 'ball-info bottom');
                    const addBallInfo = (label, idx, clsName) => {
                        const frame = Utils.addElement(ballInfo, 'div', clsName);
                        Utils.addElement(frame, 'div', 'label fs-pt75').innerText = label;
                        Utils.addElement(frame, 'div', 'value right').innerText = idx >= 0 ? tds[idx].innerText : numberToPercentage(record[2] * 5);
                        return frame;
                    }
                    addBallInfo('TIMES DRAWN', 1, 'lblock left');
                    if (!colorBall) addBallInfo('% OF DRAWINGS', -1, 'lblock');
                    addBallInfo('% OF DRAWS', 2, 'lblock right');

                    const list = Utils.addElement(popup.content, 'div', 'jackpot-list');
                    const ballNumber = parseInt(tds[0].firstChild.innerText, 10);

                    let tileCount = 0, adCount = 0, auNamePrefix = 'dpad-history-tile', placements = [2, 6, 16, 30];
                    const renderBalls = (game, index) => {
                        // insert ads
                        if (placements.includes(++tileCount) && adCount < placements.length) {
                            const adUnitName = `${auNamePrefix}-${++adCount}`;
                            Utils.addElement(list, 'div', auNamePrefix).innerHTML = this.insertAd(adUnitName);
                            this.loadAds(adUnitName);
                        }

                        // render game tile
                        const frame = Utils.addElement(list, 'div', 'jackpot-tile');
                        frame.me = this;
                        frame.ltype = this.type;
                        frame.onclick = event => {
                            event.stopPropagation();
                            this.renderPayoutsPopup(event.target.closest('.jackpot-tile'), game, 'dpad-payoutmodal-banner');
                        }
                        this.renderPrevDraw(frame, this.type);
                        this.loadPrevDrawInfo(this[this.type], game);
                        if (ltype.prevNumsDp) ltype.prevNumsDp.style.display = 'none';
                        ltype.prevNumsJp.children[index].classList.add('selected-blue');
                    }

                    games.forEach(game => {
                        if (colorBall && game.wnum[5] === ballNumber) {
                            renderBalls(game, 5);
                        } else if (!colorBall) {
                            const index = game.wnum.slice(0, 5).indexOf(ballNumber);
                            if (index >= 0) renderBalls(game, index);
                        }
                    });
                    popup.reposition();
                    this.global.router.hideLoader();
                });
            }
        }
        return table;
    }

    refreshTable(table, activeColumn, colorBall) {
        const balls = colorBall ? this[this.type].cballs : this[this.type].rballs;

        for (let i = 0; i < 4; i++) {
            if (i === activeColumn) {
                const clist = table[`column${i}`].classList;
                const ascend = clist.contains('up');
                if (clist.contains('inactive')) {
                    clist.replace('inactive', 'active');
                    balls.sort((a, b) => ascend ? a[i] - b[i] : b[i] - a[i]);
                } else {
                    if (ascend) {
                        balls.sort((a, b) => b[i] - a[i]);
                        clist.replace('up', 'down');
                    } else {
                        balls.sort((a, b) => a[i] - b[i]);
                        clist.replace('down', 'up');
                    }
                }
            } else {
                table[`column${i}`].classList.replace('active', 'inactive');
            }
        }
        const rows = table.rows;
        for (let i = rows.length - 1; i > 0; i--) Utils.clearNode(rows[i], true);
        balls.forEach(record => table.addOneRow(record, colorBall));
    }

    static getInfoPopup(target, width) {
        const popup = target.me.layout.createModalPopup(null, null, width);
        popup.title.style.display = 'none';
        return popup;
    }
}
