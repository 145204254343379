/* jshint esversion: 8 */
'use strict';

import UserAdminBase from './user-base.js';
import Utils from '../../lib/utils.js';

export default class ProfileUpdate extends UserAdminBase {
    constructor(global) {
        super(global);
        this.pageName = 'Profile Update';
    }

    async loadContentSection() {
        const me = this;
        this.user = this.auth.currentUser;
        Utils.addElement(this.framebox, 'div', 'title').innerText = 'Update Account Profile';
        const content = Utils.addElement(this.framebox, 'div', 'section');

        if (this.user) {
            // registration form
            const regForm = Utils.addElement(content, 'form', 'content');
            Utils.addElement(regForm, 'div').innerText = 'Please provide your personal information below.';
            Utils.addElement(regForm, 'div', 'label').innerText = 'FIRST AND LAST NAME';
            this.name = Utils.addElement(regForm, 'input', 'inputbox');
            this.name.value = this.user.displayName;

            // Email Address
            Utils.addElement(regForm, 'div', 'label').innerText = 'EMAIL';
            let oBtnDiv = Utils.addElement(regForm, 'div', 'tooltip-input');
            this.email = Utils.addElement(oBtnDiv, 'input', 'inputbox');
            this.email.setAttribute('type', 'email');
            this.email.setAttribute('autocomplete', 'username');
            this.email.value = this.user.email;
            Utils.addElement(oBtnDiv, 'div', 'tooltiptext').innerText = 'Email address is the unique identifier and cannot be changed.';
            this.email.disabled = true;

            // Password
            this.plabel = Utils.addElement(regForm, 'div', 'label');
            this.plabel.innerHTML = 'PASSWORD';
            oBtnDiv = Utils.addElement(regForm, 'div', 'tooltip-input');
            this.password = Utils.addElement(oBtnDiv, 'input', 'inputbox', 'profile-password');
            this.password.setAttribute('type', 'password');
            this.password.setAttribute('autocomplete', 'current-password');
            const eyeicon = Utils.addElement(oBtnDiv, 'div', 'logos eye-icon open');
            eyeicon.onclick = () => Utils.togglePassword(eyeicon, 'profile-password');
            if (this.isOauthAccountOnly()) {
                Utils.addElement(oBtnDiv, 'div', 'tooltiptext').innerText = 'The password cannot be changed for Google, Facebook, or Apple login.';
                this.password.disabled = true;
            }

            Utils.addElement(regForm, 'div', 'label').innerText = 'STATE';
            this.select = Utils.addElement(regForm, 'select', 'dropdown');
            this.getStates().then(states => {
                me.states = states;
                me.addStateSelectOptions(me.select);
                me.getUserState().then(ustate => {
                    if (ustate) {
                        for (let iobj of me.select.options) {
                            if (iobj.text === ustate) {
                                iobj.selected = true;
                                return;
                            }
                        }
                    }
                });
            })

            this.errormsg = Utils.addElement(regForm, 'div', 'login-msg height60 info');

            // Submit button
            oBtnDiv = Utils.addElement(regForm, 'div', 'button-frame');
            oBtnDiv.style.marginTop = '0px';
            let btn = Utils.addElement(oBtnDiv, 'button', 'submit-button', 'update-button');
            btn.innerText = 'Submit';
            btn.onclick = async event => {
                event.preventDefault();
                me.register().then(() => {
                    me.errormsg.innerHTML = 'User information has been successfully updated.';
                }).catch(error => {
                    me.errormsg.innerHTML = error.message;
                })
            }
        } else {
            const form = Utils.addElement(content, 'form', 'content');
            Utils.addElement(form, 'div').innerText = 'Please click on the "Sign In" button in the upper-right corner to log in first before you can update your account profile.';
        }
    }

    initialize() {
        if (this.user) this.resetForm();
    }

    register() {
        let msg = '';
        this.errormsg.innerText = '';
        if (this.name.value.trim().length === 0) msg += '<li>Name field cannot be empty.</li>';
        const password = this.password.value.trim();
        if (password.length > 0 && password.length < 6) msg += '<li>Password length cannot be smaller than 6.</li>';
        if (this.select.selectedIndex === 0) msg += '<li>State needs to be selected.</li>'

        if (msg.length > 0) {
            return Promise.reject({code: 'powermega/invalid-reg-parameters', message: '<ul>' + msg + '</ul>'});
        } else {
            // update Firebase Authentication user profile
            this.user.updateProfile({displayName: this.name.value.trim()}).catch(error => {
                return Promise.reject(error);
            });
            if (password.length > 0) {
                this.user.updatePassword(this.password.value.trim()).catch(error => {
                    return Promise.reject(error);
                });
            }

            // update Firestore Powermega database to set user registered flag to be true;
            return this.ucol.doc(this.user.uid).get().then(pmUserSnapshot => {
                return (pmUserSnapshot && pmUserSnapshot.data()) ? this.updatePowermegaUser(this.user, pmUserSnapshot) : this.createUserInFirestore(this.user);
            })
        }
    }

    updatePowermegaUser(authUser, pmUserSnapshot) {
        const data = {};
        const pmUser = pmUserSnapshot.data();
        const inputName = this.name.value.trim();
        if (!pmUser.registered) data.registered = true;
        if (!pmUser.name || (inputName.length > 0 && pmUser.name !== inputName)) data.name = this.name.value || authUser.displayName;
        const selectedState = this.select.options[this.select.selectedIndex].label;
        if (this.select.selectedIndex > 0 && selectedState !== pmUser.state) data.state = selectedState;
        return Object.keys(data).length === 0 ? Promise.resolve() : this.setPowermegaUser(pmUserSnapshot.ref, data, true, 'Updated user profile: ' + JSON.stringify(data) + '.');
    }

    getUserState() {
        return this.ucol.doc(this.user.uid).get().then(pmUserSnapshot => {
            return (pmUserSnapshot && pmUserSnapshot.data()) ? pmUserSnapshot.data().state : null;
        });
    }

    isOauthAccountOnly() {
        try {
            for (let i of this.user.providerData) {
                if (i.providerId === 'password') return false;
            }
        }catch(error) {
            console.log('[Error] failed in isOauthAccountOnly', error);
        }
        return true;
    }

    resetForm() {
        super.resetForm();
        this.name.value = '';
        this.select.selectedIndex = 0;
        this.plabel.innerHTML = 'PASSWORD';

        // if the user has logged in, populate the user profile fields
        if (this.user) {
            this.name.value = this.user.displayName;
            this.email.value = this.user.email;
            this.getUserState().then(ustate => {
                if (ustate) {
                    for (let iobj of this.select.options) {
                        if (iobj.text === ustate) {
                            iobj.selected = true;
                            return;
                        }
                    }
                }
            });
        }
    }

    askForPassword() {
        this.plabel.innerHTML = 'PASSWORD <span class="set-password">(Create your new Powermega password)</span>';
    }
}
