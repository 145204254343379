/* jshint esversion: 8 */
'use strict';

import BasePage from '../../lib/page-base.js';

export default class TermsConditions extends BasePage {
    constructor(global) {
        super(global);
        this.pageName = 'Terms and Conditions';
    }

    initialize() {
        if (this.layout.webview) {
            this.layout.hideHeaderFooter();
            this.showWebviewElement('pmb-webview');
        }
        this.initializeAds('iaad-articlepage-bgnone', 3);
    }

    getContent() {
        return `<div class="legal-page">
<div class="pm-brand hide-element" id="pmb-webview">
    <div class="logos pmlogo-36x36"></div>
    <div>
        <div class="b-title">POWERMEGA</div>
        <div class="b-subtitle">Helping You Win!</div>
    </div>
</div>
<h1>Powermega Terms & Conditions</h1>
<p class="notes">Last updated: April 05, 2020</p>
<p>Please read these Terms and Conditions ("Terms", "Terms and Conditions") carefully before using the Powermega application (the "Service") operated by powermega ("us", "we", or "our").</p>
<p>Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users and others who access or use the Service.</p>
<p>By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of the terms then you may not access the Service.</p>
<div class="iaad-articlepage-bgnone" id="iaad-articlepage-bgnone-1"></div>
<h2>1. Accounts</h2>
<p>When you create an account with us, you must provide us information that is accurate, complete, and current at all times. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of your account on our Service.</p>
<p>You are responsible for safeguarding the password that you use to access the Service and for any activities or actions under your password, whether your password is with our Service or a third-party service.</p>
<p>You agree not to disclose your password to any third party. You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account.</p>
<h2>2. Links To Other Web Sites</h2>
<p>Our Service may contain links to third-party web sites or services that are not owned or controlled by Powermega.</p>
<p>Powermega has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services. You further acknowledge and agree that  shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such web sites or services.</p>
<p>We strongly advise you to read the terms and conditions and privacy policies of any third-party web sites or services that you visit.</p>
<div class="iaad-articlepage-bgnone" id="iaad-articlepage-bgnone-2"></div>
<h2>3. Termination</h2>
<p>We may terminate or suspend access to our Service immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.</p>
<p>We may terminate or suspend your account immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.</p>
<p>All provisions of the Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.</p>
<p>Upon termination, your right to use the Service will immediately cease. If you wish to terminate your account, you may simply discontinue using the Service.</p>
<h2>4. Disclaimer</h2>
<p>Your use of the app, its content, and any items obtained through the app is at your own risk. The app, its content, and any items obtained through the app are provided on an "as is" and "as available" basis. Powermega makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights. Neither Powermega nor any person associated with Powermega makes any warranty or representation with respect to the completeness, security, reliability, quality, accuracy, or availability of the app, its content, or any items obtained through the app.</p>
<h2>5. Limitations</h2>
<p>Your access to and use of the app, its content, and any items obtained through the app is at your own risk. In no event will Powermega, its affiliates, licensors, and service providers, and its and their respective officers, directors, managers, employees, shareholders, contractors, agents, representatives, licensors, suppliers, successors, and assigns (collectively, the "released parties") be liable for damages of any kind, under any legal theory, arising out of or in connection with your access, use, or inability to use, the app, any sites linked to it, any content on the app or such other items obtained through the app, including any direct, indirect, special, incidental, consequential, or punitive damages, including but not limited to, personal injury, emotional distress, loss of revenue, loss of profits, loss of winnings, loss of data, loss of business or anticipated savings, loss of use, loss of goodwill, whether caused by tort (including negligence), breach of contract, or otherwise, even if foreseeable. The released parties assume no responsibility or liability for any errors or omissions in the content of the app or for any damages to or interference with equipment, mobile devices, programs, files, or other property, whether such damages are related to access or use of the app or any sites linked to it.</p>
<p>Except as provided otherwise in these terms and conditions of use, your sole remedy for dissatisfaction with or damage sustained in connection with the app or the content offered thereon is to stop using the app. Your permission to use the app may automatically terminate without notice at the company's sole discretion.</p>
<div class="iaad-articlepage-bgnone" id="iaad-articlepage-bgnone-3"></div>
<h2>6. Revisions and Errata</h2>
<p>The materials appearing on Powermega's app could include technical, typographical, or photographic errors.  Powermega does not warrant that any of the materials on its app are accurate, complete, or current.  Powermega may make changes to the materials contained on its app at any time without notice.  Powermega does not, however, make any commitment to update the materials.</p>
<h2>7. Governing Law</h2>
<p>These Terms shall be governed and construed in accordance with the laws of United States, without regard to its conflict of law provisions.</p>
<p>Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service, and supersede and replace any prior agreements we might have between us regarding the Service.</p>
<h2>8. Changes</h2>
<p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material we will try to provide at least 30 days notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.</p>
<p>By continuing to access or use our Service after those revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, please stop using the Service.</p>
<h2>9. Contact Us</h2>
<p>If you have any questions about these Terms, please contact us by email: contactus@powermega.net</p>
<br/><br/><br/><br/>
</div>`;
    }
}
