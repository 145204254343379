/* jshint esversion: 8 */
'use strict';

import BasePage from '../../lib/page-base.js';
import Utils from '../../lib/utils.js'


export default class NewFeatures extends BasePage {
    constructor(global) {
        super(global);
        this.pageName = 'New Features';
    }

    getContent() {
        const appVersion = Utils.getQueryParam(this.global.qstring, 'v');
        if (appVersion === '1.131') {
            return this.getVersion1131();
        } else if (appVersion === '1.920') {
            return this.getVersion1920();
        } else if (appVersion === '3.1.1') {
            return this.getVersion3011();
        } else if (appVersion === '3.10.15') {
            return this.getVersion31015();
        } else {
            return this.showErrorMsg(appVersion)
        }
    }

    // new features introduced in iOS version 1.6 (1.131)
    // this version added Track My Favorite Numbers feature
    getVersion1131() {
        return `<div class="static-root new-features">
<div class="nf-title">Track My Favorite Numbers</div>
<div class="nf-subtitle">a new feature of version 1.6</div>
<div class="nf-label">STEP 1 OF 3</div>
<div class="nf-description">Tap on the "Numbers" icon from the home screen.</div>
<img class="nf-images" src='./images/new-features/v1-6/img-01.jpg' />
<div class="nf-label">STEP 2 OF 3</div>
<div class="nf-description">Select the "Favorite #'s" tab and tap on the blue "Add" button at the upper right corner to add your favorite numbers.</div>
<div class="nf-description">Choose the numbers you would like to track by selecting the checkbox to the left of the numbers.</div>
<div class="nf-description">Tap on the blue "Add To" button at the lower left corner to add selected numbers to the tracking list.</div>
<img class="nf-images" src='./images/new-features/v1-6/img-02.jpg' />
<div class="nf-label">STEP 3 OF 3</div>
<div class="nf-description">Select the radio button at the upper left of the screen if you would like to track your numbers for every draw.  Or, you can choose a date in the calendar section below to track selected numbers just for that day.</div>
<img class="nf-images" src='./images/new-features/v1-6/img-03.jpg' />
<br/><br/>
<div class="nf-description"><b>That's it.</b> If you chose to track the numbers for every draw, you will see "My Tracked Numbers" on "Played List" as well as "Home" screens showing below.</div>
<img class="nf-images" src='./images/new-features/v1-6/img-04.jpg' />
<br/><br/>
<img class="nf-images" src='./images/new-features/v1-6/img-05.jpg' />
<br/><br/>
<div class="nf-notes"><b>Notes:</b> This tutorial is for Mega Millions and you can setup "My Favorite Numbers" for Powerball as well.</div>
<br/><br/><br/><br/>
</div>`;
    }

    // new features introduced in iOS version 2.0 (1.919)
    getVersion1920() {
        // 1. historical jackpot number statistics
        // 2. Lottery number generator - advanced Mega Millions and Powerball number generator
        // 3. improve ticket page usibility
        // 4. the group or pool play feature is moved into Settings page.
        return `<div class="static-root new-features">
<div class="nf-title">New Features</div>
<div class="nf-subtitle">introduced in version 2.0</div>

<div class="nf-label">1. POWERBALL® DOUBLE PLAY® SUPPORT</div>
<div class="nf-description">Double Play® is a new add-on feature to the Powerball® game that gives players in select jurisdictions another chance to match their Powerball numbers in a separate drawing with a top cash prize of $10 million. Double Play started from August 23, 2021.</div>
<div class="nf-description">The Powermega app is now showing both regular jackpot and Double Play winning numbers.  The app also tracks the Double Play winning numbers and winning amounts. See Figure 1 below.</div>
<img class="nf-images" src='./images/new-features/v2-0/img-01.png' />
<div class="nf-figure">Figure 1</div>
<br/>

<div class="nf-label">2. JACKPOT NUMBER STATISTICS</div>
<div class="nf-description">This newly introduced stats feature lists the times and percentage of each number was drawn.  It is based on all the drawings under current rules.  Mega Millions's new rules started from Tuesday, October 31, 2017, whith white ball numbers from 1 to 70 and mega ball number from 1 to 25.  Powerball's new rules started from Wednesday, October 7, 2015, with  white ball numbers from 1 to 69 and power ball number from 1 to 26.</div>
<div class="nf-description">To access the Stats information, tap on the "Numbers" icon from the home screen, and then select the "Stats" tab.  See Figure 2 and Figure 3 below.</div>
<img class="nf-images" src='./images/new-features/v2-0/img-02.png' />
<div class="nf-figure">Figure 2</div>
<br/>
<img class="nf-images" src='./images/new-features/v2-0/img-03.png' />
<div class="nf-figure">Figure 3</div>
<br/>

<div class="nf-label">3. REDESIGN OF THE TICKET SCREEN</div>
<div class="nf-description">To make it easier to track the scanned or manually inputted tickets, Powermega app has been added "Tickets" icon on the home screen.  The new Tickets screen combines the existing "My Plays" and "Tickets" features that were under "Numbers" section.  This Tickets screen allows users to locate their saved tickets easier.</div>
<div class="nf-description">To access the "Tickets" screen, tap on the "Tickets" icon from the home screen.  See Figure 4 below.</div>
<img class="nf-images" src='./images/new-features/v2-0/img-04.png' />
<div class="nf-figure">Figure 4</div>
<br/>

<div class="nf-label">4. MORE NEW FEATURES</div>
<div class="nf-description">There are a few new features added into the "More" screen, for example: "News", "New Features", etc.  The "Group" menu has been moved from the home screen into the "More" section and named as "Group Play".
</div>
<div class="nf-description">To access the "More" screen, tap on the icon at the upper-right corner of the home screen.  See Figure 5 below.</div>
<img class="nf-images" src='./images/new-features/v2-0/img-05.png' />
<div class="nf-figure">Figure 5</div>
<br/>

<!--
<div class="nf-label">5. Adanced Number Generator</div>
<div class="nf-description">The advanced generator creates Mega Millions or Powerball numbers based on users' preferences and criteria, including minimum or maximum number, odd vs.even ratio, etc.  The generated numbers can be stored under the user account for future use.</div>
<div class="nf-description">To access Advanced Generator, tap on the "Numbers" icon from the home screen, select the "Favorite #'s" tab, tap on the blue "Add" button at the upper-right corner, and then choose the blue "Advance" icon.  See the screenshot below.</div>
<img class="nf-images" src='./images/new-features/v2-0/img-05.png' />
<br/><br/>
-->

<br/><br/>
</div>`;
    }

    // new features introduced in iOS version 3.0 (3.1.1)
    getVersion3011() {
        // 1. support multi-draw tickets
        // 2. enhance breaking news feature by adding a link on the home page
        return `<div class="static-root new-features">
<div class="nf-title">A New Feature</div>
<div class="nf-subtitle">introduced in version 3.0</div>

<div class="nf-label">MULTI-DRAW TICKET SUPPORT</div>
<div class="nf-description">Some states offer the Multi-Draw Ticket, a game feature that allows players to select plays for up to twenty consecutive drawings on one ticket, starting with the next scheduled drawing. The cost of a Multi-Draw Ticket depends on the number of draws and the wager amount selected by the player. The Powermega app now supports this Multi-Draw Ticket feature.</div>
<br/>

<div class="nf-label">HOW DOES IT WORK?</div>
<div class="nf-description">After successfully scanning a ticket, you will see an "i" icon on the right side of the DRAW DATE row. This icon describes how to set up the date range of the Multi-Draw Ticket. See figures 1 and 2 below.</div>
<img class="nf-images" src='./images/new-features/v3-0/img-01.png' />
<div class="nf-figure">Figure 1</div>
<br/>
<img class="nf-images" src='./images/new-features/v3-0/img-02.png' />
<div class="nf-figure">Figure 2</div>
<br/>

<div class="nf-description">In Ticket Edit mode, tap on the pencil icon on the right side of the DRAW DATE row. See Figure 3 below.</div>
<img class="nf-images" src='./images/new-features/v3-0/img-03.png' />
<div class="nf-figure">Figure 3</div>
<br/>

<div class="nf-description">On the calendar screen, tap on the Multi-Draw button at the top, select the start date, then the end date. See Figure 4 below.</div>
<img class="nf-images" src='./images/new-features/v3-0/img-04.png' />
<div class="nf-figure">Figure 4</div>
<br/>

<div class="nf-description">After exiting the calendar screen, you should see the selected date range shown on the "Modify Ticket" screen. See Figure 5 below.</div>
<img class="nf-images" src='./images/new-features/v3-0/img-05.png' />
<div class="nf-figure">Figure 5</div>
<br/>

<div class="nf-description">To check ticket status, go to the “Tickets” screen from either the Mega Millions or Powerball home screen. You should see multiple drawing sections, with one for upcoming draws, and the others for future draws. See Figure 6 below.</div>
<img class="nf-images" src='./images/new-features/v3-0/img-06.png' />
<div class="nf-figure">Figure 6</div>
<br/>

<br/><br/>
</div>`;
    }

    // new features introduced in iOS version 3.8 (3.10.15)
    getVersion31015() {
        // 1. support costs and winnings
        // 2. support top prizes
        return `<div class="static-root new-features">
<div class="nf-title">New Features</div>
<div class="nf-subtitle">introduced in version 3.8</div>

<br/>
<div class="nf-description">The Powermega version 3.8 has introduced two new features: Costs and Winnings, and Top Prizes.</div>

<div class="nf-label">1. COSTS AND WINNINGS</div>
<div class="nf-description">If you select a set of numbers, the Powermega app will calculate the cost of tickets and the total prizes for a specified period. In other words, the app simulates the scenario where you purchase the same set of numbers over time, providing insights into the costs incurred and potential winnings.</div>
<br/>

<div class="nf-label">2. TOP PRIZES</div>
<div class="nf-description">The Powermega app dynamically monitors the top 10 prizes, allowing you to conveniently check the leading Mega Millions, Powerball, or combined rankings at any time.</div>
<br/>

<div class="nf-label">HOW DO THEY WORK?</div>
<div class="nf-description">You can access these features on the "More" screen by tapping the gear icon in the upper-right corner of the home screen. Please refer to Figure 1 below.</div>
<img class="nf-images" src='./images/new-features/v3-8/img-01.png' />
<div class="nf-figure">Figure 1</div>
<br/>

<div class="nf-description">When the upcoming jackpot reaches a threshold, the "Costs and Winnings" and "Top Prizes" icons will also appear on the home screen. Please refer to Figure 2 below.</div>
<img class="nf-images" src='./images/new-features/v3-8/img-02.png' />
<div class="nf-figure">Figure 2</div>
<br/>

<div class="nf-description">While you are on the "Costs and Winnings" screen, you can pick your numbers through a random quick pick, manually select them, or choose from your favorite list. You can also toggle the multiplier switches on or off and specify the period you are interested in. Please refer to Figures 3 below.</div>
<img class="nf-images" src='./images/new-features/v3-8/img-05.png' />
<div class="nf-figure">Figure 3</div>
<br/>

<div class="nf-description">"Top Prizes" showcases the largest top 10 jackpots in history. You can choose to view the top 10 for Mega Millions only, Powerball only, or both combined by tapping the checkboxes at the top. If the upcoming jackpot reaches the top 10, it will appear in the list and be highlighted in red. Please refer to Figure 4 below.</div>
<img class="nf-images" src='./images/new-features/v3-8/img-06.png' />
<div class="nf-figure">Figure 4</div>
<br/>

<br/><br/>
</div>`;
    }

    // invalid version number messages
    showErrorMsg(appVersion) {
        return `<div class="general-msg">
<div class="logos" id="applogo48x48"></div><br/><br/>
<h2>Page Not Found</h2><br/><br/>
<p>The feature information you are looking for does not exist.</p><br/>
<p>The request version "${appVersion}" is either missing or invalid.  Please check the URL and make sure the valid version number is specified.</p>
<div>`;
    }
}
