/* jshint esversion: 8 */
'use strict';

import UserAdminBase from './user-base.js';
import Utils from '../../lib/utils.js';

export default class ManageAccount extends UserAdminBase {
    constructor(global) {
        super(global);
        this.pageName = 'Manage Account';
    }

    loadContentSection() {
        const me = this;
        Utils.addElement(this.framebox, 'div', 'title').innerText = 'Manage Account';

        // Message
        this.user = this.auth.currentUser;
        const content = Utils.addElement(this.framebox, 'div', 'section');
        const confirmForm = Utils.addElement(content, 'form', 'content');
        if (!this.user) {
            Utils.addElement(confirmForm, 'div').innerHTML = 'You have logged out. Please click on the "Sign In" button in the upper-right corner to log in first before you can view your account profile.';
            return;
        }
        Utils.addElement(confirmForm, 'div').innerHTML = `You have logged in to Powermega with the following account.  ${this.getProviders(this.user.providerData)}`;
        Utils.addElement(confirmForm, 'div', 'label').innerText = 'NAME';
        this.name = Utils.addElement(confirmForm, 'input', 'inputbox');
        this.name.value = this.user.displayName;
        this.name.disabled = true;
        Utils.addElement(confirmForm, 'div', 'label').innerText = 'EMAIL';
        this.email = Utils.addElement(confirmForm, 'input', 'inputbox');
        this.email.setAttribute('type', 'email');
        this.email.setAttribute('autocomplete', 'username');
        this.email.value = this.user.email;
        this.email.disabled = true;

        // other option section
        const section = Utils.addElement(this.framebox, 'div', 'section');
        Utils.addElement(section, 'div', 'content').innerText = 'If you would like to update your user profile, reset your password, choose an alternative sign-in method, or opt-in/out of email notifications, click the corresponding button below.\n\nYou can always get back to this page by clicking on the avatar icon at the upper-right corner and selecting "Manage Account".';

        // update user profile section
        let oBtnDiv = Utils.addElement(section, 'div', 'button-frame');
        let btn = Utils.addElement(oBtnDiv, 'button', 'submit-button', 'reset-password-button');
        btn.innerText = 'Update Profile';
        btn.onclick = event => {
            event.preventDefault();
            this.global.router.dispatch('/updprofile');
        }

        // Reset Password
        oBtnDiv = Utils.addElement(section, 'div', 'button-frame');
        btn = Utils.addElement(oBtnDiv, 'button', 'submit-button', 'reset-password-button');
        btn.innerText = 'Reset Password';
        btn.onclick = event => {
            event.preventDefault();
            me.confirmPasswordReset(me.user.email);
        }

        // Use Alternative Sign In Method
        oBtnDiv = Utils.addElement(section, 'div', 'button-frame');
        btn = Utils.addElement(oBtnDiv, 'button', 'submit-button');
        btn.innerText = 'Alternative Sign In';
        btn.onclick = event => {
            event.preventDefault();
            this.global.router.dispatch('/authenticate');
        }

        // News Letter Opt In/Out Toggle
        oBtnDiv = Utils.addElement(section, 'div', 'button-frame');
        btn = Utils.addElement(oBtnDiv, 'button', 'submit-button', 'news-letter-btn');
    }

    initialize() {
        this.resetForm();
        this.layout.selectMenuItem(this.layout.menus.signin.item);
        if (this.getCookie('pmdureq') === 'yes') this.global.router.dispatch('/termaccount');
    }

    resetForm() {
        const user = this.auth.currentUser;
        if (user) {
            this.name.value = user.displayName;
            this.email.value = user.email;
            this.updateOptInOutButton();
        }
    }

    updateOptInOutButton() {
        const btn = document.getElementById('news-letter-btn');
        try {
            this.ucol.doc(this.user.uid).get().then(docSnapshot => {
                const eopt = docSnapshot.data().eopt;
                const optout = eopt && eopt.optin === false ? true : false;
                btn.style.display = '';
                btn.innerText = optout ? 'Subscribe Emails' : 'Unsubscribe Emails';
                btn.onclick = event => {
                    event.preventDefault();
                    this.global.router.dispatch('/optnews', `?ee=${this.encodeEmail(this.user.email)}&optin=${optout}`);
                }
            });
        } catch(error) {
            // there can be a case caused by timing that the user hasn't created, but the page is trying to get the user object.
            btn.style.display = 'none';
        }
    }
}
