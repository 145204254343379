/* jshint esversion: 8 */
'use strict';

import UserAdminBase from './user-base.js';
import Utils from '../../lib/utils.js';

export default class Authentication extends UserAdminBase {
    constructor(global) {
        super(global);
        this.pageName = 'Authentication';
    }

    loadContentSection() {
        const me = this;

        Utils.addElement(this.framebox, 'div', 'title').innerText = 'Sign Me In';
        const content = Utils.addElement(this.framebox, 'div', 'section');
        const loginForm = Utils.addElement(content, 'form', 'content');
        this.displayNotes = Utils.addElement(loginForm, 'div');
        this.displayNotes.style.display = 'none';
        Utils.addElement(loginForm, 'div', 'label').innerText = 'EMAIL';
        let oBtnDiv = Utils.addElement(loginForm, 'div', 'tooltip-input');
        this.email = Utils.addElement(oBtnDiv, 'input', 'inputbox', 'auth-email');
        this.email.setAttribute('type', 'email');
        this.email.setAttribute('autocomplete', 'username');
        this.emailtips = Utils.addElement(oBtnDiv, 'div', 'tooltiptext');
        this.emailtips.innerText = 'You have logged in to your Powermega account and the email address cannot be changed from here.';
        this.pwdlabel = Utils.addElement(loginForm, 'div', 'label');
        this.pwdlabel.innerText = 'PASSWORD';
        oBtnDiv = Utils.addElement(loginForm, 'div', 'tooltip-input');
        this.password = Utils.addElement(oBtnDiv, 'input', 'inputbox', 'auth-password');
        this.password.setAttribute('type', 'password');
        this.password.setAttribute('autocomplete', 'current-password');
        this.eyeicon = Utils.addElement(oBtnDiv, 'div', 'logos eye-icon slash');
        this.eyeicon.onclick = () => Utils.togglePassword(this.eyeicon, 'auth-password');
        this.pwdtips = Utils.addElement(oBtnDiv, 'div', 'tooltiptext');
        this.pwdtips.innerText = 'You have logged in to your Powermega account and the password cannot be changed from here.';
        this.resetpwd = Utils.addElement(loginForm, 'div', 'forgot-password');
        this.resetpwd.innerText = 'FORGOT PASSWORD?';
        this.resetpwd.onclick = () => { me.resetPassword(true) }
        this.errormsg = Utils.addElement(loginForm, 'div', 'login-msg error');

        // Sign In button
        oBtnDiv = Utils.addElement(loginForm, 'div', 'button-frame');
        let btn = Utils.addElement(oBtnDiv, 'button', 'submit-button', 'submit-loginform');
        btn.innerText = 'Sign In';
        btn.onclick = event => {
            event.preventDefault();
            me.errormsg.innerText = '';
            if (event.target.innerText === 'Sign In') {
                if (this.auth.currentUser) {
                    const credential = this.global.firebase.auth.EmailAuthProvider.credential(me.email.value, me.password.value);
                    this.auth.currentUser.linkWithCredential(credential).catch(error => {
                        me.errormsg.innerText = error.message;
                    });
                } else {
                    me.auth.signInWithEmailAndPassword(me.email.value, me.password.value).catch(error => {
                        me.errormsg.innerText = error.message;
                    });
                }
            } else if (event.target.innerText === 'Reset Password') {
                try {
                    me.confirmPasswordReset(me.email.value);
                    me.resetPassword(false);
                } catch(error) {
                    me.errormsg.innerText = error.message;
                };
            }
        }

        const separator = Utils.addElement(content, 'div', 'separator');
        Utils.addElement(separator, 'div', 'text').innerText = 'Or continue with social Sign-in';

        // Google login
        oBtnDiv = Utils.addElement(content, 'div', 'button-frame');
        btn = Utils.addElement(oBtnDiv, 'button', 'login-options', 'google-login');
        Utils.addElement(btn, 'div', 'logos', 'google24x24');
        Utils.addElement(btn, 'div', 'login-text').innerText = 'Sign In With Google';
        btn.onclick = () => {
            me.loginFromGoogle();
        }

        // Facebook login
        oBtnDiv = Utils.addElement(content, 'div', 'button-frame');
        btn = Utils.addElement(oBtnDiv, 'button', 'login-options', 'facebook-login');
        Utils.addElement(btn, 'div', 'logos', 'facebook24x24');
        Utils.addElement(btn, 'div', 'login-text').innerText = 'Sign In With Facebook';
        btn.onclick = () => {
            me.loginFromFacebook();
        }

        // Apple login
        oBtnDiv = Utils.addElement(content, 'div', 'button-frame');
        btn = Utils.addElement(oBtnDiv, 'button', 'login-options', 'apple-login');
        Utils.addElement(btn, 'div', 'logos', 'apple24x24');
        Utils.addElement(btn, 'div', 'login-text').innerText = 'Sign In With Apple';
        btn.onclick = () => {
            me.loginFromApple();
        }

        this.newActSep = Utils.addElement(content, 'div', 'separator');
        Utils.addElement(this.newActSep, 'div', 'text').innerText = 'New to Powermega?';

        this.newActBtn = Utils.addElement(content, 'div', 'button-frame');
        btn = Utils.addElement(this.newActBtn, 'button', 'login-options', 'powermega-register');
        Utils.addElement(btn, 'div', 'logos', 'applogo24x24');
        Utils.addElement(btn, 'div', 'login-text').innerText = 'Create an Account';
        btn.onclick = () => {
            if (me.auth.currentUser) me.auth.signOut();
            this.global.router.dispatch('/register');
        }
    }

    loginFromGoogle() {
        const provider = new this.global.firebase.auth.GoogleAuthProvider();
        this.loginUsingOauth(provider);
    }

    loginFromFacebook() {
        const provider = new this.global.firebase.auth.FacebookAuthProvider();
        provider.addScope('email');
        // provider.addScope('user_localtion');
        this.loginUsingOauth(provider);
    }

    loginFromApple() {
        const provider = new this.global.firebase.auth.OAuthProvider('apple.com');
        provider.addScope('email');
        provider.addScope('name');
        this.loginUsingOauth(provider);
    }

    loginUsingOauth(provider) {
        this.auth.useDeviceLanguage();
        this.auth.currentUser ? this.auth.currentUser.linkWithRedirect(provider) : this.auth.signInWithRedirect(provider);
    }

    resetPassword(reset) {
        const submitBtn = document.getElementById('submit-loginform');
        this.errormsg.innerText = '';
        let display = '';
        if (reset) {
            display = 'none';
            submitBtn.innerText = 'Reset Password';
        } else {
            submitBtn.innerText = 'Sign In';
        }
        this.pwdlabel.style.display = display;
        this.password.style.display = display;
        this.eyeicon.style.display = display;
        this.resetpwd.style.display = display;
    }

    initialize() {
        this.layout.selectMenuItem(this.layout.menus.signin.item);
        this.resetPassword(false);
        this.controlSignInOptions();
    }

    controlSignInOptions() {
        try {
            this.emailtips.style.display = 'none';
            this.pwdtips.style.display = 'none';
            if (this.auth.currentUser) {
                for (let pid of this.auth.currentUser.providerData) {
                    if (pid.providerId === 'password') {
                        document.getElementById('submit-loginform').disabled = true;
                        const pwdbox = document.getElementById('auth-password');
                        pwdbox.value = '************';
                        pwdbox.disabled = true;
                        this.emailtips.style.display = '';
                        this.pwdtips.style.display = '';
                        if (this.eyeicon) this.eyeicon.style.display = 'none';
                    } else if (['google.com', 'facebook.com', 'apple.com'].includes(pid.providerId)) {
                        const btn = document.getElementById(pid.providerId.replace('.com', '-login'));
                        btn.disabled = true;
                        btn.style.cursor = 'default';
                    }
                }
                const emailbox = document.getElementById('auth-email');
                emailbox.value = this.auth.currentUser.email;
                emailbox.disabled = true;
                this.displayNotes.innerHTML = `You have already logged in to Powermega with the following account.  ${this.getProviders(this.auth.currentUser.providerData)}<br/><br/>You could choose other available authentication method(s) to login into the current Powermega account.`;
                this.displayNotes.style.display = '';
                this.newActSep.style.display = 'none';
                this.newActBtn.style.display = 'none';
            } else {
                const emailbox = document.getElementById('auth-email');
                emailbox.value = '';
                emailbox.disabled = false;
                const pwdbox = document.getElementById('auth-password');
                pwdbox.value = '';
                pwdbox.disabled = false;
                if (this.eyeicon) this.eyeicon.style.display = '';
                document.getElementById('submit-loginform').disabled = false;
                document.getElementById('google-login').disabled = false;
                document.getElementById('facebook-login').disabled = false;
                document.getElementById('apple-login').disabled = false;
                this.displayNotes.style.display = 'none';
                this.newActSep.style.display = '';
                this.newActBtn.style.display = '';
            }
        } catch(error) {
            console.log('Error at Authentication.show()', error);
        }
    }
}
