/**
 * jshint esversion: 8
 *
 * Run the service in emulator:
 * firebase emulators:start --only hosting
 *
 * Deploy the website to the proper environment
 * https://firebase.googleblog.com/2018/08/one-project-multiple-sites-plus-boost.html
 *
 * There are two environments, production and staging, which is configured in app.prod.js and app-stage.js respectively.
 * The selection of the environment is controlled in webpack.config.js and package.json during build phase: "npm run xxx".
 *
 * - checking active Firebase instance currently being used:
 *   firebase projects:list
 *   firebase use
 *   firebase use super-mega-ba57d
 *   firebase use powermega-staging
 *   firebase emulators:start --only hosting (old command)
 *   firebase serve --only hosting (new command)
 *
 * - deploying to the staging site:
 *   firebase use powermega-staging
 * - specify the powermega site explicitly:
 *   firebase target:apply hosting powermega powermega-staging
 *   firebase deploy --only hosting:powermega
 * - or go with default settings:
 *   firebase deploy --only hosting
 *
 *   firebase hosting:disable
 *
 * - configuring Firebase Storage CORS
 *   https://cloud.google.com/storage/docs/gsutil/commands/cors
 *   https://cloud.google.com/storage/docs/configuring-cors
 *   https://stackoverflow.com/questions/44639212/gs-your-cloud-storage-bucket-has-no-cors-configuration
 *   gsutil cors set cors.json gs://powermega-staging.appspot.com
 *
 * - Fetch all files from Firebase Hosting
 *   https://gist.github.com/mbleigh/9c8680cf319ace2f506f57380da66e7d
 *   npx https://gist.github.com/mbleigh/9c8680cf319ace2f506f57380da66e7d <site_name>
 *   for example:
 *   npx https://gist.github.com/mbleigh/9c8680cf319ace2f506f57380da66e7d powermega-staging
 *
 * - Update build version
 *   Open webpack.config.js
 *   update plugins -> new HtmlWebpackPlugin -> meta -> version to be n.yy.m.d
 *   where: n is the major version number, yy is the year, m is the month and d is the date
 *   for example: 1.20.8.23, which means version version 1 created on August 23th, 2020
 *
 * - Command of getting project app settings
 *   firebase --project super-mega-ba57d apps:sdkconfig
 *
 * - deploying to the staging site:
 *   npm run build-stage
 *   firebase use powermega-staging
 *   firebase target:clear hosting powermega
 *   firebase target:apply hosting powermega powermega-staging
 *   firebase deploy --only hosting:powermega
 */
'use strict';

import '../../css/app.scss';
import '../../css/widgets.scss';
import '../../css/about-page.scss';
import '../../css/static-pages.scss';
import '../../css/ad-units.scss';
import Router from '../lib/router.js';
import MenuConfig from '../../config/menu-config.js';
import GeoLocation from '../../config/geo-location.js';

const settings = {
    dbname: 'powermega',
    firebaseConfig: {
        apiKey: "AIzaSyCh8lhaXmTYLsRPaPi7ofBBjDG418ZUlog",
        apiKey_TEST: "AIzaSyAmgyPmSqhSNXgoNmTo1i1nK-pcErVY_Jk",
        authDomain: "stage.powermega.net",
        storageBucket: "powermega-staging.appspot.com",
        databaseURL: "https://powermega-staging.firebaseio.com",
        projectId: "powermega-staging",
        messagingSenderId: "785201643731",
        appId: "1:785201643731:web:a43fdbec616ce20f35d426",
        measurementId: "G-8E42FDK2NS"
    },
    actionCodeSettings: {
        url: 'http://stage.powermega.net/dlink/elogin',
        handleCodeInApp: true,
        iOS: {
            bundleId: 'com.planetbinc.powermega.staging'
        },
        android: {
            packageName: 'com.planetbinc.powermega.staging',
            installApp: true,
            minimumVersion: '19'
        },
        dynamicLinkDomain: 'stage.powermega.net'
    },
    webHomeUrl: 'https://stage.powermega.net',
    iosAppLink: 'https://stage.powermega.net/dlink/iosapp',
    androidAppLink: 'https://stage.powermega.net/dlink/androidapp',
    menus: MenuConfig.menus,
    geo: GeoLocation.geo
}

// start the SPA from here
new Router(settings);
