/* jshint esversion: 8 */
'use strict';

import BasePage from '../../lib/page-base.js';

export default class HowToPlayPb extends BasePage {
    constructor(global) {
        super(global);
        this.pageName = 'Play Powerball';
    }

    initialize() {
        this.layout.main.classList.add('scheme-pb');
        this.initializeAds('iaad-articlepage-bgdark-1');
        this.initializeAds('dpad-staticpage-banner-1');
        this.initializeAds('iaad-articlepage-bgdark-2');
    }

    getContent() {
        return `<div class="static-root">
<div class="lottery-img">
    <img class="lottery-logo" src="../images/lottery-logos/pb-logo-640x120.png"></img>
</div>
<div>
    <div class="htp-label">RULES</div>
    <div>
        <li>Powerball® tickets cost $2.00 per play. Power Play® option costs additional $1.00 per play.</li>
        <li>Select five numbers from 1 to 69 for the white balls and one number from 1 to 26 for the red power ball.</li>
        <li>Choose your numbers on a play slip or let the lottery terminal randomly pick your numbers.</li>
        <li>The Powerball® jackpot grows until it is won.</li>
        <li>The Powerball drawing is held at 11:00 PM Eastern Time (8:00 PM Pacific Time) on every Monday, Wednesday and Saturday.</li>
    </div>
</div>
<br/>
<div class="dpad-staticpage-banner" id="iaad-articlepage-bgdark-1"></div>
<div>
    <div class="htp-label">DOUBLE PLAY®</div>
    <div>
        <li>Double Play® is a new add-on feature to the Powerball® game that gives players in select jurisdictions another chance to match their Powerball numbers in a separate drawing with a top cash prize of $10 million.</li>
        <li>Players can add the Double Play feature to their Powerball ticket for an additional $1 per play. The Double Play drawing is held after every Powerball drawing on Monday, Wednesday and Saturday.</li>
        <li>Players will play the same set of numbers in the both the main Powerball drawing and Double Play drawing, and Powerball tickets that include the Double Play® feature will be eligible to win prizes in both drawings.</li>
        <li>Players can include both the Power Play® and Double Play® features on their Powerball ticket, however, the Power Play multiplier does not apply to prizes won in the Double Play® drawing.</li>
        <li>13 lotteries offer the Double Play® add-on feature: Colorado, Florida, Indiana, Maryland, Michigan, Missouri, New Jersey, Pennsylvania, Puerto Rico, South Carolina, South Dakota, Tennessee, and Washington.  Additional lotteries may choose to offer Double Play® at a later date.</li>
    </div>
</div>
<br/>
<div class="dpad-staticpage-banner" id="dpad-staticpage-banner-1"></div>
<div>
    <div class="htp-label">PRIZE WITH POWER PLAY</div>
    <div class="hscrollable">
        <table class="tabular tblpb">
            <tr class="thpb">
                <th>MATCH</th>
                <th>2X</th>
                <th>3X</th>
                <th>4X</th>
                <th>5X</th>
                <th>10X</th>
            </tr>
            <tr>
                <td>5 + 0</td>
                <td>$2M</td>
                <td>$2M</td>
                <td>$2M</td>
                <td>$2M</td>
                <td>$2M</td>
            </tr>
            <tr>
                <td>4 + 1</td>
                <td>$100K</td>
                <td>$150K</td>
                <td>$200K</td>
                <td>$250K</td>
                <td>$500K</td>
            </tr>
            <tr>
                <td>4 + 0</td>
                <td>$200</td>
                <td>$300</td>
                <td>$400</td>
                <td>$500</td>
                <td>$1,000</td>
            </tr>
            <tr>
                <td>3 + 1</td>
                <td>$200</td>
                <td>$300</td>
                <td>$400</td>
                <td>$500</td>
                <td>$1,000</td>
            </tr>
            <tr>
                <td>3 + 0</td>
                <td>$14</td>
                <td>$21</td>
                <td>$28</td>
                <td>$35</td>
                <td>$70</td>
            </tr>
            <tr>
                <td>2 + 1</td>
                <td>$14</td>
                <td>$21</td>
                <td>$28</td>
                <td>$35</td>
                <td>$70</td>
            </tr>
            <tr>
                <td>1 + 1</td>
                <td>$8</td>
                <td>$12</td>
                <td>$16</td>
                <td>$20</td>
                <td>$40</td>
            </tr>
            <tr>
                <td>0 + 1</td>
                <td>$8</td>
                <td>$12</td>
                <td>$16</td>
                <td>$20</td>
                <td>$40</td>
            </tr>
        </table>
    </div>
</div>
<br/>
<div class="dpad-staticpage-banner" id="iaad-articlepage-bgdark-2"></div>
<div>
    <div class="htp-notes">The overall odds of winning a prize are 1 in 24.87. The odds presented here are based on a $2 play (rounded to two decimal places).</div>
    <div class="htp-label">POWERBALL® PRIZE AND ODDS</div>
    <table class="tabular tblpb">
        <tr class="thpb">
            <th>MATCH</th>
            <th>PRIZE</th>
            <th>ODDS</th>
        </tr>
        <tr>
            <td>5 + 1</td>
            <td>Jackpot</td>
            <td>1 in 292,201,338.00</td>
        </tr>
        <tr>
            <td>5 + 0</td>
            <td>$1 Million</td>
            <td>1 in 11,688,053.52</td>
        </tr>
        <tr>
            <td>4 + 1</td>
            <td>$500,000</td>
            <td>1 in 913,129.18</td>
        </tr>
        <tr>
            <td>4 + 0</td>
            <td>$100</td>
            <td>1 in 36,525.17</td>
        </tr>
        <tr>
            <td>3 + 1</td>
            <td>$100</td>
            <td>1 in 14,494.11</td>
        </tr>
        <tr>
            <td>3 + 0</td>
            <td>$7</td>
            <td>1 in 579.76</td>
        </tr>
        <tr>
            <td>2 + 1</td>
            <td>$7</td>
            <td>1 in 701.33</td>
        </tr>
        <tr>
            <td>1 + 1</td>
            <td>$4</td>
            <td>1 in 91.98</td>
        </tr>
        <tr>
            <td>0 + 1</td>
            <td>$4</td>
            <td>1 in 38.32</td>
        </tr>
    </table>
</div>
<br/>
<div>
    <div class="column">
        <div class="htp-label">ODDS WITH 10X AVAILABLE</div>
        <table class="tabular tblpb">
            <tr class="upperth thpb">
                <th>POWER</th>
                <th>ODDS</th>
            </tr>
            <tr class="lowerth">
                <th>PLAY</th>
                <th></th>
            </tr>
            <tr>
                <td>10X</td>
                <td>1 in 43</td>
            </tr>
            <tr>
                <td>5X</td>
                <td>1 in 21.5</td>
            </tr>
            <tr>
                <td>4X</td>
                <td>1 in 14.33</td>
            </tr>
            <tr>
                <td>3X</td>
                <td>1 in 3.31</td>
            </tr>
            <tr>
                <td>2X</td>
                <td>1 in 1.79</td>
            </tr>
        </table>
    </div>
    <div class="column align-right">
        <div class="htp-label">ODDS WITH 10X UNAVAILABLE</div>
        <table class="tabular tblpb">
            <tr class="upperth thpb">
                <th>POWER</th>
                <th>ODDS</th>
            </tr>
            <tr class="lowerth">
                <th>PLAY</th>
                <th></th>
            </tr>
            <tr>
                <td>5X</td>
                <td>1 in 21</td>
            </tr>
            <tr>
                <td>4X</td>
                <td>1 in 14</td>
            </tr>
            <tr>
                <td>3X</td>
                <td>1 in 3.23</td>
            </tr>
            <tr>
                <td>2X</td>
                <td>1 in 1.75</td>
            </tr>
        </table>
    </div>
</div>
<br/>
<div>
    <div class="htp-label">DOUBLE PLAY® PRIZE AND ODDS</div>
    <table class="tabular tblpb">
        <tr class="thpb">
            <th>MATCH</th>
            <th>PRIZE</th>
            <th>ODDS</th>
        </tr>
        <tr>
            <td>5 + 1</td>
            <td>$10 Million</td>
            <td>1 in 292,201,338.00</td>
        </tr>
        <tr>
            <td>5 + 0</td>
            <td>$500,000</td>
            <td>1 in 11,688,053.52</td>
        </tr>
        <tr>
            <td>4 + 1</td>
            <td>$50,000</td>
            <td>1 in 913,129.18</td>
        </tr>
        <tr>
            <td>4 + 0</td>
            <td>$500</td>
            <td>1 in 36,525.17</td>
        </tr>
        <tr>
            <td>3 + 1</td>
            <td>$500</td>
            <td>1 in 14,494.11</td>
        </tr>
        <tr>
            <td>3 + 0</td>
            <td>$20</td>
            <td>1 in 579.76</td>
        </tr>
        <tr>
            <td>2 + 1</td>
            <td>$20</td>
            <td>1 in 701.33</td>
        </tr>
        <tr>
            <td>1 + 1</td>
            <td>$10</td>
            <td>1 in 91.98</td>
        </tr>
        <tr>
            <td>0 + 1</td>
            <td>$7</td>
            <td>1 in 38.32</td>
        </tr>
    </table>
</div>
<br/>
<div>
    <div class="htp-label">DISCLAIMER AND NOTES</div>
    <div>
        <li>Information contained herein is provided for informational purpose only.  This app makes no warranty or representation as to the accuracy or completeness of the Information.</li>
        <li>In California, all prizes are pari-mutuel, meaning payouts are based on sales and the number of winners. All other Powerball states set the 2nd through 9th prizes at pre-determined amounts.</li>
        <li>Trademarks and logos are the properties of their respective owners.</li>
    </div>
</div>
<br/><br/><br/><br/>
</div>`;
    }
}