/* jshint esversion: 8 */
'use strict';

import UserAdminBase from './user-base.js';
import ProfileUpdate from './update-profile.js';
import Utils from '../../lib/utils.js';

export default class EmailLogin extends UserAdminBase {
    constructor(global) {
        super(global);
        this.pageName = 'Email Login';
    }

    loadContentSection() {
        const me = this;
        // first half - login part
        Utils.addElement(this.framebox, 'div', 'title').innerText = 'Sign Me In';
        const content = Utils.addElement(this.framebox, 'div', 'section');

        this.instruction = Utils.addElement(content, 'div', 'content');
        const loginForm = Utils.addElement(content, 'form', 'content');
        this.email = Utils.addElement(loginForm, 'input', 'inputbox', 'elogin-email');
        this.email.setAttribute('type', 'email');
        this.email.setAttribute('autocomplete', 'username');
        this.errormsg = Utils.addElement(loginForm, 'div', 'login-msg error height60');
        let oBtnDiv = Utils.addElement(loginForm, 'div', 'button-frame');
        let btn = Utils.addElement(oBtnDiv, 'button', 'submit-button', 'elogin-button');
        btn.innerText = 'Sign In';
        btn.onclick = event => {
            event.preventDefault();
            me.errormsg.innerText = '';
            const email = me.email.value;
            if (me.auth.currentUser && me.auth.currentUser.email === me.email.value) {
                me.global.router.dispatch('/manageact');
            } else if (me.validateEmail(email)) {
                if (me.auth.currentUser) {
                    me.global.processing = true;
                    me.auth.signOut().then(() => {
                        me.getPresetEmail();
                        me.signInUsingEmail(email);
                    }).catch(error => {
                        me.errormsg.innerHTML = `Sign out from your previous account: ${me.auth.currentUser.email} failed.`;
                    })
                } else {
                    me.signInUsingEmail(email);
                }
            } else {
                me.errormsg.innerText = 'Invalid email address!  Please check and enter your correct email address.'
            }
        }
        this.emailme = Utils.addElement(loginForm, 'div', 'button-frame');
        this.emailme.style.display = 'none';
        btn = Utils.addElement(this.emailme, 'button', 'submit-button');
        btn.innerText = 'Email Me';
        btn.onclick = event => {
            event.preventDefault();
            me.auth.sendSignInLinkToEmail(me.email.value, me.global.action).then(() => {
                window.localStorage.setItem('emailForSignIn', me.email.value);
                me.errormsg.innerText = 'Email has been successfully sent.  Please check your mailbox and follow the instruction to sign in.';
                me.emailme.style.display = 'none';
            }).catch(error => {
                me.errormsg.innerText = error.message;
            });
        }

        const loginOption = Utils.addElement(this.framebox, 'div', 'section');
        Utils.addElement(loginOption, 'div', 'content').innerText = 'If you already have a Powermega account and know the password, or you\'d like to sign in using your Google, Facebook, or Apple account, please click the "Sign In" button at the upper-right corner.';
    }

    initialize() {
        this.emailme.style.display = 'none';
        const presetEmail = this.getPresetEmail();
        if (presetEmail) this.email.value = presetEmail;
    }

    getPresetEmail() {
        if (this.auth.currentUser) {
            this.instruction.innerHTML = `You've successfully logged in using the email address "${this.auth.currentUser.email}". If this address differs from the one where you clicked the Powermega Sign-In link in your mailbox, please enter that email address into the input box below and click the "Sign In" button to log in again.`;
            return this.auth.currentUser.email;
        } else {
            this.instruction.innerHTML = 'Please enter the email address associated with the URL link you received in your mailbox with the subject "Sign in to Powermega".';
            return window.localStorage.getItem('emailForSignIn');
        }
    }

    signInUsingEmail(email) {
        this.auth.signInWithEmailLink(email, window.location.href).then(() => {
            this.global.processing = true;
            window.localStorage.removeItem('emailForSignIn');
            this.global.router.dispatch('/manageact');
            ProfileUpdate.getInstance(this.global).askForPassword();
        }).catch(() => {
            this.errormsg.innerHTML = `Sign-in using email "${email}" failed!<br/><br/>You may have entered an incorrect email address, or the URL may be missing proper parameters. If this is the case, please correct your email and/or check the URL address, then click the "Sign In" button below to try again.<br/><br/>Alternatively, the link you clicked in your email may have expired. In that case, you can request another email link by entering the correct email above and clicking the "Email Me" button below.`;
            this.emailme.style.display = '';
        });
    }
}
