/**
 * jshint esversion: 8
 *
 * https://www.thatsoftwaredude.com/content/6396/coding-a-calendar-in-javascript
 */
'use strict';
import Utils from './utils.js';
import '../../css/calendar.scss';

const gMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

/**
 * The alignment options defines the relative position between the input box and calendar popup.
 * For alignment.x, the possible values are:
 *   - left: the calendar popup and the input box align on the left.
 *   - center: the calendar popup is in the center of its parent UI space.
 *   - right: the calendar popup and the input box align on the right.
 * For alignment.y, the possible values are:
 *   - top: the calendar popup is on top of the input box.
 *   - center: the calendar popup is in the middle of its parent UI space.
 *   - bottom: the calendar popup is right below the input box.
 */
export default class Calendar {
    constructor(parent, startYear = 2020, endYear) {
        this.parent = parent;
        const date = new Date();
        this.currentMonth = date.getMonth();
        this.currentYear = date.getFullYear();
        this.startYear = startYear;
        this.endYear = endYear || this.currentYear;
    }

    static createCalendarPopup(parent, startYear, endYear, callback) {
        const calendar = new Calendar(parent, startYear, endYear);
        calendar.callback = callback;
        calendar.renderCalendar();
        return calendar;
    }

    static createInputAndCalendar(parent, startYear, endYear, defaultUtcDate, callback, alignment) {
        const calendar = new Calendar(parent, startYear, endYear);
        calendar.callback = callback || calendar.callbackFromCalendar;
        calendar.alignment = alignment || {x: 'center', y: 'bottom'};
        calendar.createInputBox(defaultUtcDate);
        return calendar;
    }

    renderCalendar() {
        this.container = Utils.addElement(this.parent, 'div', 'calendar-container', 'calendar-container');
        this.container.onmousedown = event => event.preventDefault();
        const cheader = Utils.addElement(this.container, 'div', 'cheader');
        this.backward = Utils.addElement(cheader, 'button', 'arrow left');
        this.backward.onmousedown = () => this.gotoNextMonth(false);
        this.yearMonth = Utils.addElement(cheader, 'div', 'ymbtn float-left');
        this.yearMonth.onmousedown = () => {
            if (this.dates) {
                const year = this.yearMonth.year;
                this.renderYearMonths(year, this.yearMonth.month);
                document.getElementById(`year-${year}`).scrollIntoView();
            } else {
                this.renderMonthDays();
            }
        }
        this.forward = Utils.addElement(cheader, 'button', 'arrow right');
        this.forward.onmousedown = () => this.gotoNextMonth();;
        Utils.addElement(cheader, 'div', 'empty-div');
        this.today = Utils.addElement(cheader, 'div', 'ymbtn today');
        this.today.innerText = 'Today';
        this.today.onmousedown = () => {
            const date = new Date();
            this.callbackFromCalendar({year: date.getFullYear(), month: date.getMonth(), date: date.getDate()});
            this.renderMonthDays(this.currentYear, this.currentMonth);
        }
        Utils.addElement(this.container, 'div', 'clear');
        this.renderMonthDays(this.currentYear, this.currentMonth);
    }

    renderMonthDays(year, month) {
        if (this.months) {
            Utils.clearNode(this.months);
            this.months = null;
        }
        if (this.dates) Utils.clearNode(this.dates);
        this.dates = Utils.addElement(this.container, 'div', 'dates');
        const dheader = Utils.addElement(this.dates, 'div', 'days dheader');
        Utils.addElement(dheader, 'div').innerText = 'Su';
        Utils.addElement(dheader, 'div').innerText = 'Mo';
        Utils.addElement(dheader, 'div').innerText = 'Tu';
        Utils.addElement(dheader, 'div').innerText = 'We';
        Utils.addElement(dheader, 'div').innerText = 'Th';
        Utils.addElement(dheader, 'div').innerText = 'Fr';
        Utils.addElement(dheader, 'div').innerText = 'Sa';
        this.monthFrame = Utils.addElement(this.dates, 'div', 'days');
        this.renderOneMonth(this.monthFrame, year, month);
    }

    renderOneMonth(monthFrame, year, month) {
        if (monthFrame.hasChildNodes()) Utils.clearNode(monthFrame, false);
        const getDaysInMonth = (year, month) => new Date(year, month, 0);
        const prevMonth = getDaysInMonth(year, month);
        const prevRemainDays = (prevMonth.getDay() + 1) % 7;
        if (prevRemainDays) {
            const lastDay = prevMonth.getDate();
            for (let i = lastDay - prevRemainDays + 1; i <= lastDay; i++) Utils.addElement(monthFrame, 'div', 'day-gray').innerText = i;
        }
        const now = new Date();
        const nowYear = now.getFullYear(), nowMonth = now.getMonth(), nowDate = now.getDate();
        const numOfDays = getDaysInMonth(year, month + 1).getDate();
        const chosenDate = this.inputbox ? this.inputbox.value.split('-').map(item => parseInt(item, 10)) : [0, 0, 0];

        for (let i = 1; i <= numOfDays; i++) {
            const date = Utils.addElement(monthFrame, 'div', 'round');
            date.innerText = i;
            date.year = year;
            date.month = month;
            date.date = i;
            if (year === nowYear && month === nowMonth && nowDate === i) date.classList.add('current-day');
            if (year === chosenDate[0] && month + 1 === chosenDate[1] && chosenDate[2] === i) date.classList.add('chosen-date');
            date.onmousedown = event => {
                if (this.callback) this.callback(event.target);
                date.onmouseup = () => Utils.clearNodeById('calendar-container');
            }
        }
        const remainder = (prevRemainDays + numOfDays) % 7;
        if (remainder) for (let i = 1; i <= 7 - remainder; i++) Utils.addElement(monthFrame, 'div', 'day-gray').innerText = i;

        // Show updated Year and Month, disable or enable the Backward or Forward button if it reaches the boundary
        this.yearMonth.year = year;
        this.yearMonth.month = month;
        this.yearMonth.innerText = `${gMonths[month]} ${year}`;
        this.forward.disabled = year === this.endYear && month === 11;
        this.backward.disabled = year === this.startYear && month === 0;
    }

    renderYearMonths(selectedYear, selectedMonth) {
        const highlightSelectedMonth = month => {
            if (this.selectedYearMonth) this.selectedYearMonth.classList.remove('selected');
            month.classList.add('selected');
            this.selectedYearMonth = month;
        }

        if (this.dates) {
            Utils.clearNode(this.dates);
            this.dates = null;
        }

        this.months = Utils.addElement(this.container, 'div', 'months');
        for (let i = this.startYear; i <= this.endYear; i++) {
            Utils.addElement(this.months, 'div', 'm-year', `year-${i}`).innerText = i;
            const months = Utils.addElement(this.months, 'div', 'm-months');
            for (let j = 0; j < gMonths.length; j++) {
                const month = Utils.addElement(months, 'div');
                month.innerText = gMonths[j];
                month.year = i;
                month.month = j;
                if (i === selectedYear && j === selectedMonth) highlightSelectedMonth(month);
                month.onmousedown = () => {
                    highlightSelectedMonth(month);
                    month.onmouseup = () => this.renderMonthDays(i, j);
                }
            }
        }
    }

    gotoNextMonth(goNext = true) {
        let year = this.yearMonth.year, month = this.yearMonth.month;
        if (goNext && ++month > 11) {
            month = 0;
            year++;
        } else if (!goNext && --month < 0) {
            month = 11;
            year--;
        }
        this.renderOneMonth(this.monthFrame, year, month);
    }

    createInputBox(defaultUtcDate) {
        this.inputbox = Utils.addElement(this.parent, 'input', 'calendar-inputbox');
        if (defaultUtcDate) {
            const date = new Date(defaultUtcDate);
            this.inputbox.value = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
        }
        this.inputbox.onfocus = () => {
            Utils.clearNodeById('calendar-container');
            this.renderCalendar();
            let ymd = this.inputbox.value.trim();
            if (ymd) {
                ymd = ymd.split('-');
                this.renderOneMonth(this.monthFrame, parseInt(ymd[0]), parseInt(ymd[1]) - 1);
            }
            this.repositionCalendarPopup();
        }
        this.inputbox.onblur = () => {
            Utils.clearNodeById('calendar-container');
        }
        this.inputbox.onkeypress = event => {
            if (event.keyCode === 13) {
                event.preventDefault()
                event.target.blur();
            }
        }
    }

    callbackFromCalendar(date) {
        this.inputbox.value = `${date.year}-${('0' + (date.month + 1)).slice(-2)}-${('0' + date.date).slice(-2)}`;
        this.inputbox.blur();
        if (this.inputbox.onchange) this.inputbox.onchange();
    }

    repositionCalendarPopup() {
        const toPx = number => number.toString() + 'px';
        const calbox = this.container;
        const iptbox = this.inputbox;

        switch(this.alignment.x) {
            case 'left':
                calbox.style.left = toPx(iptbox.offsetLeft);
                break;
            case 'right':
                calbox.style.left = toPx(iptbox.offsetLeft + iptbox.offsetWidth - calbox.offsetWidth);
                break;
            default: // center
                const deltax = (calbox.offsetWidth - iptbox.offsetWidth) / 2;
                calbox.style.left = toPx(iptbox.offsetLeft - deltax);
        }

        switch(this.alignment.y) {
            case 'top':
                calbox.style.top = toPx(iptbox.offsetTop - calbox.offsetHeight);
                break;
            case 'bottom':
                calbox.style.top = toPx(iptbox.offsetTop + iptbox.offsetHeight);
                break;
            default: // center
                const deltay = (calbox.offsetHeight - iptbox.offsetHeight) / 2;
                calbox.style.top = toPx(iptbox.offsetTop - deltay);
        }
    }
}