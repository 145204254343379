/* jshint esversion: 8 */
'use strict';

import UserAdminBase from './user-base.js';
import Utils from '../../lib/utils.js';

export default class UserStatusCheck extends UserAdminBase {
    constructor(global) {
        super(global);
        this.pageName = 'Check Status';
    }

    loadContentSection() {
        const code = Utils.getQueryParam(this.global.qstring, 'code');
        Utils.addElement(this.framebox, 'div', 'title').innerText = 'Check User Status';
        const content = Utils.addElement(this.framebox, 'div', 'section');
        const innerForm = Utils.addElement(content, 'form', 'content');
        this.topMsg = Utils.addElement(innerForm, 'div', 'form-msg');
        this.initText = 'You should have got a confirmation code when you requested to terminate your Powermega account.  Please find the code and enter it into the input box below.';

        if (code) {
            this.checkUserStatus(code, innerForm);
        } else {
            this.topMsg.innerHTML = this.initText;
            this.getConfirmationCode(innerForm);
        }
    }

    checkUserStatus(code, innerForm) {
        this.searchForRecord(code).then(doc => {
            this.addNameValuePair(innerForm, 'CONFIRMATION CODE', code);
            this.addNameValuePair(innerForm, 'STATUS', doc.status);
            this.addNameValuePair(innerForm, 'DELETION REQUEST RECEIVED AT', doc.received.toDate().toLocaleString());
            if (doc.finished) this.addNameValuePair(innerForm, 'DELETION FINISHED AT', doc.finished.toDate().toLocaleString());
            return doc.status;
        }).then(status => {
            if (status === 'finished') {
                this.topMsg.innerHTML = `The account associated to the confirmation code <b>${code}</b> has been successfully deleted.`;
            } else if (status === 'processing') {
                this.topMsg.innerHTML = `The account associated with the confirmation code <b>${code}</b> is in the process of deleting.  If it stays with this status for over 2 minutes, please email Powermega support at service.powermega@gmail.com.`;
            } else {
                this.topMsg.innerHTML = 'The status is unknown, please email Powermega support at service@powermega.net.';
                this.getConfirmationCode(innerForm);
            }
        }).catch(error => {
            this.topMsg.innerHTML = error.toString();
            this.getConfirmationCode(innerForm).value = code;
        });
    }

    searchForRecord(code) {
        const ccol = this.global.firebase.firestore().collection(this.global.dbname).doc('members').collection('cleanup');
        return ccol.doc(code.toLowerCase()).get().then(snapshot => {
            if (snapshot.exists) {
                return snapshot.data();
            } else {
                return Promise.reject(`The account deletion confirmation code "<b>${code}</b>" is not found.  If you can find the correct code, please enter it into the input box below and then click the "Check" button.`);
            }
        }).catch(error => {
            return Promise.reject(error.toString());
        })
    }

    addNameValuePair(parent, name, value) {
        Utils.addElement(parent, 'div', 'label').innerText = name;
        const iptbox = Utils.addElement(parent, 'input', 'inputbox');
        iptbox.value = value;
        iptbox.disabled = true;
    }

    addButton(parent, btnText, navPath, queryString) {
        const oBtnDiv = Utils.addElement(parent, 'div', 'button-frame');
        const btn = Utils.addElement(oBtnDiv, 'button', 'submit-button');
        btn.innerText = btnText;
        if (navPath) {
            btn.onclick = event => {
                event.preventDefault();
                this.global.router.dispatch(navPath, queryString);
            }
        }
        return btn;
    }

    getConfirmationCode(parent, navPath = '/checkstatus') {
        const me = this;
        const inputbox = Utils.addElement(parent, 'input', 'inputbox');
        const errormsg = Utils.addElement(parent, 'div', 'login-msg error height60');
        const btn = this.addButton(parent, 'Check');
        btn.onclick = event => {
            event.preventDefault();
            const code = inputbox.value;
            if (code)
                me.global.router.dispatch(navPath, '?code=' + code);
            else {
                this.topMsg.innerHTML = this.initText;
                errormsg.innerText = 'Please enter a valid confirmation code before clicking on the "Check" button below.';
            }
        }
        return inputbox;
    }
}