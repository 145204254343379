/* jshint esversion: 8 */
'use strict';

import LotteryBase from './lottery-base.js';
import Utils from '../../lib/utils.js';
import '../../../css/stats-page.scss';


export default class TopPrizes extends LotteryBase {
    constructor(global) {
        super(global);
        this.pageName = 'Top Prizes';
        this.tpFeedRef = this.sref.child('powermega/feeds/top-prizes.pbi');
    }

    initialize() {
        this.layout.selectMenuItem(this.layout.menus.numbers.menus.tprizes.item);
    }

    async getContent() {
        this.root = Utils.addElement(null, 'div', 'stats-root');

        Utils.addElement(this.root, 'div', 'top10-title').innerText = '10 Biggest Prizes in History';
        const typesBar = Utils.addElement(this.root, 'div', 'ltype-tab');
        const typeSelectors = Utils.addElement(typesBar, 'div', 'selections');
        this.selectMm = Utils.addCheckbox(typeSelectors);
        this.selectMm.checked = true;
        Utils.addElement(typeSelectors, 'span', 'rpad-28px').innerText = 'Mega Millions';
        this.selectPb = Utils.addCheckbox(typeSelectors);
        this.selectPb.checked = true;
        Utils.addElement(typeSelectors, 'span').innerText = 'Powerball';

        const sbox = Utils.addElement(this.root, 'div', 'stats-box center');

        return Utils.downloadStorageJson(this.tpFeedRef, true).then(tpJson => {
            const addType = (type, array) => array.forEach(item => item.type = type);

            tpJson.mm.current.type = 'Mega Millions';
            tpJson.mm.current.upcoming = true;
            tpJson.mm.current.winner = 'Pending upcoming draw result';
            addType('Mega Millions', tpJson.mm.history);
            tpJson.pb.current.type = 'Powerball';
            tpJson.pb.current.upcoming = true;
            tpJson.pb.current.winner = 'Pending upcoming draw result';
            addType('Powerball', tpJson.pb.history);

            const allRecords = [...tpJson.mm.history, ...tpJson.pb.history];
            allRecords.push(tpJson.mm.current);
            allRecords.push(tpJson.pb.current);
            allRecords.sort((a, b) => b.jpot - a.jpot);

            const columns = window.innerWidth > 600 ? ['#', 'Jackpot', 'Type', 'Date', 'Winner'] : ['#', 'Jackpot', 'Type', 'Date'];
            const table = this.renderTable(sbox, columns);
            this.refreshTable(table, allRecords);
            this.selectMm.onclick = event => this.validateSelections(table, allRecords, event);
            this.selectPb.onclick = event => this.validateSelections(table, allRecords, event);

            Utils.addElement(this.root, 'div', 'empty-space');
            Utils.addElement(this.root, 'div', null, 'dpad-home-leaderboard').innerHTML = this.insertAd('dpad-home-leaderboard');
            Utils.addElement(this.root, 'div', 'empty-space');

            return this.root;
        });
    }

    renderTable(parent, columns) {
        const frame = Utils.addElement(parent, 'div', 'tbl-frame');
        const table = Utils.addElement(frame, 'table', 'sortable');

        const utcToLocalDate = utcDate => {
            const options = { year: 'numeric', month: 'short', day: 'numeric' };
            return utcDate.toLocaleDateString(undefined, options);
        }

        const tr = Utils.addElement(table, 'tr', '.dcursor');
        columns.forEach(label => Utils.addElement(tr, 'th', 'sort dcursor').innerText = label);
        Utils.addElement(tr, 'th', 'sort dcursor').innerText = '';

        table.addOneRow = (index, record) => {
            const tr = Utils.addElement(table, 'tr');
            const clsName = record.upcoming ? 'tprizes highlight' : 'tprizes';
            let td = Utils.addElement(tr, 'td', 'rank');
            td.innerText = index ? index.toString() : '';
            Utils.addElement(tr, 'td', clsName).innerText = Utils.formatAmount(record.jpot, true);
            Utils.addElement(tr, 'td', clsName).innerText = record.type;
            Utils.addElement(tr, 'td', clsName).innerText = utcToLocalDate(new Date(record.ddate));
            if (columns.length > 4) Utils.addElement(tr, 'td', clsName).innerText = record.winner ? record.winner.replace(/\([^)]+\)/, '').trim() : '';
            const arrow = record.upcoming ? Utils.addElement(tr, 'td', 'highlight') : Utils.addElement(tr, 'td');
            Utils.addElement(arrow, 'div', 'logos right-arrow');

            tr.me = this;
            tr.ltype = record.type === 'Powerball' ? 'powerball' : 'megamillions';
            tr.onclick = event => {
                event.stopPropagation();
                record.upcoming ? this.renderUpcomingDrawPopup(record) : this.renderPayoutsPopup(tr, record, 'dpad-payoutmodal-banner');
            };
        }
        return table;
    }

    refreshTable(table, records) {
        const rows = table.rows;
        for (let i = rows.length - 1; i > 0; i--) Utils.clearNode(rows[i], true);
        let index = 0;
        for (let i = 0; i < records.length; i++) {
            const item = records[i];
            if (this.selectMm.checked && item.type === 'Mega Millions' || this.selectPb.checked && item.type === 'Powerball') {
                table.addOneRow(item.upcoming ? null : ++index, item);
            }
            if (index >= 10) break;
        }
    }

    validateSelections(table, records, event) {
        if (!this.selectMm.checked && !this.selectPb.checked) {
            this.showAlertMessage('At least one of the checkboxes need to be checked', this.root);
            event.target.checked = true;
        } else {
            this.refreshTable(table, records);
        }
    }

    renderUpcomingDrawPopup(record, adUnitName = 'dpad-payoutmodal-banner') {
        const popup = this.layout.createModalPopup();
        popup.title.innerText = 'Upcoming Draw';

        // Load upcoming drawing information
        const sect = Utils.addElement(popup.content, 'div', 'draw-info');
        Utils.addElement(sect, 'div', 'date fw-bold').innerText = Utils.formatDate(record.ddate, true, false);

        // Load countdown
        const block = Utils.addElement(sect, 'div');
        const cdLabel = Utils.addElement(block, 'div', 'label left fs-pt75');
        Utils.addElement(cdLabel, 'div', 'cd-element label').innerText = 'DAYS';
        Utils.addElement(cdLabel, 'div', 'cd-element label').innerText = 'HRS';
        Utils.addElement(cdLabel, 'div', 'cd-element label').innerText = 'MINS';
        Utils.addElement(cdLabel, 'div', 'cd-element label').innerText = 'SECS';
        const cdValue = Utils.addElement(block, 'div', 'value left fs-pt14');
        this.nextDays = Utils.addElement(cdValue, 'div', 'cd-element ');
        this.nextHrs = Utils.addElement(cdValue, 'div', 'cd-element');
        this.nextMins = Utils.addElement(cdValue, 'div', 'cd-element');
        this.nextSecs = Utils.addElement(cdValue, 'div', 'cd-element');
        this.pending = Utils.addElement(sect, 'div', 'date hide');

        this.countdown(record.ddate, this, popup.title);

        // Show Jackpot Annuity and Cash ammounts
        this.addBlockText(sect, 'right', 'ANNUITY', Utils.formatAmount(record.jpot), 'fs-pt14 fw-bold');
        this.addBlockText(sect, 'left', 'CASH VALUE', Utils.formatAmount(record.cash), 'fs-pt14');

        // Show the ad
        Utils.addElement(popup.content, 'div', adUnitName).innerHTML = this.insertAd(adUnitName);
        this.loadAds(adUnitName);
    }

    countdown(ddate, me, title) {
        const time = Utils.countdown(ddate);
        if (time) {
            me.nextDays.innerText = time.days.toString().padStart(2, '0');
            me.nextHrs.innerText = time.hours.toString().padStart(2, '0');
            me.nextMins.innerText = time.minutes.toString().padStart(2, '0');
            me.nextSecs.innerText = time.seconds.toString().padStart(2, '0');
            me.cdTimerId = setTimeout(me.countdown, 1000, ddate, me);
        } else {
            title.innerText = 'The Latest Draw';
            me.pending.innerText = 'Pending';
            me.pending.classList.remove('hide');
        }
    }
}
