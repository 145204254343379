/* jshint esversion: 8 */
'use strict';

import UserAdminBase from './user-base.js';
import MessageBox from '../../lib/msgbox.js';
import Utils from '../../lib/utils.js';

export default class AccountTermination extends UserAdminBase {
    constructor(global) {
        super(global);
        this.pageName = 'Account Termination';
    }

    loadContentSection() {
        this.auth.currentUser ? this.handleAuthenticatedUser() : this.handleAnnonymousUser();
    }

    initialize() {
        if (this.getCookie('pmdureq') === 'yes') this.setCookie('pmdureq', '', 0);
    }

    handleAnnonymousUser() {
        const me = this;
        // first half - check status
        Utils.addElement(this.framebox, 'div', 'title').innerText = 'Terminate Powermega Account';
        const topHalf = Utils.addElement(this.framebox, 'div', 'section');
        Utils.addElement(topHalf, 'div', 'content').innerText = 'If you\'ve previously requested to terminate your account and would like to check its status, please enter the confirmation code you received during the termination request into the input box below. Then, click the "Check" button.';
        const checkForm = Utils.addElement(topHalf, 'form', 'content');
        const code = Utils.addElement(checkForm, 'input', 'inputbox');
        let oBtnDiv = Utils.addElement(checkForm, 'div', 'button-frame');
        let btn = Utils.addElement(oBtnDiv, 'button', 'submit-button', 'check-button');
        btn.innerText = 'Check Status';
        btn.onclick = event => {
            event.preventDefault();
            me.global.router.dispatch('/checkstatus', `?code=${code.value}`);
        }

        const bottomHalf = Utils.addElement(this.framebox, 'div', 'section');
        Utils.addElement(bottomHalf, 'div', 'content').innerText = 'You are currently logged out. If you would like to delete your Powermega account and its associated personal information, please click the "Sign In" button at the upper-right corner to log in first.';
    }

    handleAuthenticatedUser() {
        const me = this;
        Utils.addElement(this.framebox, 'div', 'title').innerText = 'Terminate Powermega Account';

        const innerFrame = Utils.addElement(this.framebox, 'div', 'section');
        Utils.addElement(innerFrame, 'div', 'content').innerHTML = `You have logged in with: <b>${this.auth.currentUser.email}</b>.`;
        Utils.addElement(innerFrame, 'div', 'content').innerHTML = 'Please note that deleting your account will permanently remove all your personal information and Powermega data. <b class="red-text">The deletion process is irreversible!</b>';
        Utils.addElement(innerFrame, 'div', 'content').innerHTML = 'If you\'ve decided to remove your Powermega account, please click the "Delete Now" button. Otherwise, click the "Cancel" button below to exit the page.<br/><br/>';

        let oBtnDiv = Utils.addElement(innerFrame, 'div', 'button-frame');
        let btn = Utils.addElement(oBtnDiv, 'button', 'delete-button');
        btn.innerText = 'Delete Now';
        btn.onclick = event => {
            event.preventDefault();
            me.confirmDeletionRequest();
        }

        oBtnDiv = Utils.addElement(innerFrame, 'div', 'button-frame');
        btn = Utils.addElement(oBtnDiv, 'button', 'submit-button');
        btn.innerText = 'Cancel';
        btn.onclick = event => {
            event.preventDefault();
            me.global.router.dispatch('/');
        }
    }

    generateRandomString(length = 12) {
        let result = Math.random().toString(36).slice(2);
        while (result.length < length) {
            result += Math.random().toString(36).slice(2);
        }
        if (result.length > length) {
            result = result.substring(0, length);
        }
        return result;
    }

    confirmDeletionRequest() {
        const me = this;
        const msgbox = new MessageBox('alert', this.container, 320);
        msgbox.title.innerText = 'Warning';
        msgbox.content.innerText = `The deletion is irreversible.  Are you sure you want to delete your account?`;
        msgbox.button1.innerText = 'Yes';
        msgbox.button2.innerText = 'No';
        msgbox.button1.onclick = () => {
            msgbox.cleanup();
            const user = me.auth.currentUser;

            // show the loading wheel
            me.loaderBg = Utils.addElement(me.container, 'div', 'alert-bg');
            me.global.router.showLoader();

            // call Firebase function cleanupUser
            let popupMes = '';
            const confirmationCode = me.generateRandomString();
            const cleanupFunc = me.global.router.functions.httpsCallable('cleanupUser');
            cleanupFunc({
                params: {
                    code: confirmationCode,
                    providers: user.providerData
                }
            }).then(() => {
                const promises = [];
                user.providerData.forEach(providerData => promises.push(user.unlink(providerData.providerId)));
                return Promise.all(promises);
            }).then(() => {
                me.global.processing = true;
                return user.delete();
            }).then(() => {
                popupMes = `Your account deletion is in progress.  The request confirmation code is: "<b>${confirmationCode.toUpperCase()}</b>".  A confirmation email is on the way to you .<br/>To check the status, goto <a href="${this.global.webHomeUrl}/checkstatus" target="_blank">${this.global.webHomeUrl}\n/checkstatus</a> and enter the code.`;
            }).catch(error => {
                popupMes = error.toString();
            }).finally(() => {
                // hide loader
                me.global.router.hideLoader();
                if (me.loaderBg) {
                    me.loaderBg.parentNode.removeChild(me.loaderBg);
                    me.loaderBg = null;
                }

                const mbox = new MessageBox('alert', me.container, 320);
                mbox.title.innerText = 'Confirmation Code';
                mbox.content.innerHTML = popupMes;
                mbox.configOneButton('OK').onclick = () => {
                    if (me.auth.currentUser) me.auth.signOut();
                    mbox.cleanup();
                    me.global.router.dispatch('/');
                }
            })
        }

        msgbox.button2.onclick = () => msgbox.cleanup();
    }
}