/* jshint esversion: 8 */
'use strict';

import '../../css/popup-box.scss';
import Utils from './utils.js';

export default class MessageBox {
    /**
     * The modal popop covers two types: modal and alert.
     * The modal contains a title bar, context box and a close (X) button at upper-right corner.
     * The alert contains a title bar, context box and two buttons at button.
     * @param {string} type: modal or alert
     * @param {object} parent: the parent DOM object that a gray translucent background layer is laid on. The popup is on top of the background layer.
     * @param {number} width: the fixed width of the popup when specified, otherwise the width will be driven by the style min-width=320px and the actual content within the popup.
     * @param {number} height: the fixed height of the popupwhen specified, otherwise the width will be driven by the style min-height=250px and the actual content within the popup.
     * @param {object} options: a json object that may contain these four keys: top, right, bottom, left. The value of them is a number. For example: options = {top: 0, left: 10}
     */
    constructor(type, parent, width, height, options = {}) {
        const position = side => {
            const value = options[side];
            if (value && Number.isInteger(value)) this.popup.style[side] = value.toString() + 'px';
        }

        const stopPropagation = event => {
            const popup = event.target.closest('.modal-popup');
            if ((popup.parentNode.scrollTop == 0 && event.deltaY < 0) || (popup.scrollHeight - popup.parentNode.scrollTop <= popup.parentNode.clientHeight && event.deltaY > 0)) {
                if (event.cancelable) event.preventDefault();
            } else {
                event.stopPropagation();
            }
        }

        this.container = Utils.addElement(parent, 'div');
        this.popup = Utils.addElement(this.container, 'div', 'modal-popup');
        this.popup.addEventListener('touchmove', event => {
            stopPropagation(event);
        }, { passive: false });
        this.popup.addEventListener('wheel', event => {
            stopPropagation(event);
        }, { passive: false });

        if (width) this.popup.style.width = width.toString() + 'px';
        if (height) this.popup.style.height = height.toString() + 'px';
        Object.keys(options).forEach(option => position(option));
        this.title = Utils.addElement(this.popup, 'div', 'modal-title');
        this.content = Utils.addElement(this.popup, 'div');

        if (type === 'modal') {
            this.container.className = 'modal-bg';
            this.content.className = 'modal-content';
            this.content.style.height = (height - 51).toString() + 'px'; // 51 means: 50 (title) + 1 (bottom border)
            const btn = Utils.addElement(this.popup, 'div', 'logos nav-btn close');
            btn.title = 'Close the popup modal';
            btn.onclick = event => {
                event.preventDefault();
                this.cleanup();
            };
        } else if (type === 'alert') {
            this.container.className = 'alert-bg';
            this.content.className = 'alert-body';
            this.content.style.height = (height - 113).toString() + 'px'; // 113 means: 50 (title) + 1 (bottom border) + 42 (button height + margin) + 20 (content padding)
            const actions = Utils.addElement(this.popup, 'div', 'alert-actions');
            this.button1 = Utils.addElement(actions, 'button', 'alert-button');
            this.button2 = Utils.addElement(actions, 'button', 'alert-button');
        } else {
            throw(new Error('Unknow message box type.'));
        }
    }

    configOneButton(btnLabel) {
        this.button2.style.display = 'none';
        this.button1.innerText = btnLabel;
        return this.button1;
    }

    show() {
        this.container.style.display = 'block';
    }

    hide() {
        this.container.style.display = 'none';
    }

    cleanup(event) {
        const container = this.container || event.target.closest('.modal-bg, .alert-bg');
        Utils.clearNode(container);
    }

    reposition() {
        this.popup.offsetHeight > this.container.offsetHeight ? this.container.classList.add('start') : this.container.classList.remove('start');
    }
}
