/**
 * jshint esversion: 8
 */
'use strict';

export default class Config {
    static menus = {
        home: {
            name: 'Home',
            path: '/home'
        },
        history: {
            name: 'History',
            path: '/history'
        },
        numbers: {
            name: 'Numbers',
            path: null,
            menus: {
                generator: {
                    name: 'Generator',
                    path: '/generator'
                },
                winnings: {
                    name: 'Winnings',
                    path: '/winnings'
                },
                stats: {
                    name: 'Statistics',
                    path: '/stats'
                },
                tprizes: {
                    name: 'Top Prizes',
                    path: '/tprizes'
                }
            }
        },
        about: {
            name: 'About',
            path: '/about'
        },
        signin: {
            name: 'Sign In',
            path: '/authenticate'
        }
    }
}
