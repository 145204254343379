/* jshint esversion: 8 */
'use strict';

import UserAdminBase from './user-base.js';
import Utils from '../../lib/utils.js';

export default class Registration extends UserAdminBase {
    constructor(global) {
        super(global);
        this.pageName = 'Registration';
    }

    async loadContentSection() {
        const me = this;
        // this.renderHeader();
        Utils.addElement(this.framebox, 'div', 'title').innerText = 'Create Powermega Account';

        // registration form
        const content = Utils.addElement(this.framebox, 'div', 'section');
        const regForm = Utils.addElement(content, 'form', 'content');
        Utils.addElement(regForm, 'div', 'label').innerText = 'FIRST AND LAST NAME';
        this.name = Utils.addElement(regForm, 'input', 'inputbox');
        Utils.addElement(regForm, 'div', 'label').innerText = 'EMAIL';
        this.email = Utils.addElement(regForm, 'input', 'inputbox');
        this.email.setAttribute('type', 'email');
        this.email.setAttribute('autocomplete', 'username');
        Utils.addElement(regForm, 'div', 'label').innerText = 'PASSWORD';

        const pwdFrame = Utils.addElement(regForm, 'div', 'tooltip-input');
        this.password = Utils.addElement(pwdFrame, 'input', 'inputbox', 'reg-password');
        this.password.setAttribute('type', 'password');
        this.password.setAttribute('autocomplete', 'new-password');
        const eyeicon = Utils.addElement(pwdFrame, 'div', 'logos eye-icon slash');
        eyeicon.onclick = () => Utils.togglePassword(eyeicon, 'reg-password');

        Utils.addElement(regForm, 'div', 'label').innerText = 'STATE';
        this.select = Utils.addElement(regForm, 'select', 'dropdown');
        this.getStates().then(states => {
            me.states = states;
            me.addStateSelectOptions(me.select);
        })

        // 18 years or older check box
        let agreement = Utils.addElement(regForm, 'div', 'agreement');
        this.checkbox1 = Utils.addElement(agreement, 'input', 'checkbox-box')
        this.checkbox1.setAttribute('type', 'checkbox');
        this.checkbox1.onclick = () => this.clickCheckbox('register-button');
        const y18 = Utils.addElement(agreement, 'span', 'checkbox-msg');
        y18.innerText = 'I am 18 years of age or older.';
        const infoIcon = Utils.addElement(y18, 'div', 'tooltip-icon');
        infoIcon.innerText = '\u261C';
        Utils.addElement(infoIcon, 'div', 'tooltiptext').innerText = 'You must be 18 years or older to be eligible to participate in the lottery. Any ineligible ticket play will be treated as an unclaimed prize.';

        // privacy policy and terms & conditions
        agreement = Utils.addElement(regForm, 'div', 'agreement');
        this.checkbox2 = Utils.addElement(agreement, 'input', 'checkbox-box')
        this.checkbox2.setAttribute('type', 'checkbox');
        this.checkbox2.onclick = () => this.clickCheckbox('register-button');
        Utils.addElement(agreement, 'span', 'checkbox-msg').innerHTML = 'I agree to the <a href="/privacy?wv=true" target="_blank">Privacy Policy</a> and <a href="/terms?wv=true" target="_blank">Terms & Conditions</a>.';
        this.errormsg = Utils.addElement(regForm, 'div', 'login-msg height60 error');

        // Register submit button
        let oBtnDiv = Utils.addElement(regForm, 'div', 'button-frame');
        let btn = Utils.addElement(oBtnDiv, 'button', 'submit-button', 'register-button');
        btn.disabled = true;
        btn.innerText = 'Register';
        btn.onclick = async event => {
            event.preventDefault();
            me.register().then(() => {
                this.global.router.dispatch('/');
            }).catch(error => {
                me.errormsg.innerHTML = error.message;
            })
        }
        Utils.addElement(oBtnDiv, 'div', 'tooltiptext', 'checkbox-required').innerText = 'The button will only be enabled if both checkboxes are checked';
    }

    initialize() {
        this.layout.selectMenuItem(null);
        this.resetForm();
    }

    register() {
        return new Promise(async (resolve, reject) => {
            // validate the input parameters before register the user
            let msg = '';
            this.errormsg.innerText = '';
            if (this.name.value.trim().length === 0) msg += '<li>Name field cannot be empty.</li>';
            if (!this.validateEmail(this.email.value)) msg += '<li>Email address is invalid.</li>';
            if (this.password.value.trim().length < 6) msg += '<li>Password length cannot be smaller than 6.</li>';
            if (this.select.selectedIndex === 0) msg += '<li>State needs to be selected.</li>'

            if (msg.length > 0) {
                reject({code: 'powermega/invalid-reg-parameters', message: '<ul>' + msg + '</ul>'});
            } else {
                // create Firebase Authentication user
                this.global.processing = true;
                this.auth.createUserWithEmailAndPassword(this.email.value, this.password.value).then(async userRecord => {
                    await userRecord.user.updateProfile({displayName: this.name.value});
                    this.updatePowermegaDatabase(userRecord.user).then(() => {
                        resolve()
                    }).catch(error => {
                        reject(error)
                    });
                }).catch(error => {
                    console.log('[Warning]', error);
                    if (error.code === 'auth/email-already-in-use') {
                        this.auth.sendSignInLinkToEmail(this.email.value, this.global.action).then(() => {
                            window.localStorage.setItem('emailForSignIn', this.email.value);
                            reject({
                                code: 'auth/email-already-in-use',
                                message: 'This email address "' + this.email.value + '" is already in use.  If you own this address, please check your email to complete the Sign Up process.'
                            })
                        }).catch(error => {
                            reject(error)
                        });
                    } else {
                        reject(error)
                    }
                });
            }
        });
    }

    updatePowermegaDatabase(authUser) {
        return this.ucol.where('email', '==', authUser.email).get().then(snapshot => {
            if (snapshot.docs && snapshot.docs.length > 0) {
                // there should be none or only one record returned, in case there are multiple, we just pick up the first one.
                snapshot.forEach(pmUserDoc => {
                    const pmUser = pmUserDoc.data();
                    if (!pmUser.uid) {
                        return this.linkPowermegaUser(authUser, pmUserDoc);
                    } else if (pmUser.uid === authUser.uid) {
                        return this.updatePowermegaUser(authUser, pmUserDoc);
                    } else if (pmUser.uid !== authUser.uid) {
                        return Promise.reject({
                            code: 'powermega/email-already-in-use',
                            message: 'The email address is already in use by another Powermega account.'
                        });
                    } else {
                        return Promise.resolve();
                    }
                });
            } else {
                return this.createUserInFirestore(authUser);
            }
        }).catch(error => {
            return Promise.reject(error);
        });
    }

    linkPowermegaUser(authUser, pmUserDoc) {
        const data = pmUserDoc.data();
        data.name = this.name.value || authUser.displayName || data.name;
        data.state = (this.select.selectedIndex > 0 ? this.select.options[this.select.selectedIndex].label : data.state) || '';
        data.registered = true;
        data.uid = authUser.uid;
        if (!data.avatar) {
            data.avatar = authUser.photoURL || '';
        }
        const newPmUserRef = this.ucol.doc(authUser.uid);
        this.setPowermegaUser(newPmUserRef, data, false).then(() => {
            let logMsg;
            if (data.referrer && data.referrer.source === 'app') {
                logMsg = 'Invited by an anonymous user within the Powermega app.'
            } else if (data.referrer && data.referrer.source === 'user') {
                logMsg = 'Invited by a Powermega user: ' + data.referrer.name + '(' + data.referrer.email + ').';
            }
            if (logMsg) this.writeToUserJourney(newPmUserRef, logMsg);
            logMsg = 'Finished account setup with name: ' + data.name + ' and email: ' + data.email + '.';
            this.writeToUserJourney(newPmUserRef, logMsg);
            return pmUserDoc.ref.delete();
        }).catch(error => {
            return Promise.reject(error);
        })
    }

    updatePowermegaUser(authUser, pmUserDoc) {
        const data = {};
        const pmUser = pmUserDoc.data();
        const inputName = this.name ? this.name.value.trim() : '';
        const avatar = authUser.photoURL || '';
        if (!pmUser.name || (inputName.length > 0 && pmUser.name !== inputName)) data.name = this.name.value || authUser.displayName;
        if (!pmUser.state && this.select && this.select.selectedIndex > 0) data.state = this.select.options[this.select.selectedIndex].label;
        if (!pmUser.avatar && avatar) data.avatar = avatar;
        return Object.keys(data).length === 0 ? Promise.resolve() : this.setPowermegaUser(pmUserDoc.ref, data, true, 'Updated user name and/or state: ' + JSON.stringify(data) + '.');
    }

    resetForm() {
        super.resetForm();
        this.name.value = '';
        if (this.select) this.select.selectedIndex = 0;
        if (this.checkbox1) this.checkbox1.checked = false;
        if (this.checkbox2) this.checkbox2.checked = false;
        this.clickCheckbox('register-button');
    }
}
