/* jshint esversion: 8 */
'use strict';

import LotteryBase from './lottery-base.js';
import {Generator} from './generator.js';
import Calendar from '../../lib/calendar.js';
import Utils from '../../lib/utils.js';

export default class Winnings extends LotteryBase {
    constructor(global) {
        super(global);
        this.pageName = 'Winnings';
        this.ucol = global.firebase.firestore().collection(`${global.dbname}/members/users`);
        this.generator = new Generator(global);
    }

    getContent() {
        this.cleanup();
        this.root = Utils.addElement(null, 'div', 'winnings-root');
        this.addLotteryTab(this.root, '/winnings');
        const cframe = Utils.addElement(this.root, 'div', 'content-frame');

        const sumsect = Utils.addElement(cframe, 'div', 'summary-section');
        if (window.innerWidth > 1111) Utils.addElement(sumsect, 'div', null, 'dpad-winnings-left').innerHTML = this.insertAd('dpad-winnings-left');
        this.cblock = Utils.addElement(sumsect, 'div', 'content-block');
        Utils.addElement(sumsect, 'div', null, 'dpad-winnings-right').innerHTML = this.insertAd('dpad-winnings-right');

        Utils.addElement(this.cblock, 'div', 'section notes').innerText = 'Select your numbers, and we will calculate the cost and winning prizes for the period specified below.';
        let divbox = Utils.addElement(this.cblock, 'div', 'section');
        const addOneBall = (cls, num) => {
            const bframe = Utils.addElement(divbox, 'div', 'ballframe');
            const ball = Utils.addElement(bframe, 'div', cls);
            if (num) ball.innerText = num.toString().padStart(2, '0');
            return bframe;
        }

        for (let i = 0; i < 5; i++) this.generator.selfChoice.addToTopList(addOneBall('ball large circle white'), this);
        const color = this.isPowerball ? 'red' : 'yellow';
        this.generator.selfChoice.addToTopList(addOneBall(`ball large circle ${color}`), this);

        divbox = Utils.addElement(this.cblock, 'div', 'section');
        const addMplier = label => {
            Utils.addElement(divbox, 'label', 'mplier wrap-over fs-pt85').innerText = label;
            return Utils.addToggleStatus(divbox);
        }
        if (this.isPowerball) {
            this.mplier = addMplier('POWER PLAY');
            this.dplay = addMplier('DOUBLE PLAY');
        } else {
            this.mplier = addMplier('MEGAPLIER');
            this.dplay = undefined;
        }

        this.mplier.onclick = () => this.refresh(this);
        if (this.dplay) this.dplay.onclick = () => this.refresh(this);

        divbox = Utils.addElement(this.cblock, 'div', 'section');
        const addButton = (label, func, title) => this.generator.selfChoice.addActionButton(divbox, label, func, this, title).classList.add('small');
        addButton('Quick Pick', this.autoFill, 'This button automatically and randomly populates the numbers at the top.');
        addButton('Self Pick', this.selfPick, 'This button allows you to manually select the numbers you like.');
        addButton('Favorites', this.favorite, 'This button allows you to select your favorite numbers stored in your account.');
        addButton('Reset', this.reset, 'This button clears the selected numbers at the top, preparing for a fresh start.');

        divbox = Utils.addElement(this.cblock, 'div', 'section', 'check-action');
        const beginDate = this.isPowerball ? '2015-10-08T03:00:00Z' : '2017-11-01T03:00:00Z';
        this.startDate = Calendar.createInputAndCalendar(divbox, beginDate.slice(0, 4), null, beginDate, null, {x: 'left', y: 'bottom'});
        this.startDate.inputbox.onchange = () => this.check(this);
        this.endDate = Calendar.createInputAndCalendar(divbox, null, null, new Date());
        this.endDate.inputbox.onchange = () => this.check(this);

        const getInfoMsg = () => {
            let msg = 'The current format and price point rule for ${lotteryType} began on ${beginDate}. The default winning tracking information provided below is from ${beginDate}, until the present. You have the option to modify the start and end dates according to your preference, but the start date should not be earlier than ${beginDate}.<br/><br/>';
            if (this.isPowerball) {
                msg = msg.replace(/\$\{beginDate\}/g, 'October 7, 2015');
                msg = msg.replace('${lotteryType}', 'Powerball');
                msg += ' Additionally, the Double Play option was introduced on August 23, 2021 and is available in 13 states and Puerto Rico.<br/><br/>';
            } else {
                msg = msg.replace(/\$\{beginDate\}/g, 'October 31, 2017');
                msg = msg.replace('${lotteryType}', 'Mega Millions');
            }
            return msg;
        }
        const info = Utils.addElement(divbox, 'div', 'logos info-icon');
        info.onclick = () => this.showAlertMessage(getInfoMsg(), null, 'Query Period');

        return this.root;
    }

    async initialize() {
        this.layout.selectMenuItem(this.layout.menus.numbers.menus.winnings.item);
    }

    autoFill(me) {
        me.generator.selfChoice.initializeLotteryType();
        me.setTopNumbers(Utils.generateRandomNumbers(me.generator.selfChoice.maxRegular, me.generator.selfChoice.maxColored));
        me.check(me);
    }

    selfPick(me) {
        const popup = me.getInfoPopup();
        me.generator.selfChoice.render(popup.content);
        me.generator.selfChoice.favorBtn.style.display = 'none';
        me.generator.selfChoice.copyBtn.style.display = 'none';
        me.generator.selfChoice.selectBtn.style.display = '';
        popup.reposition();

        document.addEventListener('selfPickNumbersEvent', event => {
            const detail = event.detail;
            popup.cleanup();
            for (let i = 0; i < 6; i++) me.topBalls[i].firstChild.innerText = detail.numbers[i];
            me.mplier.checked = detail.mplier.checked;
            if (me.dplay !== undefined) me.dplay.checked = detail.dplay.checked;
            me.check(me);
        });
    }

    favorite(me) {
        if (me.auth.currentUser) {
            const popup = me.getInfoPopup();
            const myFavor = me.generator.myFavorites;
            myFavor.initializeLotteryType();

            myFavor.cblock = Utils.addElement(popup.content, 'div', 'content-block');
            myFavor.cblock.table = myFavor.renderNumbersTable(myFavor.cblock, false);
            myFavor.restore().then(() => {
                popup.reposition();
                const rows = myFavor.cblock.table.rows;
                if (rows.length > 0) {
                    rows[0].firstChild.innerText = 'Select one set of your favorite numbers';
                    rows[0].firstChild.classList.add('info-msg');
                } else {
                    const msg = Utils.addElement(myFavor.cblock, 'div', 'info-msg');
                    msg.innerText = 'No previously saved favorite numbers.';
                }

                document.addEventListener('myFavoriteNumbersEvent', event => {
                    const detail = event.detail;
                    popup.cleanup();
                    for (let i = 0; i < 6; i++) me.topBalls[i].firstChild.innerText = detail.numbers[i];
                    me.mplier.checked = detail.mplier.checked;
                    if (me.dplay !== undefined) me.dplay.checked = detail.dplay.checked;
                    me.check(me);
                });
            });
        } else {
            me.showSignInRequired();
        }
    }

    reset(me) {
        me.setTopNumbers();
        const mpliers = me.cblock.firstChild.nextSibling.querySelectorAll("input[type='checkbox']");
        for (let i = 0; i < mpliers.length; i++) mpliers[i].checked = false;
        me.cleanup(false);
    }

    async check(me) {
        const ltype = me[me.type];
        const topNumbers = me.getTopNumbers(true);

        const findMatches = wnum => {
            const wbmatch = [];
            const cbmatch = topNumbers[5] === wnum[5];
            const wbnumbers = topNumbers.slice(0, 5);
            for (let i = 0; i < 5; i++) {
                if (wbnumbers.includes(wnum[i])) wbmatch.push(i);
            }
            return {win: wbmatch.length > 2 || cbmatch, wb: wbmatch, cb: cbmatch};
        }

        const hasWon = game => {
            const match = findMatches(game.wnum);
            const dpmatch = (me.isPowerball && game.dpwnum && me.dplay.checked) ? findMatches(game.dpwnum) : null;

            if (match.win || (dpmatch && dpmatch.win)) {
                return {match: match, dpmatch: dpmatch}
            } else {
                return null;
            }
        }

        const ticketPrice = () => {
            let price = 2;
            if (me.mplier.checked) price++;
            return price;
        }

        const formatPrice = amount => (amount).toLocaleString('en-US', {style:'currency', currency:'USD'});

        const addSummaryItem = (parent, label, value) => {
            const tr = Utils.addElement(parent, 'tr');
            Utils.addElement(tr, 'td').innerText = label;
            const vtd = Utils.addElement(tr, 'td');
            if (value) vtd.innerHTML = value;
            return vtd;
        }

        const createCheckSection = (parent, section, clsName) => {
            if (section) Utils.clearNode(section);
            return Utils.addElement(parent, 'div', clsName);
        }

        me.global.router.showLoader();

        me.checkSummary = createCheckSection(me.cblock, me.checkSummary, 'section');
        me.checkResult = createCheckSection(me.cblock.parentNode.parentNode, me.checkResult, 'jackpot-list');

        const summaryTable = Utils.addElement(me.checkSummary, 'table', 'payouts summary');
        const isDoublePlay = me.dplay && me.dplay.checked;
        if (me.isPowerball && isDoublePlay) {
            addSummaryItem(summaryTable, 'Ticket price beginning August 23, 2021', `${formatPrice(ticketPrice() + 1)}`);
            addSummaryItem(summaryTable, 'Ticket price before August 23, 2021', `${formatPrice(ticketPrice())}`);
        } else {
            addSummaryItem(summaryTable, 'Ticket price for each draw', `${formatPrice(ticketPrice())}`);
        }
        const nwins = addSummaryItem(summaryTable, 'Number of wins during the period');
        const tdraw = addSummaryItem(summaryTable, 'Total number of draws during the period');
        const price = addSummaryItem(summaryTable, 'Total amount could be spent on tickets');
        const prize = addSummaryItem(summaryTable, 'Total prize amount could have won ');

        let totalDraws = 0, numberWins = 0, ticketCost = 0, totalPrize = 0;
        const ticketUnitPrice = ticketPrice();
        const convertDate = (localString, offset) => {
            const date = new Date(`${localString}T00:00:00`);
            date.setHours(date.getHours() + offset);
            return date;
        }
        const startDate = convertDate(this.startDate.inputbox.value, -12);
        const endDate = convertDate(this.endDate.inputbox.value, 36);

        return me.getHistoricalData(ltype.histUrl).then(games => {
            let tileCount = 0, adCount = 0, auNamePrefix = 'dpad-winnings-tile', placements = [5, 9, 16];
            games.forEach(game => {
                const ddate = new Date(game.ddate);
                if (startDate < ddate && ddate < endDate) {
                    totalDraws++;
                    ticketCost += (isDoublePlay && game.dpwnum) ? ticketUnitPrice + 1 : ticketUnitPrice;
                    let winnings = hasWon(game);
                    if (winnings) {
                        // insert ads
                        if (placements.includes(++tileCount) && adCount < placements.length) {
                            const adUnitName = `${auNamePrefix}-${++adCount}`;
                            Utils.addElement(me.checkResult, 'div', 'jackpot-tile dpad-winnings-tile').innerHTML = this.insertAd(adUnitName);
                            this.loadAds(adUnitName);
                        }

                        numberWins++;
                        const frame = Utils.addElement(me.checkResult, 'div', 'jackpot-tile');
                        frame.me = me;
                        frame.ltype = me.type;
                        ltype.dailyFeed = {prev: game};
                        frame.onclick = event => {
                            event.stopPropagation();
                            me.renderPayoutsPopup(event.target.closest('.jackpot-tile'), game, 'dpad-payoutmodal-banner');
                        }
                        me.renderPrevDraw(frame, me.type, undefined, true);
                        totalPrize += me.loadDrawTiles(ltype, game, winnings);
                    }
                }
            });
            nwins.innerHTML = (numberWins).toLocaleString();
            tdraw.innerHTML = (totalDraws).toLocaleString();
            price.innerHTML = `<b class="red">${me.formatAmount(ticketCost)}</b>`;
            prize.innerHTML = `<b class="green">${me.formatAmount(totalPrize)}</b>`;

            me.global.router.hideLoader();
        });
    }

    getTopNumbers(returnNumerical = false) {
        const result = [];
        for (let i = 0; i < 6; i++) {
            let number = this.topBalls[i].firstChild.innerText;
            if (number) result.push(returnNumerical ? parseInt(number) : number);
        }
        return result;
    }

    setTopNumbers(numbers) {
        const balls = this.cblock.firstChild.nextSibling.children;
        for (let i = 0; i < balls.length; i++) balls[i].firstChild.innerText = numbers ? numbers[i].toString().padStart(2, '0') : '';
    }

    cleanup(deepClean = true) {
        if (deepClean) {
            this.topBalls = [];
            this.mplier = false;
            this.dplay = false;
        }
        if (this.checkResult) Utils.clearNode(this.checkResult);
        this.checkResult = undefined;
        if (this.checkSummary) Utils.clearNode(this.checkSummary);
        this.checkSummary = undefined;
    }

    getInfoPopup(width) {
        const popup = this.layout.createModalPopup(null, null, width);
        popup.title.style.display = 'none';
        return popup;
    }

    loadDrawTiles(ltype, game, winnings) {
        const highlightMatches = (parent, match, winInfo, mplier = false, isDoublePlay = false) => {
            const selectedClass = isDoublePlay ? 'selected-green' : 'selected-blue';
            const balls = parent.getElementsByClassName('ballframe');
            if (match.cb) balls[5].classList.add(selectedClass);
            for (let i = 0; i < match.wb.length; i++) balls[match.wb[i]].classList.add(selectedClass);

            const matchKey = `${match.wb.length} + ${match.cb ? 1 : 0}`;
            let amount;
            if (matchKey === '5 + 1' && !isDoublePlay) {
                amount = game.jpot;
            } else {
                amount = this.payouts(matchKey, isDoublePlay);
                if (mplier && !isDoublePlay) amount = amount * game.mplier;
            }
            const cls = amount === 0 ? '' : ' class="green"';

            winInfo.innerHTML = `(${matchKey})${mplier ? ` x ${game.mplier}` : ''} = <b${cls}>${this.formatAmount(amount)}</b>`;
            return amount;
        }

        this.loadPrevDrawInfo(ltype, game);

        let prize = 0;
        prize += highlightMatches(ltype.prevNumsJp, winnings.match, this.matched, this.mplier.checked);
        if (winnings.dpmatch && this.dplay && this.dplay.checked) {
            prize += highlightMatches(ltype.prevNumsDp, winnings.dpmatch, this.dpmatched, false, true);
        } else if (this.isPowerball) {
            this.dpmatched.innerText = 'N.A.';
        }

        return prize;
    }

    payouts(key, isDoublePlay) {
        const mm = {
            '5 + 0': 1000000,
            '4 + 1': 10000,
            '4 + 0': 500,
            '3 + 1': 200,
            '3 + 0': 10,
            '2 + 1': 10,
            '1 + 1': 4,
            '0 + 1': 2
        }
        const pb = {
            '5 + 0': 1000000,
            '4 + 1': 50000,
            '4 + 0': 100,
            '3 + 1': 100,
            '3 + 0': 7,
            '2 + 1': 7,
            '1 + 1': 4,
            '0 + 1': 4
        }
        const dp = {
            '5 + 1': 10000000,
            '5 + 0': 500000,
            '4 + 1': 50000,
            '4 + 0': 500,
            '3 + 1': 500,
            '3 + 0': 20,
            '2 + 1': 20,
            '1 + 1': 10,
            '0 + 1': 7
        }

        let amount;

        if (isDoublePlay) amount = dp[key];
        else if (this.isPowerball) amount = pb[key];
        else amount = mm[key];

        return amount === undefined ? 0 : amount;
    }

    formatAmount(amount) {
        return Utils.formatAmount(amount, amount >= 10000);
    }

    refresh(me) {
        if (me.checkResult) me.check(me);
    }
}
