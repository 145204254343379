/* jshint esversion: 8 */
'use strict';

import BasePage from '../../lib/page-base.js';

export default class AboutPage extends BasePage {
    constructor(global) {
        super(global);
        this.pageName = 'About';
    }

    initialize() {
        this.layout.selectMenuItem(this.layout.menus.about.item);
        this.layout.addScrollingEffect();
        this.layout.webview ? this.showWebviewElement('pmb-webview') : this.layout.hideHeaderFooter(false);

        document.getElementById('mpad-about-multipex').innerHTML = this.insertAd('mpad-about-multipex');
        document.getElementById('dpad-about-tile').innerHTML = this.insertAd('dpad-about-tile');

        document.getElementById('main-content').style.removeProperty("min-height");
    }

    getContent() {
        return `<div class="pm-brand hide-element" id="pmb-webview">
    <div class="logos pmlogo-36x36"></div>
    <div>
        <div class="b-title">POWERMEGA</div>
        <div class="b-subtitle">Helping You Win!</div>
    </div>
</div>
<div id="hero-section">
    <div id="hero-container">
        <div class="welcome-h1">Welcome to Powermega</div>
        <div class="welcome-h2">An app that helps you to more easily play Mega Millions and Powerball.</div>
        <img id="hero-img" src="./images/hero-img.jpg"></img>
        <div id="app-info">
            <div class="welcome-h3">Get the app on your iPhone and win big!</div><br/>
            <!--<div class="notes">Android app is coming soon.</div>-->
            <div>
                <a href="/dlink/iosapp" class="logos store-link" id="apple-link"></a>
            </div>
        </div>
        <div class="bottom-margin"></div>
    </div>
</div>
<div id="mpad-about-multipex"></div>
<div class="flex-container">
    <div class="feature-block">
        <img class="fimage" src="./images/powermega-balls.png"></img>
        <div class="welcome-p">This cutting-edge lottery app provides you with the latest Mega Millions and Powerball information, including upcoming and past Jackpot numbers, winning amounts, payouts, etc.  It helps you manage, organize, and track your favorite numbers and tickets.</div>
    </div>
    <div class="feature-block">
        <img class="fimage" src="./images/scan.png"></img>
        <div class="welcome-p">Using advanced AI technology, this remarkable app accurately recognizes Mega Millions and Powerball ticket numbers, identifies the specific lottery type, effortlessly tracks draw dates, and efficiently monitors your potential winnings</div>
    </div>
    <div class="feature-block">
        <img class="fimage" src="./images/statistics.png"></img>
        <div class="welcome-p">It empowers you with all historical winning information, statistics of each number, calculating your cost and winnings for your chosen number, and a powerful number generator, giving you the winning edge and maximizing your chances of hitting the jackpot!</div>
    </div>
    <div class="feature-block">
        <img class="fimage" src="./images/group-play.png"></img>
        <div class="welcome-p">The app helps you to play the lottery with other people.  You can use it to define groups, manage your tickets, and share winning information and split ticket costs with group members.</div>
    </div>
    <div class="feature-block" id="dpad-about-tile"></div>
    <div class="feature-block">
        <img class="fimage" src="./images/notification.png"></img>
        <div class="welcome-p">You can opt-in to receive notifications based on the criteria you specify.  For example, you can ask the app to notify you when a Jackpot exceeds $800 million and/or send you the winning number for every draw.</div>
    </div>
    <div class="feature-block">
        <img class="fimage" src="./images/video-news.png"></img>
        <div class="welcome-p">This app offers you instant access to lottery breaking news, captivating videos, and every thrilling lottery drawing video. It's your go-to destination for staying ahead in the lottery game!</div>
    </div>
</div>`;
    }
}
