/* jshint esversion: 8 */
'use strict';

const fetch = require('node-fetch');
const cache = {};
const etags = {};

module.exports = function read(url = null, options = { headers: {} }) {
    url = url ? url : options.url;
    if (options.method === 'GET' || !options.method) {
        const etag = etags[url];
        const cachedResponse = cache[url];
        if (etag) {
            options.headers['If-None-Match'] = etag;
        }

        return fetch(url, options).then((response) => {
            if (response.status === 304) {
                return cachedResponse.clone();
            } else if (response.status === 200) {
                const responseEtag = response.headers.get('Etag');
                if (responseEtag) {
                    cache[url] = response.clone();
                    etags[url] = responseEtag;
                }
            }
            return response;
        });
    }

    // all other requests go straight to fetch
    // can't use apply(undefined, arguments) as babel uses _arguments which is different..
    return fetch.call(undefined, url, options);
}