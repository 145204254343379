/**
 * jshint esversion: 8
 *
 * This class BasePage is the base for all the pages, including AboutPage, Registration, Authentication,
 * ManageAccount, EmailLogin, ProfileUpdate, Privacy Policy, Terms & Conditions, etc.
 *
 * It sets up the page layout, which includes header, footer, content section placeholder space, user consent,
 * by using PageLayout class from ./page-layout.js.
 *
 * To instantiate an instance, the class calls two methods of its subclass:
 *     a mandatory method getContent(), which renders the main body, the content section part, of the page.
 *     an optional method initialize(), which is in some cases needed after the content has been rendered.
 *
 * the subclass fills in ${this.layout.main} placeholder space and decides showing or hiding header and/or footer.
 */
'use strict';

import PageLayout from './page-layout.js';
import AdManager from '../app/components/ad-manager.js';
import Utils from './utils.js';

export default class BasePage {
    constructor(global) {
        if (global) {
            this.global = global;
            this.auth = global.firebase.auth();
        }
        this.layout = PageLayout.getInstance(global);
        this.content = this.layout.content;
        this.location = window.location.href;
    }

    static getInstance(global) {
        if (!this.instance) this.instance = new this(global);
        return this.instance;
    }

    async getSubclassContent() {
        this.layout.main.classList.remove('vcenter');
        this.layout.main.removeAttribute('style');
        this.layout.setAuthenticationStatus();

        const content = this.getContent.constructor.name === 'AsyncFunction' ? await this.getContent() : this.getContent();
        if (typeof content === 'string') {
            this.layout.main.innerHTML = content;
        } else if (typeof content === 'object' && content !== null) {
            Utils.clearNode(this.layout.main, false);
            this.layout.main.appendChild(content);
        }
    }

    async renderContent() {
        await this.getSubclassContent();

        if (this.layout.main.classList.contains('scheme-mm')) this.layout.main.classList.remove('scheme-mm');
        if (this.layout.main.classList.contains('scheme-pb')) this.layout.main.classList.remove('scheme-pb');

        if (typeof this.initialize === 'function') {
            this.initialize.constructor.name === 'AsyncFunction' ? await this.initialize() : this.initialize();
        }

        this.sendTrackingEvent(this.pageName, window.location.pathname);
        this.loadAds();
    }

    /**
     * the logEvent is for letting native app to send webview analytics event tracking metrics
     * @param {string} name: the name of the message, for example: webview
     * @param {object} params: a json object that contains the web page information, for example: page name and url path
     */
    sendTrackingEvent(pageName, pagePath) {
        const format = pageName => pageName.toLowerCase().replace(/ /g, '-');

        if (window.AnalyticsWebInterface) {
            // Call Android interface
            window.AnalyticsWebInterface.logEvent('webview', JSON.stringify({
                page_name: `webview/${format(pageName)}`,
                page_path: pagePath
            }));
        } else if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.firebase) {
            // Call iOS interface
            var message = {
                command: 'logEvent',
                name: 'webview',
                parameters: {
                    page_name: `webview/${format(pageName)}`,
                    page_path: pagePath
                }
            };
            window.webkit.messageHandlers.firebase.postMessage(message);
        } else if (this.global.router.analytics) {
            this.global.router.analytics.logEvent('page_view', {
                page_name: `web/${format(pageName)}`,
                page_path: pagePath
            });
        }
    }

    /**
     * the setUserProperty is for letting native app to send webview analytics metrics of user properties
     * this method is not used yet by this project
     * @param {string} name
     * @param {string} value
     */
    setUserProperty(name, value) {
        if (name && value) {
            if (window.AnalyticsWebInterface) {
                // Call Android interface
                window.AnalyticsWebInterface.setUserProperty(name, value);
            } else if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.firebase) {
                // Call iOS interface
                var message = {
                    command: 'setUserProperty',
                    name: name,
                    value: value
                };
                window.webkit.messageHandlers.firebase.postMessage(message);
            }
        }
    }

    showWebviewElement(eid) {
        const pmBrand = document.getElementById(eid);
        if (pmBrand) pmBrand.classList.remove('hide-element');
    }

    insertAd(adUnitName) {
        if (!this.ads) this.ads = [];
        const adUnit = AdManager.getAdUnit(adUnitName);
        if (!this.ads.includes(adUnit.sid)) this.ads.push(adUnit.sid);
        return adUnit.tag;
    }

    loadAds(adUnitName) {
        const hasAdLoaded = slotId => {
            // this function is under the assumption that one google-ad-slot appear once on a single page.
            const adSlots = document.querySelectorAll(`[data-ad-slot='${slotId}']`);
            return adSlots.length === 1 && adSlots[0].getAttribute('data-adsbygoogle-status') === 'done';
        }

        const loadAd = slotId => {
            if (!hasAdLoaded(slotId)) {
                try {
                    (adsbygoogle = window.adsbygoogle || []).push({params: {google_ad_slot: slotId}});
                } catch(error) {
                    // The error is most likely as this: "All ins elements in the DOM with class=adsbygoogle already have ads in them."
                    // ignore the error and continue, as the slotId has been pushed into the global sapce.
                    console.log(`Warning! the ad slot "${slotId}" cannot be loaded.`, error.toString());
                }
            }
        }

        if (this.ads && this.ads.length > 0) {
            if (adUnitName) {
                const adUnit = AdManager.getAdUnit(adUnitName);
                if (adUnit && this.ads.includes(adUnit.sid)) loadAd(adUnit.sid);
            } else {
                this.ads.forEach(slotId => loadAd(slotId));
            }
        }
    }

    initializeAds(adName, numberOfAds) {
        if (numberOfAds) {
            for (let i = 1; i <= numberOfAds; i++) {
                const adUnitName = `${adName}-${i}`;
                const adUnit = document.getElementById(adUnitName);
                if (adUnit) adUnit.innerHTML = this.insertAd(adUnitName);
            }
        } else {
            const adUnit = document.getElementById(adName);
            if (adUnit) adUnit.innerHTML = this.insertAd(adName);
        }
    }
}
