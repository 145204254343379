/* jshint esversion: 8 */
'use strict';

import { enc, AES, mode as _mode, pad } from 'crypto-js';
import read from './etag-fetch.js';

export default class Utils {
    static addElement(parent, tag, className, domId) {
        const element = document.createElement(tag);
        if (className) element.className = className;
        if (domId) element.id = domId;
        if (parent) parent.appendChild(element);
        return element;
    }

    static addAnchor(parent, className, href, text, cta, isNewTab = false) {
        const anchor = this.addElement(parent, 'a');
        if (className) anchor.className = className;
        if (href) anchor.href = href;
        if (text) anchor.innerText = text;
        if (cta) parent.onclick = event => cta(event);
        if (isNewTab) anchor.target = '_blank';
        return anchor;
    }

    static clearNode(node, removeSelf = true) {
        while (node && node.hasChildNodes()) this.clearNode(node.firstChild);
        if (removeSelf && node.parentNode) node.parentNode.removeChild(node);
    }

    static clearNodeById(id, removeSelf) {
        const node = document.getElementById(id);
        if (node) this.clearNode(node, removeSelf);
    }

    static getQueryParam(query, key, caseSensitive = false) {
        if (query) {
            if (query.startsWith('?')) query = query.slice(1);
            const kvps = query.split('&');
            for (let i = 0; i < kvps.length; i++) {
                const kv = kvps[i].split('=');
                if (kv.length === 2) {
                    const isMatch = caseSensitive ? decodeURIComponent(kv[0]) === key : decodeURIComponent(kv[0].toLowerCase()) === key.toLowerCase();
                    if (isMatch) return decodeURIComponent(kv[1].trim());
                }
            }
        }
        return null;
    }

    static addTicketNumbers(parent, type, sets) {
        for (let i = 0; i < sets.length; i++) {
            const numbers = sets[i].tnum;
            this.addJackpotNumbers(parent, type, numbers, null, i);
        }
    }

    static addJackpotNumbers(parent, type, numbers, mplier, index, clsName) {
        const alphabet = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

        const row = this.addElement(parent, 'div', 'lottery-balls');
        if (index !== undefined && Number.isInteger(index)) this.addElement(parent, 'div', 'alphabet').innerText = alphabet[i];
        const addOneNumber = (number, cls) => {
            const frame = this.addElement(row, 'div', 'ballframe');
            this.addElement(frame, 'div', cls).innerText = number === '00' ? '' : number;
        }
        clsName = clsName ? 'ball large circle ' + clsName : 'ball large circle';
        for (let i = 0; i < numbers.length - 1; i++) {
            let num = numbers[i];
            num = num ? num.toString().padStart(2, '0') : '';
            addOneNumber(num, clsName);
        }
        const colorCls = type === 'powerball' ? 'ball large circle red' : 'ball large circle yellow';
        addOneNumber(numbers.slice(-1).toString().padStart(2, '0'), colorCls);
        this.addElement(row, 'div', 'ball large mplier').innerText = this.getMultiplier(mplier);
        return row;
    }

    static setJackpotNumbers(parent, numbers, mplier) {
        parent.classList.remove('hide');
        const balls = parent.getElementsByClassName('circle');
        if (balls.length === 6) {
            for (let i = 0; i < 6; i++) {
                const num = numbers[i];
                balls[i].innerText = num ? num.toString().padStart(2, '0') : '';
            }
        }
        const tail = parent.getElementsByClassName('mplier');
        if (tail && tail.length === 1) tail[0].innerText = this.getMultiplier(mplier);
    }

    static getMultiplier(mplier) {
        if (mplier && Number.isInteger(mplier)) {
            return 'X' + mplier;
        } else if (typeof mplier === 'string') {
            return mplier;
        } else {
            return '';
        }
    }

    static async fetch(url, options) {
        return read(url, options);
    }

    static async downloadStorageJson(sref, encrypted = false) {
        return sref.getDownloadURL().then(url => {
            return this.fetch(url);
        }).then(response => {
            return encrypted ? response.text() : response.json();
        }).then(body => {
            if (encrypted) {
                const txt = AES.decrypt(body, enc.Utf8.parse(this.k().join('')), { mode: _mode.ECB, padding: pad.Pkcs7 });
                return JSON.parse(txt.toString(enc.Utf8));
            } else {
                return body;
            }
        }).catch(error => {
            return error;
        });
    }

    static k() {
        const s = [8066846978657680, 1495149195958901];
        const c = (x) => {
            const r = [];
            for (let i = 16; i > 1; i -= 2) r.push(String.fromCharCode(x.slice(i - 2, i)));
            return r;
        }
        return c(s[0].toString()).concat(c((s[0] - s[1]).toString()));
    }

    static formatAmount(amount, short = false) {
        if (amount >= 1000000000) {
            return `\$${Math.round(amount/1000000)/1000}${short ? 'B' : ' Billion'}`;
        } else if (amount >= 1000000) {
            return `\$${Math.round(amount/10000)/100}${short ? 'M' : ' Million'}`;
        } else if (false && amount >= 1000) { // don't convert thousands
            return `\$${Math.round(amount/10)/100}${short ? 'K' : ',000'}`;
        } else {
            return short ? `\$${(amount).toLocaleString()}` : (amount).toLocaleString('en-US', {style:'currency', currency:'USD'});
        }
    }

    static formatDate(utcString, withYear = true, withTime = true) {
        const date = new Date(utcString);
        const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        const monthsOfYear = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const year = date.getFullYear();
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const meridiem = hours >= 12 ? "PM" : "AM";
        const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
        let result = `${daysOfWeek[date.getDay()]}, ${monthsOfYear[date.getMonth()]} ${date.getDate()}`;
        if (withYear) result += `, ${year}`;
        if (withTime) result += `, ${formattedHours}:${formattedMinutes} ${meridiem}`;

        return result;
    }

    static countdown(endDate) {
        const timeRemaining = new Date(endDate).getTime() - new Date().getTime();

        return timeRemaining > 0 ? {
            days: Math.floor(timeRemaining / 86400000),              // 86400000 = 1000 * 60 * 60 * 24
            hours: Math.floor((timeRemaining % 86400000) / 3600000), // 3600000 = 1000 * 60 * 60
            minutes: Math.floor((timeRemaining % 3600000) / 60000),  // 60000 = 1000 * 60
            seconds: Math.floor((timeRemaining % 60000) / 1000)      // 60000 = 1000 * 60
        } : null;
      }

    static isEDT(dateString) {
        const date = dateString ? new Date(dateString) : new Date();
        const jan = new Date(date.getFullYear(), 0, 1).getTimezoneOffset();
        const jul = new Date(date.getFullYear(), 6, 1).getTimezoneOffset();
        return Math.min(jan, jul) === date.getTimezoneOffset();
    }

    /**
     * Check if today belongs to the weekdays listed in the calling parameters.
     * @param  {...any} days the list of weekdays in integer format, 0 means Sunday, 1 means Monday and 6 means Saturday
     * @returns true if today belongs to the day in the calling list, otherwise false.
     */
    static isTodayInWeekday(...days) {
        const now = this.getCurrentLocalTime();
        for (let i=0; i<days.length; i++) {
            if (now.weekday === days[i]) return true;
        }
        return false;
    }

    /**
     * Get the current date, time, weekday in local format.  Below are the reference docs:
     * https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
     * https://www.w3schools.com/jsref/jsref_tolocalestring.asp
     * https://docs.aws.amazon.com/redshift/latest/dg/time-zone-names.html
     * new Date().toLocaleString("en-US", {timeZone: "GMT", "hourCycle": "h24"})
     * new Date().toLocaleString("en-US", {timeZone: "America/New_York", "hourCycle": "h24"})
     * new Date().toLocaleString("en-US", {timeZone: "America/Los_Angeles", "hourCycle": "h12"})
     * new Date().toLocaleString("en-GB", {timeZone: "Europe/London", "hourCycle": "h12"})
     * new Date().toLocaleString("zh-CN", {timeZone: "Asia/Shanghai", "hourCycle": "h24"})
     *
     * @param {*} timezone - the timezone of returned date, time json object is in.  The default value is "GMT"
     */
    static getCurrentLocalTime(timezone = 'GMT') {
        const convertWeekdayToNumber = weekday => {
            if (weekday.toLowerCase() === 'monday') return 1;
            else if (weekday.toLowerCase() === 'tuesday') return 2;
            else if (weekday.toLowerCase() === 'wednesday') return 3;
            else if (weekday.toLowerCase() === 'thursday') return 4;
            else if (weekday.toLowerCase() === 'friday') return 5;
            else if (weekday.toLowerCase() === 'saturday') return 6;
            else return 0; // Sunday
        }
        const now = new Date();
        const localString = now.toLocaleString('en-US', {timeZone: timezone, hourCycle: 'h24'});
        const day = now.toLocaleString('en-US', {timeZone: timezone, weekday: 'long'});
        const dateTime = localString.split(', ');
        const date = dateTime[0].split('/').map(item => parseInt(item, 10));
        const time = dateTime[1].split(':').map(item => parseInt(item, 10));
        return {
            year: date[2],
            month: date[0],
            date: date[1],
            weekday: convertWeekdayToNumber(day),
            hour: time[0],
            minute: time[1],
            second: time[2]
        }
    }

    /**
     * Get the next wakeup time for refreshing the home page feed.  The calculation is based on GMT timezone
     * @param {string} type: it is either 'megamillions' or 'powerball'
     * @returns the next wakeup time in milliseconds
     */
    static getNextWakeupTime(type = 'megamillions') {
        const offset = this.isEDT() ? 4 : 5;
        const convertToGmtMilliseconds = (hour, minute) => (((hour + offset) % 24) * 60 + minute) * 60000;

        const getLowFrequency = () => {
            const now = new Date();
            return 3660000 - (now.getMinutes() * 60 + now.getSeconds()) * 1000; // At 1 minute past the top of the hour following this one
        }

        const getHighFrequency = () => {
            const now = this.getCurrentLocalTime();
            const tmark1 = convertToGmtMilliseconds(21, 55); // convert 9:55 PM ET to GMT in milliseconds
            const tmark2 = convertToGmtMilliseconds(22, 55); // convert 10:55 PM ET to GMT in milliseconds
            const tmark3 = convertToGmtMilliseconds(0, 45);  // convert 12:45 AM to GMT in milliseconds
            const nowmsec = ((now.hour * 60 + now.minute) * 60 + now.second) * 1000;
            if (nowmsec < tmark1 || nowmsec >= tmark3) return getLowFrequency();
            else if (nowmsec >= tmark1 && nowmsec < tmark2) return tmark2 - nowmsec;
            else if (nowmsec >= tmark2 && nowmsec < tmark3) return 30000; // 30 seconds
            else return 3600000;
        }

        if (type === 'megamillions') {
            return this.isTodayInWeekday(3, 6) ? getHighFrequency() : getLowFrequency();
        } else if (type === 'powerball') {
            return this.isTodayInWeekday(0, 2, 4) ? getHighFrequency() : getLowFrequency();
        } else {
            return 3600000; // one hour later;
        }
    }

    static togglePassword(eye, id) {
        const input = document.getElementById(id);
        if (input.getAttribute('type') === 'password') {
            input.setAttribute('type', 'text');
            eye.classList.replace('slash', 'open');
        } else {
            input.setAttribute('type', 'password');
            eye.classList.replace('open', 'slash');
        }
    }

    static addWidget(parent, labelClass, spanClass) {
        const container = this.addElement(parent, 'label', labelClass);
        const input = this.addElement(container, 'input');
        input.type = 'checkbox';
        this.addElement(container, 'span', spanClass);
        return input;
    }

    static addRadioButton(parent, className = 'radio-button') {
        return this.addWidget(parent, className, 'radio');
    }

    static addCheckbox(parent, className = 'checkbox') {
        return this.addWidget(parent, className, 'checkmark');
    }

    static addToggleSwitch(parent, className = 'toggle-switch') {
        return this.addWidget(parent, className, 'slider');
    }

    static addToggleStatus(parent, className = 'toggle-status') {
        return this.addWidget(parent, className, 'slider');
    }

    /**
     * Create tabs and their CTA callbacks
     * @param {object} parent: the parent DOM object that the tabs are under.
     * @param {object} tabs: this is a json array that contains the information of multiple tabs. Its structure is like the following
     *     [
     *         {
     *             label: ${text label of the tab},
     *             callback: ${the callback function name},
     *             params: ${a json object as the callback input}
     *             default: ${true or false}
     *         }
     *     ]
     */
    static createaTabs(parent, tabs) {
        const tabGroup = Utils.addElement(parent, 'div', 'tab');
        parent.tabGroup = tabGroup;

        const createButton = (parent, tab) => {
            const btn = Utils.addElement(parent, 'button');
            btn.innerText = tab.label;
            if (tab.default) {
                btn.classList.add('selected');
                tabGroup.tabSelected = btn;
            }
            btn.onclick = async event => {
                const passedCheck = tab.precheck ? tab.precheck(tab.params) : true;
                if (passedCheck) {
                    tab.params.me.global.router.showLoader();
                    if (tabGroup.tabSelected) tabGroup.tabSelected.classList.remove('selected');
                    tabGroup.tabSelected = event.target;
                    tabGroup.tabSelected.classList.add('selected');
                    await tab.callback(tab.params);
                    tab.params.me.global.router.hideLoader();
                }
            }
            return btn;
        }

        tabs.forEach(tab => createButton(tabGroup, tab));
    }

    static generateRandomNumbers(maxRegular, maxColored) {
        const getRandomArbitrary = (min, max) => {
            let num = Math.floor(Math.random() * (max - min + 1)) + min;
            return num.toString().padStart(2, '0');
        }
        const result = [];
        while (result.length < 5) {
            const regball = getRandomArbitrary(1, maxRegular);
            if (!result.includes(regball)) result.push(regball);
        }
        result.sort();
        result.push(getRandomArbitrary(1, maxColored));
        return result;
    }
}
