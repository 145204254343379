/* jshint esversion: 8 */
'use strict';

import '../../css/user-consent.scss';
import Utils from './utils.js';

const UCONSENT_COOKIE_PROPERTY = 'pmuconsent';

const cookieStorage = {
    getItem: (item) => {
        const cookies = document.cookie
            .split(';')
            .map(cookie => cookie.split('='))
            .reduce((acc, [key, value]) => ({ ...acc, [key.trim()]: value }), {});
        return cookies[item];
    },
    setItem: (item, value, exdays, path='/') => {
        let cookie = `${item}=${value};path=${path}`;
        if (exdays) {
            const date = new Date();
            date.setTime(date.getTime() + exdays * 86400000); // 86400000 = 24 (hours) * 60 (minutes) * 60 (seconds) * 1000 (milliseconds)
            cookie += `;expires=${date.toUTCString()}`;
        }
        document.cookie = cookie;
    }
};

export default class UserConsent {
    constructor() {
        const wrapper = Utils.addElement(document.body, 'div', 'consent-wrapper', 'user-consent');
        const content = Utils.addElement(wrapper, 'div');
        content.innerHTML = '<p>We use cookies to enhance your online experience and to remember your preferences. By continuing to browse our website or clicking "Accept All," you agree to our use of cookies in accordance with our <a href="/privacy">Privacy Policy</a> and <a href="/terms">Terms and Conditions</a>.</p>';
        const acceptBtn = Utils.addElement(content, 'button');
        acceptBtn.innerText = 'Accept All';
        acceptBtn.onclick = () => {
            cookieStorage.setItem(UCONSENT_COOKIE_PROPERTY, true, 180);
            wrapper.classList.add('hidden');
        }
        this.consentPopup = wrapper;
    }

    static showConsentPopup() {
        if (!cookieStorage.getItem(UCONSENT_COOKIE_PROPERTY)) {
            if (!this.instance) this.instance = new this();
            this.instance.consentPopup.classList.remove('hidden');
        }
    }
}
