/* jshint esversion: 8 */
'use strict';

import BasePage from '../../lib/page-base.js';

export default class HowToPlayMm extends BasePage {
    constructor(global) {
        super(global);
        this.pageName = 'Play Mega Millions';
    }

    initialize() {
        this.layout.main.classList.add('scheme-mm');
        this.initializeAds('iaad-articlepage-bgnone-1');
        this.initializeAds('dpad-staticpage-banner-1');
    }

    getContent() {
        return `<div class="static-root">
<div class="lottery-img">
    <img class="lottery-logo" src="../images/lottery-logos/mm-logo-640x120.png"></img>
</div>
<div>
    <div class="htp-label">RULES</div>
    <div>
        <li>Mega Millions® tickets cost $2.00 per play.  Megaplier® option costs additional $1.00 per play.</li>
        <li>Select five numbers from 1 to 70 for the white balls and one number from 1 to 25 for the gold Mega Ball.</li>
        <li>Choose your numbers on a play slip or let the lottery terminal randomly pick your numbers.</li>
        <li>The Mega Millions jackpot grows until it is won.</li>
        <li>The Mega Millions drawing is held at 11:00 PM Eastern Time (8:00 PM Pacific Time) on every Tuesday and Friday.</li>
    </div>
</div>
<br/>
<div class="dpad-staticpage-banner" id="iaad-articlepage-bgnone-1"></div>
<div>
    <div class="htp-label">PRIZE WITH MEGAPLIER</div>
    <div class="hscrollable">
        <table class="tabular tblmm">
            <tr class="upperth thmm">
                <th>MATCH</th>
                <th>2X</th>
                <th>3X</th>
                <th>4X</th>
                <th>5X</th>
            </tr>
            <tr class="lowerth">
                <th>Odds</th>
                <th>1 in 3</th>
                <th>1 in 25</th>
                <th>1 in 5</th>
                <th>1 in 15</th>
            </tr>
            <tr>
                <td>5 + 0</td>
                <td>$2M</td>
                <td>$3M</td>
                <td>$4M</td>
                <td>$5M</td>
            </tr>
            <tr>
                <td>4 + 1</td>
                <td>$20K</td>
                <td>$30K</td>
                <td>$40K</td>
                <td>$50K</td>
            </tr>
            <tr>
                <td>4 + 0</td>
                <td>$1,000</td>
                <td>$1,500</td>
                <td>$2,000</td>
                <td>$2,500</td>
            </tr>
            <tr>
                <td>3 + 1</td>
                <td>$400</td>
                <td>$600</td>
                <td>$800</td>
                <td>$1,000</td>
            </tr>
            <tr>
                <td>3 + 0</td>
                <td>$20</td>
                <td>$30</td>
                <td>$40</td>
                <td>$50</td>
            </tr>
            <tr>
                <td>2 + 1</td>
                <td>$20</td>
                <td>$30</td>
                <td>$40</td>
                <td>$50</td>
            </tr>
            <tr>
                <td>1 + 1</td>
                <td>$8</td>
                <td>$12</td>
                <td>$16</td>
                <td>$20</td>
            </tr>
            <tr>
                <td>0 + 1</td>
                <td>$4</td>
                <td>$6</td>
                <td>$8</td>
                <td>$10</td>
            </tr>
        </table>
    </div>
</div>
<br/>
<div class="dpad-staticpage-banner" id="dpad-staticpage-banner-1"></div>
<div>
    <div class="htp-label">MEGA MILLIONS PRIZE ODDS</div>
    <table class="tabular tblmm">
        <tr class="thmm">
            <th>MATCH</th>
            <th>PRIZE</th>
            <th>ODDS</th>
        </tr>
        <tr>
            <td>5 + 1</td>
            <td>Jackpot</td>
            <td>1 in 302,575,350</td>
        </tr>
        <tr>
            <td>5 + 0</td>
            <td>$1 Million</td>
            <td>1 in 12,607,306</td>
        </tr>
        <tr>
            <td>4 + 1</td>
            <td>$10,000</td>
            <td>1 in 931,001</td>
        </tr>
        <tr>
            <td>4 + 0</td>
            <td>$500</td>
            <td>1 in 38,792</td>
        </tr>
        <tr>
            <td>3 + 1</td>
            <td>$200</td>
            <td>1 in 14,547</td>
        </tr>
        <tr>
            <td>3 + 0</td>
            <td>$10</td>
            <td>1 in 606</td>
        </tr>
        <tr>
            <td>2 + 1</td>
            <td>$10</td>
            <td>1 in 693</td>
        </tr>
        <tr>
            <td>1 + 1</td>
            <td>$4</td>
            <td>1 in 98</td>
        </tr>
        <tr>
            <td>0 + 1</td>
            <td>$2</td>
            <td>1 in 37</td>
        </tr>
    </table>
    <div class="htp-notes">* Overall chances of winning any prize is 1 in 24.</div>
</div>
<div>
    <div class="htp-label">DISCLAIMER AND NOTES</div>
    <div>
        <li>Information contained herein is provided for informational purpose only.  This app makes no warranty or representation as to the accuracy or completeness of the Information.</li>
        <li>In California, all prizes are pari-mutuel, meaning payouts are based on sales and the number of winners. All other Mega Millions states set the 2nd through 9th prizes at pre-determined amounts.</li>
        <li>Trademarks and logos are the properties of their respective owners.</li>
    </div>
</div>
<br/><br/><br/><br/>
</div>`;
    }
}
