/* jshint esversion: 8 */
'use strict';

import BasePage from '../../lib/page-base.js';

export default class StatsInfoMm extends BasePage {
    constructor(global) {
        super(global);
        this.pageName = 'Mega Millions Stats';
    }

    initialize() {
        this.layout.main.classList.add('scheme-mm');
        this.initializeAds('iaad-articlepage-bgnone', 1);
    }

    getContent() {
        return `<div class="static-root">
<div class="lottery-img">
    <img src="../images/lottery-logos/mm-logo-640x120.png"></img>
</div>
<br/>
<div>
    <div class="htp-label">This Mega Millions statistic report includes the number matrix that was last changed on October 31, 2017. The winning numbers consist of five white balls chosen from 1 to 70 and one mega ball chosen from 1 to 25.</div>
</div>
<br/>
<div class="dpad-staticpage-banner" id="iaad-articlepage-bgnone-1"></div>
<div>
    <div class="htp-label">TERMS USED IN THIS REPORT:</div>
    <div>
        <li><b><u>Times Drawn</u></b> is the number of times a white ball number appears as one of the five winning numbers or the yellow ball number appears as the Mega Ball number.</li>
        <li><b><u>% of Drawings</u></b> is the percentage of drawing dates where the drawn number was one of the five winning numbers. It only applies to white ball numbers.</li>
        <li><b><u>% of Draws</u></b> is the percentage of times a number was drawn out of every drawn number. There are five white ball numbers per drawing.</li>
        <li>For white ball numbers, <b><u>% of Draws</u></b> is equal to <b><u>Times Drawn</b></u> divided by the total number of drawing dates and then divided by five as there are five white balls. Therefore, <b><u>% of Draws</u></b> is equal to five times the <b><u>% of Drawings</u></b>.</li>
        <li>For Mega Ball numbers, <b><u>% of Draws</u></b> is equal to <b><u>Times Drawn</b></u> divided by the total number of drawing dates. Therefore, <b><u>% of Draws</u></b> and <b><u>% of Drawings</u></b> are the same.</li>
    </div>
</div>
<br/><br/><br/><br/>
</div>`;
    }
}
