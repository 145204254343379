/* jshint esversion: 8 */
'use strict';

import BasePage from '../../lib/page-base.js';

const appVersion = '3.24.513';

export default class AppVersion extends BasePage {
    constructor(global) {
        super(global);
        this.pageName = 'Version';
    }

    getContent() {
        return `<div class="general-msg">
<div class="logos" id="applogo48x48"></div><br/><br/>
<h2>Application Version</h2><br/><br/>
<p>The current version is: <b>${appVersion}</b></p><br/>
<p>Please click on the menu items in the top navigation bar to go to other pages.</p><div>
</div>
</div>`;
    }
}
