/* jshint esversion: 8 */
'use strict';

import LotteryBase from './lottery-base.js';
import Utils from '../../lib/utils.js';
import '../../../css/generator.scss';

export class Generator extends LotteryBase {
    constructor(global) {
        super(global);
        this.pageName = 'Generator';
        this.ucol = global.firebase.firestore().collection(`${global.dbname}/members/users`);

        this.quickPick = QuickPick.getInstance(this);
        this.selfChoice = SelfChoice.getInstance(this);
        this.myFavorites = MyFavorites.getInstance(this);
    }

    getContent() {
        this.root = Utils.addElement(null, 'div', 'numbers-root');
        this.addLotteryTab(this.root, '/generator');
        const tabFrame = Utils.addElement(this.root, 'div', 'content-frame');

        // TODO: Peter Sam
        Utils.addElement(tabFrame, 'div', null, 'dpad-generator-left').innerHTML = this.insertAd('dpad-generator-left');
        const numGen = Utils.addElement(tabFrame, 'div', 'numgen-section');
        Utils.addElement(tabFrame, 'div', null, 'dpad-generator-right').innerHTML = this.insertAd('dpad-generator-right');

        this.actionTabs = this.addActionTabs(numGen).firstChild.children;
        this.tabContent = Utils.addElement(numGen, 'div', 'tab tabcontent', 'tabcontent');
        return this.root;
    }

    async initialize() {
        this.layout.selectMenuItem(this.layout.menus.numbers.menus.generator.item);

        const action = Utils.getQueryParam(window.location.search, 'act');
        const tabsBar = document.getElementById('tabs-bar');
        const tabBtns = tabsBar.firstChild.children;
        const selectTab = async (tab, btn) => {
            await tab.render(this.tabContent);
            tabsBar.tabGroup.tabSelected = btn;
            btn.classList.add('selected');
            btn.disabled = true;
            if (this.selectedTab) this.selectedTab.disabled = false;
            this.selectedTab = tab;
        }

        if (tabsBar.tabGroup.tabSelected) tabsBar.tabGroup.tabSelected.classList.remove('selected');

        if (action === 'sc') await selectTab(this.selfChoice, tabBtns[1]);
        else if (action === 'mf') await selectTab(this.myFavorites, tabBtns[2]);
        else await selectTab(this.quickPick, tabBtns[0]);
    }

    addActionTabs(parent) {
        const container = Utils.addElement(parent, 'div', null, 'tabs-bar');

        const switchTab = async params => {
            this.global.router.showLoader();
            if (params.me.selectedTab && params.me.selectedTab.backup) params.me.selectedTab.backup();
            const tabc = document.getElementById('tabcontent');
            Utils.clearNode(tabc, false);
            await params.tab.render(tabc);
            const qstring = `?type=${(params.me.isPowerball ? 'pb' : 'mm')}`;
            params.me.global.router.updateNavigationUrl('/generator', `${qstring}&act=${params.act}`);

            const tabsBar = document.getElementById('tabs-bar');
            const tabs = tabsBar.tabGroup.children;
            for (let i = 0; i < tabs.length; i++) tabs[i].disabled = false;
            if (tabsBar.tabGroup.tabSelected) tabsBar.tabGroup.tabSelected.disabled = true;
            params.me.selectedTab = params.tab;
            this.global.router.hideLoader();
        }

        const isAuthenticated = params => {
            if (this.auth.currentUser) {
                return true;
            } else {
                params.tab.parent.showSignInRequired();
                return false;
            }
        }

        Utils.createaTabs(container, [{
            label: 'Quick Pick',
            callback: switchTab,
            params: {tab: this.quickPick, me: this, act: 'qp'},
            default: true
        }, {
            label: 'Self Choice',
            callback: switchTab,
            params: {tab: this.selfChoice, me: this, act: 'sc'},
            default: false
        }, {
            label: 'My Favorites',
            precheck: isAuthenticated,
            callback: switchTab,
            params: {tab: this.myFavorites, me: this, act: 'mf'},
            default: false
        }]);

        return container;
    }
}

/**
 * An internal class for Quick Pick tab. It is used by class Numbers only
 * parent (object): the instance of Generator class
 */
class ActionTabBase {
    constructor(parent) {
        this.parent = parent;
        this.tabData = {
            mm: { numbers: [] },
            pb: { numbers: [] }
        }
    }

    static getInstance(parent) {
        if (!this.instance) this.instance = new this(parent);
        return this.instance;
    }

    render(parentContent) {
        this.initializeLotteryType();
        this.cblock = Utils.addElement(parentContent, 'div', 'content-block');
        return this.cblock;
    }

    initializeLotteryType() {
        this.parent.getLotteryType();
        [this.maxRegular, this.maxColored] = this.parent.isPowerball ? [69, 26] : [70, 25];
    }

    renderNumbersTable(cblock, isCheckbox = true) {
        const table = Utils.addElement(cblock, 'table');
        table.parent = cblock;
        table.addOneRow = (numbers, mplier = false, dplay = false, checked = false) => {
            const tr = Utils.addElement(table, 'tr');
            let td = Utils.addElement(tr, 'td');
            tr.checkbox = isCheckbox ? Utils.addCheckbox(td) : Utils.addRadioButton(td);
            tr.checkbox.checked = checked;
            tr.checkbox.onclick = event => {
                isCheckbox ? this.refreshControls(cblock) : this.selectNumbers(event);
            }
            td = Utils.addElement(tr, 'td');

            const addOneBall = (number, cls) => Utils.addElement(td, 'div', cls).innerText = number === '00' ? '' : number;
            for (let i = 0; i < numbers.length - 1; i++) addOneBall(numbers[i], 'ball large circle white');
            const colorCls = this.parent.isPowerball ? 'ball large circle red' : 'ball large circle yellow';
            addOneBall(numbers[5], colorCls);

            td = Utils.addElement(tr, 'td');
            td.title = this.parent.isPowerball ? 'Power Play' : 'Megaplier';
            Utils.addCheckbox(td, 'checkbox largebox').checked = mplier;
            if (this.parent.isPowerball) {
                td = Utils.addElement(tr, 'td');
                td.title = 'Double Play';
                Utils.addCheckbox(td, 'checkbox largebox').checked = dplay;
            }
        }
        return table;
    }

    /**
     * the return data structure is:
     * [{
     *     selected: true | false,
     *     tnum: ['01', '08', '40', '50', '60', '18'],
     *     mplier: true | false,
     *     dplay: true | false | undefined
     * }]
     */
    readSelectedTableRows(toInteger, checkboxFlag, selectedOnly = true) {
        const result = [];
        const rows = this.cblock.table.rows;
        for (let i = 0; i < rows.length; i++) {
            if (!rows[i].sepflag) {
                if ((selectedOnly && rows[i].firstChild.firstChild.firstChild.checked) || selectedOnly === false) {
                    result.push(this.readOneTableRow(rows[i], toInteger, checkboxFlag));
                }
            }
        }
        return result;
    }

    readOneTableRow(row, toInteger = false, checkboxFlag = true) {
        const data = {};
        if (checkboxFlag) data.selected = row.firstChild.firstChild.firstChild.checked;
        data.tnum = [];
        const balls = row.firstChild.nextSibling.children;
        for (let i = 0; i < balls.length; i++) data.tnum.push(balls[i].innerText);
        if (toInteger) data.tnum = data.tnum.map(Number);
        const mplier = row.firstChild.nextSibling.nextSibling;
        data.mplier = mplier.firstChild.firstChild.checked;
        if (mplier.nextSibling) data.dplay = mplier.nextSibling.firstChild.firstChild.checked;
        return data;
    }

    getNumberIdList(numbers) {
        const result = [];
        numbers.forEach(number => result.push(number.tnum.join('-')));
        return result;
    }

    refreshControls(block) {
        let checkbox = block.getElementsByClassName('controls');
        if (checkbox && checkbox.length === 1) checkbox = checkbox[0].firstChild.firstChild;
        const actionFrame = block.getElementsByClassName('cta-frame');
        if (actionFrame && actionFrame.length === 1) {
            const buttons = actionFrame[0].querySelectorAll('button');
            const rows = block.table.rows;
            const enableButtons = (enable = true) => {
                if (checkbox) {
                    enable || rows.length > 0 ? checkbox.removeAttribute('disabled') : checkbox.disabled = true;
                }
                buttons.forEach(button => button.alwaysEnable ? button.disabled = false : button.disabled = !enable);
            }

            if (rows.length > 0) {
                let numChecked = 0, numSeparators = 0;
                for (let i = 0; i < rows.length; i++) {
                    if (rows[i].checkbox && rows[i].checkbox.checked) numChecked++;
                    else if (rows[i].sepflag) numSeparators++;
                };
                if (numChecked === 0) {
                    enableButtons(false);
                    block.checkbox.checked = false;
                } else {
                    enableButtons();
                    const checkmark = rows.length === (numChecked + numSeparators) ? '✔\ufe0e' : '\u2014';
                    block.checkbox.nextSibling.setAttribute('content-value', checkmark);
                    block.checkbox.checked = true;
                }
            } else {
                enableButtons(false);
            }
        }
    }

    selectNumbers(event) {
        const numbers = [];
        const tds = event.target.closest('tr').children;
        const balls = tds[1].children;
        for (let i = 0; i < balls.length; i++) numbers.push(balls[i].innerText);
        const mplier = tds[2].firstChild.firstChild.checked;
        const dplay = tds.length === 4 ? tds[2].firstChild.firstChild.checked : undefined;
        const cevent = new CustomEvent('myFavoriteNumbersEvent', {detail: {numbers: numbers, mplier: mplier, dplay: dplay}});
        document.dispatchEvent(cevent);
    }

    clickMainCheckbox(block) {
        const rows = block.table.rows;
        const checked = block.checkbox.checked;
        if (checked) block.checkbox.nextSibling.setAttribute('content-value', '✔\ufe0e');
        for (let i = 0; i < rows.length; i++) {
            if (!rows[i].sepflag) rows[i].checkbox.checked = checked;
        }
        this.refreshControls(block);
    }

    deleteSelectedRows(block) {
        const rows = block.table.rows;
        for (let i = rows.length - 1; i >= 0; i--) {
            if (!rows[i].sepflag && rows[i].checkbox.checked) Utils.clearNode(rows[i], true);
        }
        this.refreshControls(block);
    }

    addActionButton(ctaFrame, label, func, me, hoverText, confirmText) {
        const btn = Utils.addElement(ctaFrame, 'button', 'regular');
        btn.innerText = label;
        if (hoverText) btn.title = hoverText;
        if (confirmText) {
            btn.setAttribute('tipnotes', confirmText);
            btn.confirm = () => {
                btn.classList.add('confirm');
                setTimeout(() => {
                    btn.classList.remove('confirm');
                }, 3000);
            }
        }
        btn.onclick = () => func(me, btn);
        return btn;
    }

    addMplierHeader(ctaFrame, className) {
        const addMplierLabel = (clsName, lable, tip) => {
            const div = Utils.addElement(ctaFrame, 'div', clsName);
            div.innerText = lable;
            div.setAttribute('tipnotes', tip);
        }
        if (this.parent.isPowerball) {
            addMplierLabel(`tooltip ${className}`, 'PP', 'This column is the POWER PLAY indicator');
            addMplierLabel('tooltip lmargin2', 'DP', 'This column is the DOUBLE PLAY indicator');
        } else {
            addMplierLabel(`tooltip ${className}`, 'MP', 'This column is the MEGAPLIER indicator');
        }
    }

    getTabData() {
        return this.parent.getLotteryType() === 'powerball' ? this.tabData.pb : this.tabData.mm;
    }

    copyToClipboard(me, btn) {
        let result = '';
        const numbers = me.readSelectedTableRows(false, false, true);
        numbers.forEach(number => result += `${number.tnum.slice(0, 5).join('-')} + ${number.tnum[5]}\n`);
        navigator.clipboard.writeText(result).then(() => {
            if (btn) btn.confirm();
        });
    }
}

/**
 * An internal class for Quick Pick tab. It is used by class Numbers only
 */
export class QuickPick extends ActionTabBase {
    constructor(parent) {
        super(parent);
    }

    render(parentContent) {
        const cblock = super.render(parentContent);
        const actionBar = Utils.addElement(cblock, 'div', 'action-bar');

        const addRandomNumbers = num => {
            const btn = Utils.addElement(actionBar, 'button', 'regular');
            btn.innerText = num;
            btn.onclick = () => {
                for (let i = 0; i < num; i++) {
                    const numbers = Utils.generateRandomNumbers(this.maxRegular, this.maxColored);
                    cblock.table.addOneRow(numbers, this.mplier.checked, this.dplay ? this.dplay.checked : false);
                }
                this.refreshControls(cblock);
            }
        }
        addRandomNumbers(1);
        addRandomNumbers(5);
        addRandomNumbers(10);

        const addToggle = tip => {
            const input = Utils.addToggleStatus(actionBar, 'toggle-status tooltip');
            input.closest('.tooltip').setAttribute('tipnotes', tip);
            return input;
        }
        if (this.parent.isPowerball) {
            this.mplier = addToggle('Power Play');
            this.dplay = addToggle('Double Play');
        } else {
            this.mplier = addToggle('Megaplier');
            this.dplay = undefined;
        }

        cblock.controls = Utils.addElement(cblock, 'div', 'controls');
        cblock.checkbox = Utils.addCheckbox(cblock.controls);
        cblock.checkbox.onclick = () => this.clickMainCheckbox(cblock);
        cblock.ctas = Utils.addElement(cblock.controls, 'div', 'cta-frame');

        this.addActionButton(cblock.ctas, 'Favorite', this.favorite, this, 'This button adds the selected rows to your account and stores them for future use.');
        this.addActionButton(cblock.ctas, 'Remove', this.remove, this, 'This buttons removes selected rows from the list.');
        this.addActionButton(cblock.ctas, 'Copy', this.copyToClipboard, this, 'This button copies selected rows into clipboard.', 'The selected numbers have been copied into clipboard.');
        this.addMplierHeader(cblock.ctas, 'lmargin-qp');

        cblock.table = this.renderNumbersTable(cblock);
        this.restore();
        this.refreshControls(cblock);
    }

    favorite(me) {
        if (me.parent.auth.currentUser) {
            me.parent.myFavorites.addNumbers(me.readSelectedTableRows(false, false, true));
            me.parent.actionTabs[2].click();
        } else {
            me.parent.showAlertMessage('Please sign in to enable the favoriting numbers feature.');
        }
    }

    remove(me) {
        me.deleteSelectedRows(me.cblock);
        if (me.cblock.table.rows.length === 0) {
            let checkbox = me.cblock.getElementsByClassName('controls');
            if (checkbox && checkbox.length === 1) checkbox = checkbox[0].firstChild.firstChild;
            if (checkbox) checkbox.checked = false;
            me.refreshControls(me.cblock);
        }
    }

    backup() {
        const ltype = this.getTabData();
        ltype.mplier = this.mplier.checked;
        ltype.dplay = this.dplay ? this.dplay.checked : undefined;
        ltype.numbers = this.readSelectedTableRows(false, true, false);
    }

    restore() {
        if (this.tabData) {
            const ltype = this.getTabData();
            if (ltype.mplier) this.mplier.checked = ltype.mplier;
            if (ltype.dplay) this.dplay.checked = ltype.dplay;
            if (ltype.numbers.length > 0) ltype.numbers.forEach(record => this.cblock.table.addOneRow(record.tnum, record.mplier, record.dplay, record.selected));
        }
    }
}

/**
 * An internal class for Self Choice tab. It is used by class Numbers only
 */
export class SelfChoice extends ActionTabBase {
    constructor(parent) {
        super(parent);
    }

    render(parentContent) {
        this.cleanup();
        const cblock = super.render(parentContent);

        let divbox = Utils.addElement(cblock, 'div', 'section');
        const addOneBall = (cls, num) => {
            const bframe = Utils.addElement(divbox, 'div', 'ballframe');
            const ball = Utils.addElement(bframe, 'div', cls);
            if (num) ball.innerText = num.toString().padStart(2, '0');
            return bframe;
        }

        for (let i = 0; i < 5; i++) this.addToTopList(addOneBall('ball large circle white'));
        const color = this.parent.isPowerball ? 'red' : 'yellow';
        this.addToTopList(addOneBall(`ball large circle ${color}`));
        this.selectTopBall(0);

        divbox = Utils.addElement(cblock, 'div', 'section');
        const addMplier = label => {
            Utils.addElement(divbox, 'label', 'mplier wrap-over fs-pt85').innerText = label;
            return Utils.addToggleStatus(divbox);
        }
        if (this.parent.isPowerball) {
            this.mplier = addMplier('POWER PLAY');
            this.dplay = addMplier('DOUBLE PLAY');
        } else {
            this.mplier = addMplier('MEGAPLIER');
            this.dplay = undefined;
        }

        divbox = Utils.addElement(cblock, 'div', 'section', 'action-buttons');
        this.addActionButton(divbox, 'Auto Fill', this.autoFill, this, 'This button automatically and randomly populates the numbers at the top.');
        this.favorBtn = this.addActionButton(divbox, 'Favorite', this.favorite, this, 'This button adds the selected rows to your account and stores them for future use.');
        this.copyBtn = this.addActionButton(divbox, 'Copy', this.copy, this, 'This button copies your selected numbers into clipboard.', 'The selected numbers have been copied into clipboard.');
        this.addActionButton(divbox, 'Reset', this.reset, this, 'This button clears the selected numbers at the top, preparing for a fresh start.');
        this.selectBtn = this.addActionButton(divbox, 'Select', this.select, this, 'This button takes the selected number at the top and populates it back to the previous screen.');
        this.selectBtn.style.display = 'none';

        const addOneNumber = (cls, num, isColorBall) => {
            const ball = addOneBall(cls, num);
            ball.me = this;
            const assign = () => {
                this.assignValue(ball, isColorBall);
                this.refreshActionButtons();
            }
            ball.onclick = event => {
                const me = event.target.closest('.ballframe').me;
                if (isColorBall) {
                    const cnum = me.topBalls[5].firstChild.innerText;
                    if (!cnum || parseInt(cnum) !== num) assign();
                } else {
                    const topNumbers = [];
                    for (let i = 0; i < 5; i++) {
                        const selnum = me.topBalls[i].firstChild.innerText;
                        if (selnum !== '') topNumbers.push(parseInt(selnum));
                    }
                    if (topNumbers.includes(num) && me.focused.index !== 5)
                        this.parent.showAlertMessage('The number you chose has already been selected. Please choose a different number.');
                    else
                        assign();
                }
            }
        }
        divbox = Utils.addElement(cblock, 'div', 'section left', 'white-choices');
        for (let i = 0; i < this.maxRegular; i++) addOneNumber('ball large circle white', i + 1, false);
        divbox = Utils.addElement(cblock, 'div', 'section left', 'color-choices');
        for (let i = 0; i < this.maxColored; i++) addOneNumber(`ball large circle ${color}`, i + 1, true);
        this.restore();
        this.refreshActionButtons();
    }

    autoFill(me) {
        const numbers = Utils.generateRandomNumbers(me.maxRegular, me.maxColored);
        const topNumbers = [];
        const getNextNumber = (newIndex, numIndex, next = true) => {
            if (!topNumbers.includes(numbers[numIndex])) {
                topNumbers[newIndex] = numbers[numIndex];
            } else {
                if (numIndex >= 4) next = false;
                else if (numIndex <= 0) next = true;
                numIndex = next ? ++numIndex : --numIndex;
                getNextNumber(newIndex, numIndex + 1);
            }
        }

        for (let i = 0; i < 5; i++) {
            const selnum = me.topBalls[i].firstChild.innerText;
            if (selnum !== '') topNumbers[i] = selnum;
        }
        for (let i = 0; i < 5; i++) {
            if (!topNumbers[i]) getNextNumber(i, i);
        }
        topNumbers.sort();

        for (let i = 0; i < 5; i++) me.topBalls[i].firstChild.innerText = topNumbers[i];
        if (me.topBalls[5].firstChild.innerText === '') me.topBalls[5].firstChild.innerText = numbers[5];

        me.reset(me, false);
    }

    favorite(me) {
        if (me.parent.auth.currentUser) {
            const numbers = me.getTopBallNumbers(true);
            me.parent.myFavorites.addNumbers([{tnum: numbers, mplier: me.mplier.checked, dplay: (me.dplay ? me.dplay.checked : false)}]);
            me.parent.actionTabs[2].click();
        } else {
            me.parent.showAlertMessage('Please sign in to enable the favoriting numbers feature.');
        }
    }

    copy(me, btn) {
        let result = '';
        const balls = me.cblock.firstChild.children;
        for (let i = 0; i < 5; i++) {
            result += balls[i].firstChild.innerText;
            if (i < 4) result += '-';
        }
        result += ` + ${balls[5].firstChild.innerText}`;
        navigator.clipboard.writeText(result).then(() => {
            if (btn) btn.confirm();
        });
    }

    reset(me, includingTopNums = true) {
        if (includingTopNums) {
            for (let i = 0; i < 6; i++) {
                const ball = me.topBalls[i];
                i === 0 ? ball.classList.add('selected-blue') : ball.classList.remove('selected-blue');
                ball.firstChild.innerText = '';
            }
        } else {
            if (me.focused) me.topBalls[me.focused.index].classList.remove('selected-blue');
            me.topBalls[0].classList.add('selected-blue');
        }
        me.focused = me.topBalls[0];
        me.syncBalls();
        me.refreshActionButtons();
    }

    select(me) {
        const numbers = [];
        for (let i = 0; i < 6; i++) numbers.push(me.topBalls[i].firstChild.innerText);
        const cevent = new CustomEvent('selfPickNumbersEvent', {detail: {numbers: numbers, mplier: me.mplier, dplay: me.dplay}});
        document.dispatchEvent(cevent);
    }

    selectTopBall(position, me = this) {
        if (me.focused) me.focused.classList.remove('selected-blue');
        if (me.topBalls.length > position) {
            me.topBalls[position].classList.add('selected-blue');
            me.focused = me.topBalls[position];
        } else {
            me.focused = undefined;
        }
    }

    assignValue(ball, isColorBall = false) {
        if (!this.focused) {
            this.parent.showAlertMessage('Please select a particular ball at the top that you want to set the number before selecting the number.');
        } else if (isColorBall && this.focused.index !== 5) {
            this.parent.showAlertMessage('Please select the corresponding color ball at the top before selecting the number.');
        } else if (!isColorBall && this.focused.index === 5) {
            this.parent.showAlertMessage('Please select the corresponding white ball at the top before selecting the number.');
        } else {
            this.focused.firstChild.innerText = ball.firstChild.innerText;
            ball.classList.add('selected-blue');
            const nextBall = this.topBalls[this.focused.index + 1];
            if (nextBall && !nextBall.firstChild.innerText) this.selectTopBall(this.focused.index + 1);
            this.sortTopNumbers();
            this.syncBalls();
        }
    }

    syncBalls() {
        if (!this.whiteBalls) this.whiteBalls = document.getElementById('white-choices').children;
        if (!this.colorBalls) this.colorBalls = document.getElementById('color-choices').children;
        const topWhiteNumbers = this.getTopBallNumbers();
        const topColorNumber = [this.topBalls[5].firstChild.innerText];

        const sync = (choices, numbers) => {
            for (let i = 0; i < choices.length; i++) {
                const ball = choices[i];
                numbers.includes(ball.firstChild.innerText) ? ball.classList.add('selected-blue') : ball.classList.remove('selected-blue');
            }
        }
        sync(this.whiteBalls, topWhiteNumbers);
        sync(this.colorBalls, topColorNumber);
    }

    getTopBallNumbers(includeColorBall = false) {
        const numbers = [];
        for (let i = 0; i < 5; i++) numbers.push(this.topBalls[i].firstChild.innerText);
        if (includeColorBall) numbers.push(this.topBalls[5].firstChild.innerText);
        return numbers;
    }

    addToTopList(ball, me = this) {
        me.topBalls.push(ball);
        ball.index = me.topBalls.length - 1;
        ball.onclick = () => this.selectTopBall(ball.index, me);
    }

    sortTopNumbers() {
        const numbers = [];
        for (let i = 0; i < 5; i++) {
            const number = this.topBalls[i].firstChild.innerText;
            if (number) numbers.push(number);
        }
        if (numbers.length === 5) {
            numbers.sort();
            for (let i = 0; i < 5; i++) this.topBalls[i].firstChild.innerText = numbers[i];
        }
    }

    refreshActionButtons() {
        let numFilled = 0;
        this.topBalls.forEach(ball => {
            if (ball.firstChild.innerText) numFilled++;
        })

        const buttons = this.cblock.firstChild.nextSibling.nextSibling.children;
        const enableButtons = (enableAutoFill, enableFavorite, enableCopy, enableReset, enableSelect) => {
            const enableButton = (button, enable) => enable ? button.removeAttribute('disabled') : button.disabled = true;
            enableButton(buttons[0], enableAutoFill);
            enableButton(buttons[1], enableFavorite);
            enableButton(buttons[2], enableCopy);
            enableButton(buttons[3], enableReset);
            enableButton(buttons[4], enableSelect);
        }

        if (numFilled === 0) enableButtons(true, false, false, false, false);
        else if (numFilled === 6) enableButtons(false, true, true, true, true);
        else enableButtons(true, false, false, true, false);
    }

    cleanup() {
        // TODO: Peter, check the following, should it be this.mplier.checked = false?
        this.topBalls = [];
        this.mplier = false;
        this.dplay = false;
        this.whiteBalls = undefined;
        this.colorBalls = undefined;
    }

    backup() {
        const ltype = this.getTabData();
        ltype.mplier = this.mplier.checked;
        ltype.dplay = this.dplay ? this.dplay.checked : undefined;
        ltype.numbers.length = 0;
        const balls = this.cblock.firstChild.children;
        for (let i = 0; i < balls.length; i++) ltype.numbers.push(balls[i].firstChild.innerText);
    }

    restore() {
        if (this.tabData) {
            const ltype = this.getTabData();
            if (ltype.mplier) this.mplier.checked = ltype.mplier;
            if (ltype.dplay) this.dplay.checked = ltype.dplay;
            if (ltype.numbers) {
                const balls = this.cblock.firstChild.children;
                for (let i = 0; i < balls.length; i++) {
                    const numStr = ltype.numbers[i];
                    if (numStr) balls[i].firstChild.innerText = numStr;
                }
            }
        }
    }
}

/**
 * An internal class for My Favorite tab. It is used by class Numbers only
 */
export class MyFavorites extends ActionTabBase {
    constructor(parent) {
        super(parent);
        this.changed = false;
    }

    async render(parentContent) {
        const cblock = super.render(parentContent);

        cblock.controls = Utils.addElement(cblock, 'div', 'controls notoppad');
        cblock.checkbox = Utils.addCheckbox(cblock.controls, 'checkbox');
        cblock.checkbox.onclick = () => this.clickMainCheckbox(cblock);
        cblock.ctas = Utils.addElement(cblock.controls, 'div', 'cta-frame');
        const saveBtn = this.addActionButton(cblock.ctas, 'Save', this.saveIntoDatabase, this, 'This button saves selected rows into your account and deleted unselected ones that are currently in your account.');
        saveBtn.alwaysEnable = true;
        this.addActionButton(cblock.ctas, 'Copy', this.copyToClipboard, this, 'This button copies selected rows into clipboard.', 'The selected numbers have been copied into clipboard.');
        this.addMplierHeader(cblock.ctas, 'lmargin-mf');

        cblock.footnotes = Utils.addElement(cblock, 'div', 'footnotes');
        cblock.footnotes.innerHTML = '<b><i>Note</i></b>: The ones highlighted in <div class="red">&nbsp;</div> duplicate with the ones<br/>' +
            '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;highlighted in <div class="green">&nbsp;</div>.&nbsp;' +
            'You should remove the <div class="red">&nbsp;</div> ones.';

        cblock.table = this.renderNumbersTable(cblock);
        await this.restore();
        this.refreshControls(cblock);
    }

    async readFromDatabase() {
        const user = this.parent.auth.currentUser;
        if (user) {
            const favNumRef = this.parent.ucol.doc(user.uid).collection('fav-nums').doc(this.parent.type);
            return favNumRef.get().then(docSnapshot => {
                const data = docSnapshot.data();
                return data ? data.numbers : null;
            });
        } else {
            return this.parent.showSignInRequired();
        }
    }

    saveIntoDatabase(me) {
        const selectedRows = me.readSelectedTableRows(true, false, true);
        let msg = 'You are about to save the selected numbers to your account. Please be aware that any unselected numbers currently in your account will be deleted. Please double-check before proceeding with the save.';
        if (selectedRows.length === 0) msg += '<br/><br/><b>Warning!</b> <i>Please note that you haven\'t selected any numbers yet, which means you will delete all your previously saved favorite numbers. Click the "Cancel" button if this is not your intention.</i><br/><br/>';

        const msgbox = this.parent.showAlertMessage(msg);
        msgbox.title.innerText = 'Save Favorite Numbers';
        msgbox.button1.innerText = 'Cancel';
        msgbox.button1.onclick = msgbox.cleanup;
        msgbox.button2.innerText = 'Save';
        msgbox.button2.onclick = event => {
            const user = me.parent.auth.currentUser;
            const favNumRef = me.parent.ucol.doc(user.uid).collection('fav-nums').doc(me.parent.type);
            const numbers = selectedRows;
            me.changed = false;
            favNumRef.set({numbers: numbers}, {merge: true}).then(() => {
                Utils.clearNode(me.cblock.table, false);
                const ltype = me.getTabData();
                ltype.numbers = [];
                ltype.records = [];
                me.restore();
                msgbox.cleanup(event);
            });
        }
    }

    addNumbers(numberList) {
        numberList.forEach(record => this.getTabData().numbers.push(record));
    }

    remove(me) {
        me.deleteSelectedRows(me.cblock);
        me.changed = true;
        const rows = me.cblock.table.rows;
        for (let i = rows.length - 1; i >= 0; i--) {
            if (rows[i].sepflag) {
                if (i === rows.length - 1 || (rows[i + 1] && rows[i + 1].sepflag)) Utils.clearNode(rows[i], true);
            }
        }
        me.refreshDuplicates();
    }

    backup() {
        const rows = this.cblock.table.rows;
        const readRowsInSection = (begin, end) => {
            const numbers = [];
            let start = false;
            for (let i = 0; i < rows.length; i++) {
                if (start) {
                    if (rows[i].sepflag && rows[i].sepflag === end) break;
                    numbers.push(this.readOneTableRow(rows[i]));
                } else if (rows[i].sepflag && rows[i].sepflag === begin) {
                    start = true;
                }
            }
            return numbers;
        }

        const ltype = this.getTabData();
        ltype.numbers = readRowsInSection('new', 'old');
        ltype.records = readRowsInSection('old');
    }

    async restore() {
        const numberToString = numArray => {
            numArray.forEach((item, index) => numArray[index] = item.toString().padStart(2, '0'));
            return numArray;
        }

        const addSeparator = (text, flag) => {
            const tr = Utils.addElement(this.cblock.table, 'tr');
            tr.sepflag = flag;
            const td = Utils.addElement(tr, 'td', 'separator');
            td.colSpan = this.parent.isPowerball ? '4' : '3';
            td.innerText = text;
        }

        const addRows = (numbers, separatorText, section) => {
            if (numbers && numbers.length > 0) {
                addSeparator(separatorText, section);
                numbers.forEach(record => this.cblock.table.addOneRow(numberToString(record.tnum), record.mplier, record.dplay, record.selected));
            }
        }

        const ltype = this.getTabData();
        addRows(ltype.numbers, 'Newly generated numbers that haven\'t been saved', 'new');

        return (this.changed ? Promise.resolve(ltype.records) : this.readFromDatabase()).then(dbRecords => {
            addRows(dbRecords, 'The numbers previously stored in your account', 'old');
            this.refreshDuplicates();
        });
    }

    findDuplicates(array) {
        const duplicates = [];

        for (let i = array.length - 1; i > 0; i--) {
            let firstTime = true;
            for (let j = i - 1; j >= 0; j--) {
                if (array[i] === array[j]) {
                    if (firstTime) {
                        duplicates.push({idx: i, cls: 'duped'});
                        firstTime = false;
                    }
                    duplicates.push({idx: j, cls: 'dupof'});
                }
            }
        }

        return duplicates;
    }

    refreshDuplicates() {
        const numids = [];
        const queuing = (array, sepflag) => {
            if (array.length > 0) {
                numids.push(sepflag);
                array.forEach(record => numids.push(record.tnum.join('-')));
            }
        }
        const ltype = this.getTabData();

        this.backup();
        queuing(ltype.numbers, 'sep1');
        queuing(ltype.records, 'sep2');

        const rows = this.cblock.table.rows;
        for (let i = 0; i < rows.length; i++) {
            if (!rows[i].sepflag) rows[i].firstChild.nextSibling.classList.remove('duped', 'dupof');
        }

        if (this.cblock.footnotes) {
            const duplicates = this.findDuplicates(numids);
            if (duplicates.length > 0) {
                duplicates.reverse().forEach(record => rows[record.idx].firstChild.nextSibling.classList.add(record.cls));
                this.cblock.footnotes.style.display = 'block';
            } else {
                this.cblock.footnotes.style.display = 'none';
            }
        }
    }
}
