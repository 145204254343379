/**
 *
 * Supported Opt-In and Opt-Out URLs:
 *
 * 1) http://stage.powermega.net/optnews
 * 2) http://stage.powermega.net/optnews?optin=true
 * 3) http://stage.powermega.net/optnews?optin=false
 * 4) http://stage.powermega.net/optnews?ee=cHMwMi5xYUBnbWFpbC5jb20-
 * 5) http://stage.powermega.net/optnews?ee=cHMwMi5xYUBnbWFpbC5jb20-&optin=true
 * 6) http://stage.powermega.net/optnews?ee=cHMwMi5xYUBnbWFpbC5jb20-&optin=false
 *
 * The only case that involes Firebase Functions call optInOutEmail is #6 when user is not signed in
 *
 */

/* jshint esversion: 8 */
'use strict';

import UserAdminBase from './user-base.js';
import Utils from '../../lib/utils.js';

export default class NewsLetters extends UserAdminBase {
    constructor(global) {
        super(global);
        this.pageName = 'News Letters';
    }

    loadContentSection() {
        const scrambleEmail = email => email.replace(/^(\w{1}|)[\w.-]*(\w{1})+@(\w{1})[\w.-]*(\w{1})+\.(\w*)$/, '$1*****$2@$3*****$4.$5');

        this.encodedEmail = Utils.getQueryParam(this.global.qstring, 'ee');
        if (!this.encodedEmail && !this.auth.currentUser) {
            this.renderInvalidRequest();
        } else {
            this.email = this.encodedEmail ? this.decodeEmail(this.encodedEmail) : this.auth.currentUser.email;
            const optin = Utils.getQueryParam(this.global.qstring, 'optin');
            this.optin = optin === 'false' ? false : true;
            this.dispEmail = this.auth.currentUser ? this.email : scrambleEmail(this.email);
            this.optin ? this.renderOptIn() : this.renderOptOut();
        }
    }

    async initialize() {
        if (this.auth.currentUser) {
            if (this.validateEmail(this.email)) {
                await this.updatePowermegaDatabase().then(status => {
                    if (status === 'unchanged') {
                        const errorMsg = this.optin ?
                            `<b>Note:</b> Your request to opt-in to the newsletter has been ignored as the email address <b>${this.dispEmail}</b> has already been opted-in.\n\nIf you would like to opt-out, click on the avatar icon at the upper-right corner, select "Manage Account", and then choose "Unsubscribe Emails".` :
                            `<b>Note:</b> Your request to opt-out to the newsletter has been ignored as the email address <b>${this.dispEmail}</b> hasn't been opted-in.\n\nIf you would like to opt-in, click on the avatar icon at the upper-right corner, select "Manage Account", and then choose "Subscribe Emails".`;
                        this.displayMessage(false, errorMsg);
                    } else {
                        this.displayMessage(true);
                    }
                }).catch(error => {
                    const errorMsg = `<b>Error:</b> Failed to update email opt-in or opt-out status to the account <b>${this.dispEmail}</b>`;
                    this.displayMessage(false, errorMsg);
                })
            } else {
                const errorMsg = `<b>Error:</b> Invalid email address <b>${this.dispEmail}</b>`;
                this.displayMessage(false, errorMsg);
            }
        } else if (!this.encodedEmail && !this.auth.currentUser) {
            const errorMsg = '<b>Error:</b> Failed to process email opt-in or opt-out request';
            this.displayMessage(false, errorMsg);
        } else {
            // annoymous tries to opt-out, if annoymous requests to opt-in, he/she will be redirected to /authenticate page first from router.js
            const optOutFunc = this.global.router.functions.httpsCallable('optOutEmails');
            await optOutFunc({email: this.encodedEmail}).then(result => {
                if (result.data.code === 'succeeded') {
                    this.displayMessage(true);
                } else if (result.data.code === 'warning') {
                    const errorMsg = `<b>Note:</b> ${result.data.msg}`;
                    this.displayMessage(false, errorMsg);
                } else {
                    const errorMsg = `<b>Error:</b> ${result.data.msg}`;
                    this.displayMessage(false, errorMsg);
                }
            }).catch(error => {
                const errorMsg = `<b>Error:</b> Failed to opt-out email address <b>${this.dispEmail}</b>, code = "${error.code}"`;
                this.displayMessage(false, errorMsg);
            })
        }
    }

    renderOptOut() {
        Utils.addElement(this.framebox, 'div', 'title').innerText = 'Opt-Out Confirmation';
        const section = Utils.addElement(this.framebox, 'div', 'section');
        const content = Utils.addElement(section, 'div', 'content');
        Utils.addElement(content, 'p', null, 'error-msg');
        Utils.addElement(section, 'div', 'content').innerHTML = `<p id="response-msg" hidden>We won't send any more emails like this to your address: <b>${this.dispEmail}</b></p>`;
        Utils.addElement(section, 'div', 'content').innerHTML = `<p>We're sorry to see you go.  To opt-in or opt-out for Powermega email notifictions, you can always go to <a href="${this.global.webHomeUrl}/optnews">${this.global.webHomeUrl}/optnews</a>, sign in, and then select "Subscribe Emails" or "Unsubscribe Emails" button at the bottom of the screen.</p>`;
    }

    renderOptIn() {
        Utils.addElement(this.framebox, 'div', 'title').innerText = 'Opt-In Confirmation';
        let section = Utils.addElement(this.framebox, 'div', 'section');
        const content = Utils.addElement(section, 'div', 'content');
        Utils.addElement(content, 'p', null, 'error-msg');
        Utils.addElement(section, 'div', 'content').innerHTML = `<p id="response-msg" hidden>Thank you for subscribing the email notifications.  We will send Mega Millions and Powerball related news to your email address: <b>${this.dispEmail}</b></p>`;
        Utils.addElement(section, 'div', 'content', 'msg').innerHTML = `<p>To opt-in or opt-out for Powermega email notifictions, you can always go to <a href="${this.global.webHomeUrl}/optnews">${this.global.webHomeUrl}/optnews</a>, sign in, and then select "Subscribe Emails" or "Unsubscribe Emails" button at the bottom of the screen.</p>`;
    }

    renderInvalidRequest() {
        Utils.addElement(this.framebox, 'div', 'title').innerText = 'Invalid Request';
        const section = Utils.addElement(this.framebox, 'div', 'section');
        const content = Utils.addElement(section, 'div', 'content');
        Utils.addElement(content, 'p', null, 'error-msg');
        Utils.addElement(section, 'div', 'content').innerHTML = '<p id="response-msg" hidden>Failed to retrieve the proper parameters from your request.</p>';
        Utils.addElement(section, 'div', 'content').innerHTML = '<p>To opt-in or opt-out for Powermega email notifications, you need to sign in to your account or provide the required parameters in the request URL.<br/><br/>After signing in, click on the avatar in the upper-right corner, select "Manage Account", and then choose the "Subscribe Emails" or "Unsubscribe Emails" button at the bottom of the screen.</p>';
    }

    displayMessage(noerror = false, errorMsg) {
        const emsg = document.getElementById('error-msg');
        const rsmg = document.getElementById('response-msg');
        if (noerror) {
            if (emsg) emsg.style.display = 'none';
            if (rsmg) rsmg.style.display = 'block';
        } else {
            if (errorMsg) emsg.innerHTML = errorMsg;
            if (emsg) emsg.style.display = 'block';
            if (rsmg) rsmg.style.display = 'none';
        }
    }

    async updatePowermegaDatabase() {
        return this.ucol.where('email', '==', this.email).get().then(snapshot => {
            let status;
            if (!snapshot.empty) {
                // there should be none or only one record returned, in case there are multiple, we just pick up the first one.
                snapshot.forEach(pmUserDoc => {
                    const pmUser = pmUserDoc.data();
                    if (pmUser.eopt && pmUser.eopt.optin === this.optin) {
                        status = 'unchanged';
                    } else {
                        pmUser.eopt = {
                            optin: this.optin,
                            updated: new Date()
                        };
                        this.setPowermegaUser(pmUserDoc.ref, pmUser, true, `The user "eopt.optin" is set to "${this.optin}"`);
                        status = 'updated';
                    }
                });
            }
            return Promise.resolve(status);
        }).catch(error => {
            return Promise.reject(error);
        });
    }
}