/* jshint esversion: 8 */
'use strict';

import LotteryBase from './lottery-base.js';
import Utils from '../../lib/utils.js';
import '../../../css/history-page.scss';


export default class HistoryPage extends LotteryBase {
    constructor(global) {
        super(global);
        this.pageName = 'History';
    }

    initialize() {
        this.layout.selectMenuItem(this.layout.menus.history.item);
    }

    async getContent() {
        this.root = Utils.addElement(null, 'div');
        this.addLotteryTab(this.root, '/history');
        const ltype = this[this.type];
        this.list = Utils.addElement(this.root, 'div', 'jackpot-list');
        return Utils.downloadStorageJson(this.sref.child(ltype.histUrl), true).then(histJson => {
            this.tileCount = 0, this.adCount = 0;
            histJson.games.forEach(game => {
                this.insertHistoryAd('dpad-history-tile', [3, 7, 15, 30]);
                const frame = Utils.addElement(this.list, 'div', 'jackpot-tile');
                frame.me = this;
                frame.ltype = this.type;
                ltype.dailyFeed = {prev: game};
                frame.onclick = event => {
                    event.stopPropagation();
                    this.renderPayoutsPopup(event.target.closest('.jackpot-tile'), null, 'dpad-payoutmodal-banner');
                }
                this.renderPrevDraw(frame, this.type);
                this.loadPrevDrawInfo(ltype, game);
            });
            return this.root;
        });
    }

    insertHistoryAd(auNamePrefix, placements) {
        if (placements.includes(++this.tileCount) && this.adCount < placements.length) {
            Utils.addElement(this.list, 'div', 'dpad-history-tile').innerHTML = this.insertAd(`${auNamePrefix}-${++this.adCount}`);
        }
    }
}
