/* jshint esversion: 8 */
'use strict';

import '../../css/page-layout.scss';
import MessageBox from './msgbox.js';
import Utils from './utils.js';

export default class PageLayout {
    constructor(global) {
        this.auth = global.firebase.auth();
        this.router = global.router;
        this.content = Utils.addElement(document.body, 'div', undefined, 'content-root');
        this.header = this.renderHeader(this.content);
        this.main = Utils.addElement(this.content, 'main', 'main-content with-hdft', 'main-content');
        this.footer = this.renderFooter(this.content);
        this.headerFooter = true;
        this.customizeForWebview();
    }

    static getInstance(global) {
        if (!this.instance) this.instance = new PageLayout(global);
        return this.instance;
    }

    renderHeader(parent) {
        // Powermega logo, title and slogan
        const header = Utils.addElement(parent, 'header', undefined, 'top-bar');
        const nav = Utils.addElement(header, 'nav', 'topnav');
        const hamburg = Utils.addElement(nav, 'div', 'logos hamburg-menu');
        const brand = Utils.addElement(nav, 'div', 'pm-brand');
        Utils.addElement(brand, 'div', 'logos pmlogo-36x36');
        const nameSlogan = Utils.addElement(brand, 'div');
        Utils.addElement(nameSlogan, 'div', 'b-title').innerText = 'POWERMEGA';
        Utils.addElement(nameSlogan, 'div', 'b-subtitle').innerText = 'Helping You Win!';
        this.renderMenuItems(nav);
        hamburg.onclick = event => this.clickMenuIcon(event, this.menuItems);

        // avatar, its submenu and the submenu items
        this.avatar1 = Utils.addElement(nav, 'img', 'avatar', 'avatar-navbar');
        this.avatar1.onclick = event => this.clickMenuIcon(event, this.avatarSubmenu);
        this.renderAvatarSubmenu(nav);

        this.setAuthenticationStatus();
        return header;
    }

    renderFooter(parent) {
        const footer = Utils.addElement(parent, 'footer', 'footer-full');
        let element = Utils.addElement(footer, 'div', 'legal');
        Utils.addElement(element, 'span', 'copyright').innerText = 'Copyright © 2025, Powermega. All rights reserved.';
        element = Utils.addElement(element, 'span', 'links');
        Utils.addAnchor(element, 'privacy', '/privacy', 'Privacy Policy', this.clickAnchor('footerLink'));
        Utils.addAnchor(element, undefined, '/terms', 'Terms & Conditions', this.clickAnchor('footerLink'));
        return footer;
    }

    renderMenuItems(parent) {
        this.menuItems = Utils.addElement(parent, 'div', 'top-menu hamburg-submenu', 'top-menu');
        const mainul = Utils.addElement(this.menuItems, 'ul');

        this.mqlMin600 = window.matchMedia('(min-width: 600px)');

        const insertMenuItem = (parent, menu) => {
            if (menu.menus) {
                const li = Utils.addElement(parent, 'li', 'has-submenu');
                Utils.addAnchor(li, undefined, menu.path, menu.name);
                Utils.addElement(li, 'div', 'logos arrow-down')
                const subul = Utils.addElement(li, 'ul', 'sub-menu');

                const displaySubmenu = mql => {
                    if (mql.matches) {
                        li.onmouseenter = () => subul.style.maxHeight = '600px';
                        li.onmouseleave = () => subul.style.maxHeight = '0';
                        subul.style.maxHeight = '0px';
                    } else {
                        li.onmouseenter = null;
                        li.onmouseleave = null;
                        subul.style.maxHeight = '600px';
                    }
                }
                displaySubmenu(this.mqlMin600);
                this.mqlMin600.addEventListener('change', displaySubmenu);

                Object.keys(menu.menus).forEach(key => {
                    menu.menus[key].item = insertMenuItem(subul, menu.menus[key], false)
                });
            } else {
                const li = Utils.addElement(parent, 'li');
                return Utils.addAnchor(li, undefined, menu.path, menu.name, this.clickAnchor('menuItem'));
            }
        }

        this.menus = this.router.global.menus;
        Object.keys(this.menus).forEach(key => this.menus[key].item = insertMenuItem(mainul, this.menus[key]));
    }

    renderAvatarSubmenu(parent) {
        this.avatarSubmenu = Utils.addElement(parent, 'div', 'sub-menu avatar-sm');
        const subMenu = Utils.addElement(this.avatarSubmenu, 'div', 'asm-content');
        let element = Utils.addElement(subMenu, 'div', 'pm-brand');
        this.avatar2 = Utils.addElement(element, 'img', 'pmlogo-48x48');
        element = Utils.addElement(element, 'div');
        this.username = Utils.addElement(element, 'div', 'b-title');
        this.emailAddress = Utils.addElement(element, 'div', 'b-subtitle');
        Utils.addElement(subMenu, 'hr');

        // Manage Account menu item
        this.smitemProfile = Utils.addAnchor(subMenu, 'link', '/manageact', null, this.clickAnchor('avatar'));
        Utils.addElement(this.smitemProfile, 'div', 'logos icon uprofile');
        Utils.addElement(this.smitemProfile, 'p').innerText = 'Manage Account';

        // Logout menu item
        this.smitemLogout = Utils.addAnchor(subMenu, 'link');
        this.smitemLogout.onclick = () => {
            this.auth.signOut().then(() => this.setAuthenticationStatus());
        }
        Utils.addElement(this.smitemLogout, 'div', 'logos icon logout');
        Utils.addElement(this.smitemLogout, 'p').innerText = 'Sign Out';
    }

    setAuthenticationStatus() {
        const user = this.auth.currentUser;
        if (user) {
            const usrImg = user.photoURL || 'images/logged-in-48x48.png';
            this.avatar1.src = usrImg
            this.avatar2.src = usrImg;
            this.avatar1.style.display = '';
            this.menus.signin.item.parentNode.style.display = 'none';
            this.username.innerText = user.displayName;
            this.emailAddress.innerText = user.email;
        } else {
            this.avatar1.style.display = 'none';
            this.menus.signin.item.parentNode.style.display = '';
        }
    }

    hideHeaderFooter(hide = true) {
        if ((this.headerFooter && hide) || (!this.headerFooter && !hide)) {
            const showConsent = show => {
                const userConsent = document.getElementById('user-consent');
                if (userConsent) userConsent.style.display = show ? '' : 'none';
            }
            if (hide) {
                this.header.style.display = 'none';
                this.footer.style.display = 'none';
                this.main.classList.remove('with-hdft');
                this.main.classList.add('without-hdft');
                showConsent(false);
            } else {
                this.header.style.display = '';
                this.footer.style.display = '';
                this.main.classList.remove('without-hdft');
                this.main.classList.add('with-hdft');
                showConsent(true);
            }
            this.headerFooter = !hide;
        }
    }

    customizeForWebview() {
        let callingParams = window.location.search.slice(1);
        if (callingParams) {
            callingParams.split('&').forEach(params => {
                const param = params.split('=');
                const key = param[0].toLowerCase();
                if ((key === 'webview' || key === 'wv') && param[1].toLowerCase() === 'true') {
                    this.hideHeaderFooter();
                    this.webview = true;
                }
            });
        }
    }

    clickMenuIcon(event, submenu) {
        if (event) event.stopPropagation();

        const hideSubmenu = () => {
            submenu.classList.remove('open-submenu');
            document.body.removeEventListener('click', handleOutsideClick);
        }
        const handleOutsideClick = () => {
            if (submenu.classList.contains('open-submenu')) hideSubmenu();
        }

        if (submenu.classList.contains('open-submenu')) {
            hideSubmenu();
            this.openedSubmenu = null;
        } else {
            if (this.openedSubmenu) this.openedSubmenu.classList.remove('open-submenu');
            submenu.classList.add('open-submenu');
            this.openedSubmenu = submenu;
            submenu.addEventListener('click', event => event.stopPropagation());
            document.body.addEventListener('click', handleOutsideClick);
        }
    }

    addScrollingEffect() {
        const hero = document.getElementById("hero-section");
        if (hero) {
            const sticky = this.header.offsetTop;
            window.onscroll = () => {
                hero.style.backgroundPosition = `center bottom  ${sticky - window.pageYOffset}px`;
                window.pageYOffset > sticky ? this.header.classList.add('scroll-header') : this.header.classList.remove('scroll-header');
            };
        }
    }

    selectMenuItem(menuItem) {
        if (this.selectedMenuItem) {
            if (this.selectedMenuItem === menuItem) {
                return;
            } else {
                this.selectedMenuItem.classList.remove('selected');
            }
        }

        if (menuItem) {
            this.selectedMenuItem = menuItem;
            menuItem.classList.add('selected');
        } else if (this.selectedMenuItem) {
            this.selectedMenuItem = null;
        }
    }

    /**
     * This is a SPA (Single Page Application). The default anchor link does not work. This function is to
     * make the corresponding JS function call based on anchor href value.
     * @param {string} type: the values are: "menuItem", "avatar", "footerLink"
     * @returns the correspnding function of the type that dispatch calls for anchor href.
     */
    clickAnchor(type) {
        const getAnchor = event => {
            let element = event.target;
            if (element instanceof HTMLLIElement) {
                return element.firstChild;
            } else if (!(element instanceof HTMLAnchorElement)) {
                return element.closest('a');
            } else {
                return element;
            }
        }

        return async event => {
            event.preventDefault();
            const anchor = getAnchor(event);

            if (type === 'menuItem') {
                const ul = anchor.closest('ul');
                if (this.mqlMin600.matches) {
                    if (ul.classList.contains('sub-menu')) ul.style.maxHeight = '0';
                } else {
                    this.clickMenuIcon(event, this.menuItems);
                }
            } else if (type === 'avatar') {
                this.clickMenuIcon(event, this.avatarSubmenu);
            } else if (type === 'footerLink') {
                if (this.selectedMenuItem) this.selectedMenuItem.classList.remove('selected');
                this.selectedMenuItem = null;
            }

            if (anchor.href) {
                const url = new URL(anchor.href);
                await this.router.dispatch(url.pathname, url.search);
            }
            return false;
        }
    }

    createModalPopup(type, parent, width, height, options = {}) {
        return  new MessageBox(type || 'modal', parent || this.content, width, height, options);
    }
}
